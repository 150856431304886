import React from "react";
import styles from "./styles.module.scss";
import format from "date-fns/format";
// Components
import FlightpathChart from "../../Charts/FlightpathChart";
import PieChart from "../../Charts/PieChart";
import PdfFooter from "../../Components/PdfFooter";
import PdfSectionHeading from "../../Components/PdfSectionHeading";
import ServiceUserImage from "../../Components/ServiceUserImage";
import ServiceUserData from "../../Components/ServiceUserData";
import CareId from "../../Components/CareId";
// Utils
import {
  getInitials,
  countOccurrences,
  RiskStatus,
} from "@intelligentlilli/lilli-utils";
import { subDays, endOfDay, addDays, startOfDay } from "date-fns";

const ChangeInCareNeeds = ({
  preview,
  SU,
  SUProperties,
  SUPhoto,
  SURiskScores,
  id,
  pdfStyle,
  movementRisk,
  temperatureRisk,
  sleepRisk,
  independenceRisk,
  sustenanceRisk,
  movementData,
  independenceData,
  sustenanceData,
  from,
  to,
  summary,
  setSummary,
}) => {
  // Get the max value for the time outside. This is used to decide whether to convert the values to hours rather than minutes for readability in the graph
  const maxValue = Math.max(...independenceData.map((d) => d.value));
  const convertToHours = maxValue / 60 > 1;
  const flightpathData = convertToHours
    ? independenceData.map((day) => ({
        ...day,
        value: day.value / 60,
        expectedMin: day.expectedMin / 60,
        expectedMax: day.expectedMax / 60,
      }))
    : independenceData;

  // Occurances of eack risk level in the date range
  const countAtRisk = countOccurrences(SURiskScores, 2);
  const countUnexpected = countOccurrences(SURiskScores, 1);
  const countExpected = countOccurrences(SURiskScores, 0);

  const careID =
    SUProperties?.mosaicID ||
    SUProperties?.careId ||
    SUProperties?.mosaicId ||
    SUProperties?.careID ||
    "Not set";
  return (
    <>
      <div className={pdfStyle}>
        <div className={styles.header}>
          <h2>Change in care needs report</h2>
          <div>
            <div>
              <b>Requested by:</b>
            </div>
            <div>
              <b>User ID:</b>
            </div>
            <div>Organisation placeholder</div>
            <div>
              <b>Date requested:</b> {format(new Date(), "dd/MM/yy")}
            </div>
          </div>
          <img src="/logo/lilli-128-sand.png" alt="Lilli" width="60" />
        </div>
        <div className={styles.date}>
          <b>Report date range:</b>{" "}
        </div>
        <div className={styles.summary}>
          <div className={styles.summary_left}>
            <ServiceUserImage
              profilePhotoURL={SUPhoto}
              // If we have a profile photo for the service user then remove the border
              removeBorder={!!SUPhoto}
              initials={getInitials(SU?.userstring)}
              style={{ height: 120, width: 120, minWidth: 120 }}
            />
            <div className={styles.summary_left_name}>
              <h2>{SU?.userstring}</h2>
              <CareId
                careID={careID}
                style={{ fontSize: 9, borderRadius: 6 }}
              />
            </div>

            <ServiceUserData
              serviceUserProperties={SUProperties}
              iconSize={{
                width: 15,
                height: 15,
              }}
              vertical
              pdf
            />
            <div>
              <b>Notes: </b>
              {SUProperties?.usernote}
            </div>
          </div>
          <div className={styles.summary_right}>
            <PdfSectionHeading title="Summary" riskLevel={movementRisk} />
            <div className={styles.summary_right_summary}>
              {preview ? (
                <textarea
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                  placeholder="Add a summary to the report"
                  style={{ height: "180px", width: "100%", resize: "none" }}
                />
              ) : (
                <div>Placeholder for summary text</div>
              )}
            </div>

            <div className={styles.summary_right_notes}>
              <h2>Carer notes</h2>
              <div>placeholder</div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.content_block} style={{ marginRight: "42px" }}>
            <PdfSectionHeading
              title="Monthly overview"
              riskLevel={RiskStatus.AT_RISK}
            />
            <div>
              Assessment of status for all behaviours for {SU?.userstring}
            </div>
            <div className={styles.overview}>
              <PieChart
                className={styles.overview_pieChart}
                atRisk={countAtRisk}
                unexpected={countUnexpected}
                expected={countExpected}
                radius={50}
                innerRadius={25}
              />
              <p>placeholder</p>
            </div>
          </div>
          <div className={styles.content_block}>
            <PdfSectionHeading title="Movement" riskLevel={movementRisk} />
            <div className={styles.content_chart}>
              <FlightpathChart
                data={movementData}
                dateType="month"
                xDomain={[
                  addDays(startOfDay(from), 1),
                  subDays(endOfDay(to), 1),
                ]}
                yAxisTitle="Movements"
                crosshair={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={pdfStyle}>
        <div className={styles.content}>
          <div className={styles.content_block} style={{ marginRight: "42px" }}>
            <PdfSectionHeading
              title="Independence"
              riskLevel={independenceRisk}
            />
            <div className={styles.content_chart}>
              <FlightpathChart
                data={flightpathData}
                dateType="month"
                xDomain={[
                  addDays(startOfDay(from), 1),
                  subDays(endOfDay(to), 1),
                ]}
                yAxisTitle={
                  convertToHours ? "Hours outside" : "Minutes outside"
                }
                crosshair={false}
              />
            </div>
          </div>
          <div className={styles.content_block}>
            <PdfSectionHeading title="Sustenance" riskLevel={sustenanceRisk} />
            <div className={styles.content_chart}>
              <FlightpathChart
                data={sustenanceData}
                dateType="month"
                xDomain={[
                  addDays(startOfDay(from), 1),
                  subDays(endOfDay(to), 1),
                ]}
                yAxisTitle="Events detected"
                crosshair={false}
                sustenance
              />
            </div>
          </div>
        </div>
        <PdfFooter />
      </div>
    </>
  );
};

export default ChangeInCareNeeds;
