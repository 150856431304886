import { deleteReport as deleteReportAPI } from "@intelligentlilli/api-layer";

const deleteReport = async (server, reportId) => {
  try {
    const response = await deleteReportAPI(server, reportId, "web");
    return response;
  } catch (error) {
    return error;
  }
};

export default deleteReport;
