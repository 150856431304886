import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";

const Card = (props) => (
  <div className={`${styles.card} ${props.style}`} onClick={props.onClick}>
    {props.children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node,
  style: PropTypes.string,
  onClick: PropTypes.func,
};

export default Card;
