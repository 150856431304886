import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
// components
import PrimaryButton from "../PrimaryButton";
import NewReport from "../NewReport";
import NewStyleModal from "../NewStyleModal";
import { AddIcon } from "../../Styles/Icons";
// Hooks
import { useReportsHook, useFade } from "../../Services/Hooks";
import { useViewport } from "../ViewportProvider";

const DashboardButtonsReports = () => {
  const navigate = useNavigate();
  const { width } = useViewport();
  const isMobile = width <= 1200;
  const server = useSelector((state) => state.session.server);
  const serviceUsers = useSelector((state) => state.serviceUsers);

  // custom hooks
  const { reportTypes } = useReportsHook(server, navigate);

  // Fade Modal
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  // we don't want to show the new report button on mobile
  if (isMobile) return null;

  return (
    <div>
      <div className={styles.dashboard_buttons}>
        <PrimaryButton
          className={styles.add_report_button}
          startIcon={<AddIcon />}
          onClick={() => setShowAnimation(true)} //kicks off a series of actions controlled by the useFade hook
          // when set to True, the useEffect hook then sets the NewModal visibility to true
          // when showAnimation is true, then the class ".fade_in" animation kicks in
        >
          New Report
        </PrimaryButton>
      </div>
      {isVisible && (
        <NewStyleModal
          showCloseIcon={true}
          hide={() => {
            setShowAnimation(false);
          }}
          showAnimation={showAnimation}
          title="New Report"
          context="A new report will be created with the details you select below."
        >
          <NewReport
            reportTypes={reportTypes}
            serviceUsers={serviceUsers}
            closeReport={() => setShowAnimation(false)}
          />
        </NewStyleModal>
      )}
    </div>
  );
};

export default DashboardButtonsReports;
