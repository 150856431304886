import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// State
import {
  setLoading,
  setRefreshOrganisationHubs,
} from "../../State/slices/session-slice";
// API
import postCreateHub from "../../Services/Cache/Hubs/postCreateHub";
import postHubProperty from "../../Services/Cache/Hubs/postHubProperty";
import postUserHubAssociation from "../../Services/Cache/Users/postUserHubAssociation";

export const useAddNewServiceUser = (
  setAddingServiceUserError,
  setAddingServiceUser
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const serviceUsers = useSelector((state) => state.serviceUsers);
  const server = useSelector((state) => state.session.server);
  const user = useSelector((state) => state.user);

  const setHubProperties = async (
    id,
    conditions,
    careType,
    dateOfBirth,
    careId
  ) => {
    const promises = [];
    if (conditions) {
      promises.push(postHubProperty(server, id, "Conditions", conditions));
    }
    if (careType) {
      promises.push(postHubProperty(server, id, "CareType", careType));
    }
    if (dateOfBirth) {
      promises.push(postHubProperty(server, id, "DateOfBirth", dateOfBirth));
    }
    if (careId) {
      promises.push(postHubProperty(server, id, "CareId", careId));
    }
    await Promise.all(promises).catch((err) => {
      console.log("setHubProperties err:", err);
      setAddingServiceUserError(err);
    });
  };

  const setCarers = async (id, carerAssignment, assignToMe) => {
    const promises = [];
    if (carerAssignment) {
      carerAssignment.forEach((carer) => {
        if (carer.id) {
          promises.push(postUserHubAssociation(server, carer.id, id));
        }
      });
    }
    if (assignToMe) {
      promises.push(postUserHubAssociation(server, user.key, id));
    }

    await Promise.all(promises)
      .then(() => {
        console.log("Finished setting carers to service users");
        // Refetch the service users from the server
        dispatch(setRefreshOrganisationHubs(true));
      })
      .catch((err) => {
        console.log("setCarers err:", err);
        setAddingServiceUserError(err);
      });
  };

  // Function for handling adding a new service user
  const onAddServiceUser = (
    forename,
    surname,
    postcode,
    houseNumber,
    dateOfBirth,
    careId,
    conditions,
    careType,
    carerAssignment,
    assignToMe
  ) => {
    // First we check that the service user hasn't already been added to the organisation
    const duplicateServiceUser =
      serviceUsers.filter(
        (serviceUser) =>
          serviceUser.serviceUserProperties?.forenames
            ?.toLowerCase()
            .replaceAll(" ", "") ===
            forename?.toLowerCase().replaceAll(" ", "") &&
          serviceUser.serviceUserProperties?.surname
            ?.toLowerCase()
            .replaceAll(" ", "") ===
            surname?.toLowerCase().replaceAll(" ", "") &&
          serviceUser.serviceUserProperties?.postcode
            ?.toLowerCase()
            .replaceAll(" ", "") === postcode?.toLowerCase().replaceAll(" ", "")
      ).length > 0;
    if (duplicateServiceUser) {
      setAddingServiceUserError(`${forename} ${surname} already exists`);
    } else {
      dispatch(setLoading(true));
      postCreateHub(server, forename, surname, houseNumber, postcode)
        .then((res) => {
          if (res.ok) {
            setHubProperties(
              res.body.result,
              conditions,
              careType,
              dateOfBirth,
              careId
            );
            setCarers(res.body.result, carerAssignment, assignToMe);
          } else {
            if (res.status === 401) {
              navigate("/login");
            } else {
              console.log(
                "Cannot create SU.",
                "Status:",
                res.status,
                "Res:",
                res
              );
              alert(
                "There was a problem creating this new Lilli user.\n\nCheck you have an internet connection\n\nIf the problem persists please contact: support@intelligentlilli.com"
              );
              setAddingServiceUserError(
                "Sorry, we couldn't create this new user."
              );
            }
          }
        })
        .catch((err) => {
          setAddingServiceUserError(err);
          console.log("Adding service user error:", err);
          alert("Error adding service user");
        })
        .finally((_) => {
          dispatch(setLoading(false));
          setAddingServiceUser(false);
        });
    }
  };

  return { onAddServiceUser };
};
