import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
// Components
import { CloseIcon, SearchIcon } from "../../Styles/Icons";

const SearchInput = (props) => {
  const focus = () => {
    props.setFocus();
  };
  const isFocused = () => {
    props.setFocused(true);
  };
  const requestEnd = () => {
    props.setRequestEnd(true);
  };
  const selectingRecent = () => {
    props.setSelectingRecent(true);
  };

  return (
    <div
      className={`
        ${styles.container} 
        ${props.focused && styles.container_focused}
        ${props.className}
        `}
    >
      <div
        onClick={props.setFocus && focus}
        className={styles.container_clickable}
      >
        <SearchIcon className={styles.searchIcon} />
        <input
          value={props.value}
          onFocus={props.setFocused && isFocused}
          onBlur={props.setRequestEnd && requestEnd}
          placeholder={props.placeholder || "Search..."}
          onChange={(event) => {
            props.setValue(event.target.value);
          }}
          ref={props.inputRef && props.inputRef}
          aria-label="Search"
        />
      </div>
      <CloseIcon
        className={styles.closeIcon}
        onClick={() => {
          props.setValue("");
        }}
        onMouseDown={props.setSelectingRecent && selectingRecent}
      />
      {props.children}
    </div>
  );
};

SearchInput.propTypes = {
  inputProps: PropTypes.object,
  setValue: PropTypes.func,
};

export default SearchInput;
