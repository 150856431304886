import { postUpdateReport as postUpdateReportAPI } from "@intelligentlilli/api-layer";

const postUpdateReport = async (
  server,
  id,
  reportType,
  name,
  days,
  reportStartDate,
  serviceUserId,
  options,
  summary
) => {
  try {
    const response = await postUpdateReportAPI(
      server,
      id,
      reportType,
      name,
      days,
      reportStartDate,
      serviceUserId,
      options,
      summary,
      "web"
    );
    return response;
  } catch (err) {
    return err;
  }
};

export default postUpdateReport;
