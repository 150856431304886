import { getUserProperties as getUserPropertiesAPI } from "@intelligentlilli/api-layer";

const getUserProperties = async (server, id) => {
  try {
    const response = await getUserPropertiesAPI(server, id, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getUserProperties;
