import React from "react";
import styles from "./styles.module.scss";
// Utils
import { getInitials } from "@intelligentlilli/lilli-utils";
// Icons
import { RefreshIcon } from "../../Styles/Icons/DesignSystem";
// Components
import AltSpinner from "../Spinners/AltSpinner";
import SensorSignal from "../SensorSignal";

const InstallationSuDetails = ({
  userstring,
  devicestring,
  onClick,
  loading,
  online,
  isDesktop,
  allowRefresh,
}) => {
  return (
    <div className={styles.installation_su_details}>
      <div className={styles.left}>
        <div className={`${styles.su_details_icon} fs-mask`}>
          {getInitials(userstring) || ""}
        </div>
        <div>
          <div className={`${styles.su_details_name} fs-mask`}>
            {userstring || ""}
          </div>
          <div className={styles.su_details_id}>{devicestring || ""}</div>
        </div>
      </div>

      <div
        className={`${styles.right} ${loading && styles.disabled} ${
          allowRefresh && styles.pointer
        }`}
        onClick={!loading ? onClick : () => {}}
      >
        <div className={styles.connections_hint}>
          {loading && (isDesktop ? "Testing connection" : "Testing")}
          {!loading && allowRefresh && (isDesktop ? "Test connection" : "Test")}
          {!loading && !allowRefresh && (
            <SensorSignal online={online} loading={loading} />
          )}
        </div>
        {loading && <AltSpinner small />}
        {!loading && allowRefresh && <RefreshIcon />}
      </div>
    </div>
  );
};

export default InstallationSuDetails;
