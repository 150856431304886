import { postReports as postReportsAPI } from "@intelligentlilli/api-layer";

const postReports = async (
  server,
  reportType,
  name,
  days,
  reportStartDate,
  serviceUserId,
  options,
  summary = null
) => {
  try {
    const response = await postReportsAPI(
      server,
      reportType,
      name,
      days,
      reportStartDate,
      serviceUserId,
      options,
      (summary = null),
      "web"
    );
    return response;
  } catch (err) {
    return err;
  }
};

export default postReports;
