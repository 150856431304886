import React from "react";
import styles from "./styles.module.scss";
import AltSpinner from "../Spinners/AltSpinner";

const SensorSignal = ({ online, loading }) => {
  if (loading) {
    return <AltSpinner small />;
  }
  if (online) {
    return <div className={styles.connected}>Connected</div>;
  }
  return <div className={styles.disconnected}>Not connected</div>;
};

export default SensorSignal;
