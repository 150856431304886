import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// API
import getReportTypes from "../Cache/ReportTypes/getReportTypes";
import deleteReport from "../Cache/Reports/deleteReport";
import getReports from "../Cache/Reports/getReports";
import postUpdateReport from "../Cache/Reports/postUpdateReport";
import getReportPreview from "../Cache/Reports/getReportPreview";
// State
import { setLoading } from "../../State/slices/session-slice";
import { setReports } from "../../State/slices/reports-slice";
import { setReportTypes } from "../../State/slices/reportTypes-slice";
// Utils
import { getArrayOfReportOptions } from "../Utils";
import { differenceInDays, parseISO } from "date-fns";
import postReports from "../Cache/Reports/postReports";

export function useReportsHook(server, navigate) {
  const dispatch = useDispatch();
  const reportTypes = useSelector((state) => state.reportTypes);
  const reports = useSelector((state) => state.reports);
  const [closeConfirmModal, setCloseConfirmModal] = useState(true);
  const [isSavingChanges, setIsSavingChanges] = useState(null);

  useEffect(() => {
    getReportTypes(server)
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
        } else {
          dispatch(setReportTypes(res.body));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getReports(server)
      .then((res) => {
        dispatch(setLoading(false));
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
        } else {
          // NOTE: Update this to see all reports
          // This is for Dorset v1 Release
          const generalReportsOnly = res.body.filter(
            (report) => report.typeid === "general-report"
          );
          dispatch(setReports(generalReportsOnly));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  }, [server, navigate, dispatch]);

  const createReport = (formData, reportEndDate, creatingReportOnSUPage) => {
    postReports(
      server,
      formData.reportType, // "general-report"
      formData.reportName, // name
      differenceInDays(
        parseISO(reportEndDate),
        parseISO(formData.reportStartDate)
      ) + 1, // days
      formData.reportStartDate, // "YYYY-MM-DD"
      formData.serviceUsers?.value, // service user ID
      getArrayOfReportOptions(formData.options) // options ["sustenance", ... ]
    )
      .then((res) => {
        console.log(res);
      })
      .then(() => {
        if (creatingReportOnSUPage) {
          navigate("/reports");
        }
      })
      .then(() => {
        getReports(server)
          .then((res) => {
            if (res.ok) {
              const generalReportsOnly = res.body.filter(
                (report) => report.typeid === "general-report"
              );
              dispatch(setReports(generalReportsOnly));
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("Error creating report:", err);
        alert("Error creating report");
      });
  };

  const deleteSelectedReport = (reportId) => {
    dispatch(setLoading(true));
    deleteReport(server, reportId)
      .then((res) => {
        // Refresh the reports
        getReports(server)
          .then((res) => {
            dispatch(setLoading(false));
            if (res.ok) {
              const generalReportsOnly = res.body.filter(
                (report) => report.typeid === "general-report"
              );
              dispatch(setReports(generalReportsOnly));
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
            console.log(err);
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
    setCloseConfirmModal(true);
  };

  const editAndPreviewReport = (
    formData,
    server,
    reportEndDate,
    setError,
    setData
  ) => {
    setIsSavingChanges(true);
    // Post updates to the report
    postUpdateReport(
      server,
      formData.reportID, // service user ID
      formData.reportType, // i.e. 'general-report',
      formData.reportName, // name person has given the report,
      differenceInDays(
        parseISO(reportEndDate),
        parseISO(formData.reportStartDate)
      ) + 1, // report end date
      formData.reportStartDate, // report start date "YYYY-MM-DD"
      formData.suID, // service user ID
      getArrayOfReportOptions(formData.options), // ["sustenance", ...
      formData.summary
    )
      .then(() => {
        const urlPath = window.location.pathname;
        if (urlPath.includes("pdf-preview")) {
          // Preview the newly edited report
          getReportPreview(server, formData.reportID)
            .then((res) => {
              if (res.ok) {
                setData(res.body);
                setIsSavingChanges(false);
              } else {
                setError(true);
              }
            })
            .catch((err) => {
              setError(true);
            });
        }
      })
      .catch((e) => {
        console.log("error updating report:", e);
        setIsSavingChanges(false);
      });
  };

  return {
    createReport,
    deleteSelectedReport,
    reportTypes,
    reports,
    closeConfirmModal,
    setCloseConfirmModal,
    editAndPreviewReport,
    isSavingChanges,
  };
}
