import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { PropTypes } from "prop-types";
// DateTime
import { isSameDay, format } from "date-fns";
// Components
import IconButton from "@mui/material/IconButton";
import { NavigateBeforeIcon, NavigateNextIcon } from "../../Styles/Icons";
import PrimaryButton from "../PrimaryButton";
import { useViewport } from "../ViewportProvider";
import { mobileBreakpoint } from "../../Services/config";

const DateSelector = (props) => {
  const { width } = useViewport();
  const isMobile = width < mobileBreakpoint;
  // Format the various date types
  const dayFormat = format(props.date, "dd LLL");
  const weekOrMonthDateFormat = `${format(props.from, "dd/LL")} - ${format(
    props.to,
    "dd/LL"
  )}`;

  const getButtonStyle = (type) => {
    if (type === props.dateType) {
      return {
        border: `1px solid ${theme.green3}`,
        backgroundColor: theme.green3,
        color: theme.neutral7,
        borderRadius: isMobile ? 3 : 4,
        padding: isMobile ? "4px 6px" : "8px 16px",
        fontWeight: 500,
        minWidth: 35,
      };
    }
    return {
      border: `1px solid ${theme.neutral2}`,
      backgroundColor: isMobile ? theme.neutral2 : theme.white,
      color: theme.neutral7,
      borderRadius: isMobile ? 3 : 4,
      padding: isMobile ? "4px 6px" : "8px 16px",
      fontWeight: 400,
      minWidth: 35,
    };
  };

  return (
    <div className={styles.dateSelector}>
      <div className={styles.dateSelector_increment}>
        <IconButton
          onClick={() => props.onChange(-1)}
          // NOTE: update earliest date to installation date (or something along those lines when we have that data)
          disabled={
            isSameDay(props.date, props.earliestDate) // if todays date is the same as the earliest date, then disable
            // The below logic was blocking the "back" button from working correctly
            // || compareAsc(props.earliestDate, props.from) === 1  // compareAsc comes from 'date-fns'
          }
          aria-label="View earlier data"
          className={styles.selectorButton}
          style={{
            border: isMobile ? "none" : `1px solid ${theme.neutral2}`,
            borderRadius: 4,
            padding: "7px 13px",
          }}
        >
          <NavigateBeforeIcon
            className={styles.dateSelector_increment_button}
          />
        </IconButton>
        <div className={styles.dateSelector_increment_date}>
          {props.dateType === "day" ? dayFormat : weekOrMonthDateFormat}
        </div>
        <IconButton
          onClick={() => props.onChange(1)}
          disabled={isSameDay(new Date(), props.date)}
          aria-label="View later data"
          className={styles.selectorButton}
          style={{
            border: isMobile ? "none" : `1px solid ${theme.neutral2}`,
            borderRadius: 4,
            padding: "7px 13px",
          }}
        >
          <NavigateNextIcon className={styles.dateSelector_increment_button} />
        </IconButton>
        <PrimaryButton
          style={{
            border: `1px solid ${theme.neutral2}`,
            backgroundColor: theme.white,
            borderRadius: 4,
            padding: "7px 13px",
          }}
          onClick={() => props.handleTodayButtonClick()}
        >
          Today
        </PrimaryButton>
      </div>

      <div className={styles.dateSelector_mode}>
        <PrimaryButton
          style={getButtonStyle("day")}
          onClick={() => props.setDateType("day")}
        >
          {!isMobile ? "Day" : "D"}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => props.setDateType("week")}
          style={getButtonStyle("week")}
        >
          {!isMobile ? "Week" : "W"}
        </PrimaryButton>
        <PrimaryButton
          style={getButtonStyle("month")}
          onClick={() => props.setDateType("month")}
        >
          {!isMobile ? "Month" : "M"}
        </PrimaryButton>
      </div>
    </div>
  );
};

DateSelector.propTypes = {
  date: PropTypes.instanceOf(Date),
  earliestDate: PropTypes.instanceOf(Date),
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  setDateType: PropTypes.func,
  dateType: PropTypes.string,
};

export default DateSelector;
