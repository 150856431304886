import React from "react";
import NewReport from "../NewReport";
import NewStyleModal from "../NewStyleModal";

function CreateNewReportModal({
  serviceUsers,
  reportTypes,
  showAnimation,
  setShowAnimation,
}) {
  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={() => {
        setShowAnimation(false);
      }}
      showAnimation={showAnimation}
      title="New Report"
      context="A new report will be created with the details you select below."
    >
      <NewReport
        reportTypes={reportTypes}
        serviceUsers={serviceUsers}
        closeReport={() => setShowAnimation(false)}
      />
    </NewStyleModal>
  );
}

export default CreateNewReportModal;
