import { getHubSleepAnalysis as getHubSleepAnalysisAPI } from "@intelligentlilli/api-layer";

const getHubSleepAnalysis = async (server, id, days, endTime) => {
  try {
    const response = await getHubSleepAnalysisAPI(
      server,
      id,
      days,
      endTime,
      "web"
    );
    if (response.ok) {
      return response.body;
    } else {
      return null;
    }
  } catch (err) {
    console.log(`Error fetching data for sleep:`, err);
    return err;
  }
};

export default getHubSleepAnalysis;
