import { getReportStatus as getReportStatusAPI } from "@intelligentlilli/api-layer";

const getReportStatus = async (server, id, publicationId = null) => {
  try {
    const response = await getReportStatusAPI(server, id, publicationId, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getReportStatus;
