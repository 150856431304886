import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// State
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../State/slices/session-slice";
// API
import postForgottenPasswordReset from "../../Services/Cache/Session/postForgottenPasswordReset";
// Components
import GuestPage from "../../Components/GuestPage";
import ResetPasswordForm from "../../Forms/ResetPasswordForm";

const ResetPassword = () => {
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  // get the token from the URL
  const { token } = useParams();
  const navigate = useNavigate();

  // Redux State
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);

  // Handling user forgetting password
  const onSubmitResetPassword = (password) => {
    dispatch(setLoading(true));
    postForgottenPasswordReset(server, password, token)
      .then((res) => {
        if (!res.ok) {
          dispatch(setLoading(false));
          if (res === undefined) {
            setResetPasswordError(
              "Lilli servers are down. Please try again later."
            );
          } else if (res.status === 400) {
            // "Password reset token is expired or invalid."
            setResetPasswordError(res.body.error);
          } else if (res.status === 422) {
            setResetPasswordError(res.body.reason);
          } else {
            setResetPasswordError("An unexpected error occured.");
            console.log(res.body.reason);
          }
        } else {
          console.log("Reset PW Page - PW reset success!");
          setResetPasswordSuccess(true);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        setResetPasswordError(
          "An unexpected error occured, password cannot be reset."
        );
        dispatch(setLoading(false));
      });
  };

  return (
    <GuestPage>
      <ResetPasswordForm
        onSubmit={onSubmitResetPassword}
        resetPasswordError={resetPasswordError}
        resetPasswordSuccess={resetPasswordSuccess}
        navigate={navigate}
        setResetPasswordError={setResetPasswordError}
        setResetPasswordSuccess={setResetPasswordSuccess}
      />
    </GuestPage>
  );
};

export default ResetPassword;
