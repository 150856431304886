import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
// Forms
import { Form, Field } from "react-final-form";
import FormError from "../../Components/FormError";
// Components
import InstallationNextButton from "../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../Components/InstallationGoBackButton";

const DeviceIdForm = (props) => {
  const navigate = useNavigate();

  return (
    <Form
      onSubmit={(values) => props.onSubmit(values.id)}
      validate={(values) => {
        const errors = {};
        if (!values.id) {
          errors.id = "Required";
        }
        if (values.id?.length !== 16) {
          errors.id = "Device ID should be 16 digits long";
        }
        // create a regex that only allows numbers from 0 to 9 and capital letters from A to F as this is the format of the device ID
        const regex = /^[A-F0-9]+$/;
        if (!regex.test(values.id)) {
          errors.id =
            "ID should only contain numbers from 0 to 9 and capital letters from A to F";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <form
          className={styles.form}
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <Field name="id">
            {({ input, meta }) => (
              <div className={styles.input}>
                <label>
                  If you cannot scan your QR code enter the 16 digit number
                  above it here:
                  <input {...input} type="text" />
                </label>

                {meta.error && meta.touched && (
                  <span className={styles.error}>{meta.error}</span>
                )}
              </div>
            )}
          </Field>
          {props.error && <FormError error={props.error} />}
          <div className={props.buttonClass}>
            <InstallationGoBackButton
              label={"Go back"}
              onClick={() => navigate(-1)}
              isDesktop={props.isDesktop}
              marginTop={!props.isDesktop && "12px"}
              marginRight={props.isDesktop ? "24px" : "0px"}
              cypresstestid={"Installation-Link-QR-Code-Go-Back-Btn-5a"}
            />
            <InstallationNextButton
              type="submit"
              isDesktop={props.isDesktop}
              label={"Start installation"}
              disabled={submitting || pristine || !valid}
              cypresstestid={"Installation-Link-QR-Code-5"}
            />
          </div>
        </form>
      )}
    />
  );
};

export default DeviceIdForm;
