import { postUpdatePassword as postUpdatePasswordAPI } from "@intelligentlilli/api-layer";

const postUpdatePassword = async (server, currentPassword, newPassword) => {
  try {
    const response = await postUpdatePasswordAPI(
      server,
      currentPassword,
      newPassword,
      "web"
    );
    return response;
  } catch (err) {
    return err;
  }
};

export default postUpdatePassword;
