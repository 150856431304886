import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserNotesForm from "../../Forms/UserNotesForm";
import { useHubDailyNotes } from "../../Services/Hooks/useHubDailyNotes";
import styles from "./styles.module.scss";
import SecondaryButton from "../SecondaryButton";
import { format } from "date-fns";
import PrimaryButton from "../PrimaryButton";
import theme from "../../Styles/theme.scss";
import { DeleteIcon } from "../../Styles/Icons/DesignSystem";

const EditNotes = ({ hubId, setNoteToDelete, noteToDelete }) => {
  const navigate = useNavigate();
  // Redux State
  const server = useSelector((state) => state.session.server);
  const staff = useSelector((state) => state.staff);

  const { onSaveNote, hubNotes, error, deleteNote } = useHubDailyNotes(
    navigate,
    server,
    hubId
  );

  const getNoteAuthor = (note) => {
    const staffMember = staff.find((staff) => staff.id === note.byuserid);
    if (!staffMember)
      return (
        <div>
          <span>by </span>
          <span className={styles.blue}>{note.byusername} </span>
        </div>
      );
    return (
      <div>
        <span>by </span>
        <span className={styles.blue}>{staffMember?.forenames} </span>
        <span className={styles.blue}>{staffMember?.surname}</span>
      </div>
    );
  };

  if (noteToDelete) {
    return (
      <div className={styles.note}>
        <div className={styles.note_value}>{noteToDelete.value}</div>
        <div className={styles.note_data}>
          {noteToDelete.author}
          <div className={styles.note_data_right}>
            <div>{noteToDelete.date}</div>
          </div>
        </div>
        <div className={styles.buttons}>
          <SecondaryButton
            style={{
              fontWeight: theme.regular,
              border: `1px solid ${theme.neutral2}`,
              padding: "8px 16px",
            }}
            onClick={() => setNoteToDelete(null)}
          >
            Go back
          </SecondaryButton>
          <PrimaryButton
            startIcon={<DeleteIcon />}
            onClick={() => {
              deleteNote(noteToDelete.id);
              setNoteToDelete(null);
            }}
            style={{
              backgroundColor: theme.error2,
              fontWeight: theme.regular,
              border: `none`,
              padding: "8px 16px",
            }}
          >
            Delete note
          </PrimaryButton>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.notes}>
      <h3>New note</h3>
      <UserNotesForm onSubmit={onSaveNote} error={error} />
      <h2>Previous notes</h2>
      {hubNotes?.length === 0 && <p className={styles.noNotes}>None found</p>}
      <div className={styles.notes_previous}>
        {hubNotes?.map((note) => (
          <div className={styles.note}>
            <div className={`${styles.note_value} fs-mask`}>{note.value}</div>
            <div className={styles.note_data}>
              {getNoteAuthor(note)}
              <div className={styles.note_data_right}>
                <SecondaryButton
                  onClick={() =>
                    setNoteToDelete({
                      value: note.value,
                      author: getNoteAuthor(note),
                      date: format(new Date(note.ts), "dd.MM.yy"),
                      id: note.id,
                    })
                  }
                  style={{ color: theme.error2, fontWeight: theme.regular }}
                >
                  Delete note
                </SecondaryButton>
                <div>{format(new Date(note.ts), "dd.MM.yy")}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditNotes;
