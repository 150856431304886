import React, { useState } from "react";
import "../../../node_modules/react-vis/dist/style.css";
import { PropTypes } from "prop-types";
import theme from "../../Styles/theme.scss";
import styles from "./styles.module.scss";
// Components
import {
  XYPlot,
  XAxis,
  YAxis,
  LineSeries,
  AreaSeries,
  CustomSVGSeries,
  Crosshair,
  ChartLabel,
} from "react-vis";
import CrosshairContainer from "../Crosshair";
// date-time
import format from "date-fns/format";
import { UnexpectedIcon, ExpectedIcon, AtRiskIcon } from "../../Styles/Icons";
import { BehaviourType } from "@intelligentlilli/lilli-utils";
import { AutoSizer } from "react-virtualized";

const FlightPathChart = (props) => {
  // local state
  const [motion, setMotion] = useState();
  const [series, setSeries] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  // Where the flightpath chart is used for pdfs it is too small to keep it's label text size and icon sizes. This variable is used to decide when to use smaller fonts.
  const smallTypeface = width < 300;
  const showLabel = width > 350;

  // Function to format the chart data into the required x, y react-vis format.
  const formatData = (data) =>
    data.map((reading) => {
      // For movement we are considering anything above the minimum to be good i.e. 'Expected'
      let expected = reading.value >= reading.expectedMin;
      if (props.behaviour === BehaviourType.TEMPERATURE) {
        expected = reading.expected === "within-range";
      }
      // Where we have both the expectedMin and the expectedMax then we have a flightpath available
      let flightpathAvailable =
        reading.expectedMax != null && reading.expectedMin != null;

      // We need smaller icons for the small charts used in the pdfs
      const iconSize = smallTypeface ? "40%" : "60%";

      // For now sustenance is a special case where we have dayRatings but no flightpath. In this case we still want to render the correct icon
      if (props.dateType !== "day" && props.sustenance) {
        flightpathAvailable = true;
      }
      // Return the requited x and y values along with the custom marker dependent on being expected or unexpected.
      return {
        x: reading.ts,
        y: reading.value,
        customComponent: () => (
          <g
            transform={
              flightpathAvailable && !smallTypeface
                ? "translate(-6 -6)"
                : "translate(-4 -4)"
            }
          >
            {flightpathAvailable ? (
              <circle
                cx={smallTypeface ? "4" : "6"}
                cy={smallTypeface ? "4" : "6"}
                r={smallTypeface ? "4" : "6"}
                fill={theme.white}
              />
            ) : (
              <circle cx="4" cy="4" r="4" fill={theme.primaryTeal} />
            )}
            {flightpathAvailable &&
              (reading.dayRating === 0 ||
              (props.dateType === "day" && expected) ? (
                <ExpectedIcon
                  width={iconSize}
                  height={iconSize}
                  // Making the viewBox responsive to the mobile view
                  viewBox={`0 0 ${width} ${height}`}
                  style={{ color: theme.green }}
                />
              ) : reading.dayRating === 1 ||
                (props.dateType === "day" && !expected) ? (
                <UnexpectedIcon
                  width={iconSize}
                  height={iconSize}
                  viewBox={`0 0 ${width} ${height}`}
                  style={{ color: theme.unexpected }}
                />
              ) : (
                <AtRiskIcon
                  width={iconSize}
                  height={iconSize}
                  viewBox={`0 0 ${width} ${height}`}
                  style={{ color: theme.risk3 }}
                />
              ))}
          </g>
        ),
      };
    });

  // Function to generate the flightpath.
  const generateFlightPath = (data) =>
    data
      ?.filter(
        (reading) => !isNaN(reading.expectedMax) && !isNaN(reading.expectedMin)
      )
      .map((reading) => {
        return {
          x: reading.ts,
          y: reading.expectedMax,
          y0: reading.expectedMin,
        };
      });

  const splitArray = (data) => {
    // Split the data array by enteries that have undefined values. This allows us to plot seperate lines
    const dataArray = [];
    let startIndex = 0;
    // Where there is an undefined slice the array and add it to dataArray
    data?.forEach((dataPoint, index) => {
      if (dataPoint.value === undefined || dataPoint.value === null) {
        if (data[index - 1]?.value !== undefined) {
          dataArray.push(data.slice(startIndex, index));
        }
        startIndex = index + 1;
      }
      // If the last datapoint is present grab the last slice
      if (index === data.length - 1 && dataPoint.value !== undefined) {
        dataArray.push(data.slice(startIndex, index + 1));
      }
    });
    return dataArray;
  };

  const dataArray = splitArray(props.data);

  // Generate the formatted data
  const formattedDataArray = dataArray.map((data) => formatData(data));
  // Generate the flightpath
  const flightPath = generateFlightPath(
    props.data?.filter(
      (data) => data.value !== undefined && data.value !== null
    )
  );

  // Find the minimum and maximum values of both the data and the flightpath to constrain the y axis
  const minValue =
    formattedDataArray &&
    flightPath &&
    Math.min(
      ...formattedDataArray.flat().map((d) => d.y),
      ...flightPath.flat().map((d) => d.y0)
    );
  const maxValue =
    formattedDataArray &&
    flightPath &&
    Math.max(
      ...flightPath.flat().map((d) => d.y),
      ...formattedDataArray.flat().map((d) => d.y)
    );

  const getYDomain = () => {
    // There's a bug where if all the data points are the same y value then the resulting chart
    // takes up very little vertical space (common with the sleep behaviour), this fixes it
    if (minValue === maxValue) {
      return [-0.1, maxValue * 2 || 1];
    }
    // If the behaviour wants a custom y axis range get it
    if (props.yDomain) {
      return props.yDomain(minValue, maxValue);
    }
    // Else return the default range
    return [
      minValue - (maxValue - minValue) * 0.1,
      maxValue + (maxValue - minValue) * 0.1,
    ];
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        setWidth(width);
        setHeight(height);
        return (
          <XYPlot
            width={width}
            height={height}
            xType="time"
            animation={true && "stiff"}
            xDomain={props.xDomain}
            yDomain={getYDomain()}
            onMouseLeave={() => setMotion(null)}
            style={{ paddingLeft: showLabel ? 4 : 0, overflow: "visible" }}
          >
            <ChartLabel
              text={props.yAxisTitle}
              includeMargin={false}
              className={styles["rv-xy-plot__axis__title"]}
              xPercent={width > 400 ? -0.08 : -0.12}
              yPercent={0.06}
              style={{
                transform: "rotate(-90)",
                textAnchor: "end",
              }}
            />

            <XAxis
              tickTotal={props.dateType === "day" ? 10 : 7}
              tickFormat={(d) => {
                if (props.dateType === "day") {
                  return format(d, "haaa");
                }
                return format(d, "EEE");
              }}
              style={{
                line: { stroke: theme.teal_opaque },
                ticks: { stroke: theme.teal_opaque },
                text: {
                  stroke: "none",
                  fill: theme.primaryTeal,
                  fontWeight: 400,
                  fontSize: smallTypeface ? 9 : theme.small_font_size,
                },
              }}
            />
            <YAxis
              style={{
                line: { stroke: theme.teal_opaque },
                ticks: { stroke: theme.teal_opaque },
                text: {
                  stroke: "none",
                  fill: theme.primaryTeal,
                  fontWeight: 400,
                  fontSize: smallTypeface ? 9 : theme.small_font_size,
                },
              }}
            />
            <AreaSeries
              className="chart"
              color={theme.success1}
              opacity={0.2}
              curve={"curveMonotoneX"}
              data={flightPath}
            />
            {formattedDataArray.map((data, s) => (
              <LineSeries
                className="area-elevated-line-series"
                stroke={theme.primaryTeal}
                data={data}
                onNearestX={(motion) => s === series && setMotion([motion])}
                onSeriesMouseOver={() => setSeries(s)}
                curve="curveMonotoneX"
                key={s}
              />
            ))}
            {motion && props.crosshair && (
              <Crosshair values={motion}>
                <CrosshairContainer>
                  <p>
                    {props.dateType === "day"
                      ? format(motion[0].x, "haaa")
                      : format(motion[0].x, "EEEE do")}
                  </p>
                  {props.crosshairHint(motion[0].y)}
                </CrosshairContainer>
              </Crosshair>
            )}
            {formattedDataArray.map((data, index) => (
              <CustomSVGSeries data={data} key={index} />
            ))}
          </XYPlot>
        );
      }}
    </AutoSizer>
  );
};

FlightPathChart.propTypes = {
  data: PropTypes.array,
  dateType: PropTypes.string,
  crosshair: PropTypes.bool,
  xDomain: PropTypes.array,
  yAxisTitle: PropTypes.string,
  crosshairHint: PropTypes.func,
};

FlightPathChart.defaultProps = {
  crosshair: true,
};

export default FlightPathChart;
