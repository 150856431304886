import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
// Components
import Page from "../../Components/Page";
import DashboardSearch from "../../Components/DashboardSearch";
import DashboardHeader from "../../Components/DashboardHeader";
import DashboardNotificationsSection from "../../Components/DashboardNotificationsSection";
// Utils
import { getIsUserJustAnInstaller } from "../../Services/Utils";

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const userRoles = user?.roles;
  const userIsJustAnInstaller = getIsUserJustAnInstaller(userRoles);
  // Get any pre set filters
  const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState(
    location.state?.filter || userIsJustAnInstaller ? "installation" : "all"
  );
  // Tabs note: when notifications are selected, onClick updates the URL
  // Tabs note: when clicking "awaiting installation" -> this goes to "users" and updates the filter
  const [selectedTab, setSelectedTab] = useState("users"); // other options: staff, reports

  useEffect(() => {
    if (userIsJustAnInstaller) {
      setSelectedFilter("installation");
    }
  }, [userIsJustAnInstaller]);

  return (
    <Page className={styles.page}>
      <div className={styles.content}>
        <DashboardHeader />
        <div className={styles.search_and_notifications_container}>
          <DashboardSearch
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            userIsJustAnInstaller={userIsJustAnInstaller}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
          <DashboardNotificationsSection
            setSelectedFilter={setSelectedFilter}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
