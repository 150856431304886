import React from "react";

export const SuccessTickIcon = ({ color, width }) => {
  return (
    <svg
      width={width || "20"}
      height={width * 0.75 || "15"}
      viewBox="0 0 20 15"
      fill="none"
      id="SuccessTickIcon"
    >
      <path
        d="M18.5995 0.692507L7.08368 12.2075C7.00625 12.2852 6.91424 12.3469 6.81292 12.389C6.71161 12.4311 6.60297 12.4527 6.49326 12.4527C6.38355 12.4527 6.27492 12.4311 6.1736 12.389C6.07228 12.3469 5.98027 12.2852 5.90285 12.2075L1.44951 7.75001C1.37209 7.67228 1.28008 7.6106 1.17876 7.56851C1.07744 7.52643 0.968807 7.50477 0.859095 7.50477C0.749384 7.50477 0.640752 7.52643 0.539433 7.56851C0.438115 7.6106 0.346103 7.67228 0.268678 7.75001C0.190948 7.82743 0.129271 7.91944 0.0871861 8.02076C0.0451014 8.12208 0.0234375 8.23071 0.0234375 8.34042C0.0234375 8.45013 0.0451014 8.55877 0.0871861 8.66008C0.129271 8.7614 0.190948 8.85342 0.268678 8.93084L4.72368 13.385C5.19364 13.8541 5.83051 14.1175 6.49451 14.1175C7.15851 14.1175 7.79539 13.8541 8.26535 13.385L19.7803 1.87251C19.858 1.7951 19.9195 1.70314 19.9615 1.6019C20.0035 1.50065 20.0252 1.39212 20.0252 1.28251C20.0252 1.17289 20.0035 1.06436 19.9615 0.963117C19.9195 0.861875 19.858 0.769916 19.7803 0.692507C19.7029 0.614776 19.6109 0.553099 19.5096 0.511014C19.4083 0.46893 19.2996 0.447266 19.1899 0.447266C19.0802 0.447266 18.9716 0.46893 18.8703 0.511014C18.7689 0.553099 18.6769 0.614776 18.5995 0.692507Z"
        fill={color || "white"}
      />
    </svg>
  );
};
