import { postHubProperty as postHubPropertyAPI } from "@intelligentlilli/api-layer";

const postHubProperty = async (server, hubId, key, value) => {
  try {
    const response = await postHubPropertyAPI(server, hubId, key, value, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default postHubProperty;
