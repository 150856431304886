import React from "react";
import styles from "./styles.module.scss";
// Components
import DeviceIdQrReader from "../DeviceIdQrReader";
// Forms
import DeviceIdForm from "../../Forms/DeviceIdForm";

export const ManuallyEnterQRCode = ({
  buttonClass,
  brokenQRCode,
  setBrokenQRCode,
  onLinkDevice,
  devicestringError,
  isDesktop,
}) => {
  const manuallyLinkDevice = (deviceId) => {
    setBrokenQRCode(false);
    onLinkDevice(deviceId);
  };

  return (
    <div className={styles.manual_entry}>
      {brokenQRCode && (
        <div className={styles.manual_entry_message}>
          There is a problem with the QR code please enter it manually
        </div>
      )}
      <DeviceIdForm
        buttonClass={buttonClass}
        onSubmit={manuallyLinkDevice}
        error={devicestringError}
        isDesktop={isDesktop}
      />
    </div>
  );
};

export const ScanQRCode = ({
  setBrokenQRCode,
  devicestringError,
  onLinkDevice,
}) => {
  // We have some stickers on our older boxes with incorrect QR codes.
  // The affected QR codes end in FC0 and so require manual entry.
  const linkAfterCheck = (deviceId) => {
    if (deviceId.includes("FC0")) {
      setBrokenQRCode(true);
    } else {
      onLinkDevice(deviceId);
    }
  };

  return (
    <div className={styles.component}>
      <DeviceIdQrReader onSubmit={linkAfterCheck} error={devicestringError} />
    </div>
  );
};
