import { getHubIndependenceAnalysis as getHubIndependenceAnalysisAPI } from "@intelligentlilli/api-layer";

const getHubIndependenceAnalysis = async (server, id, days, endTime) => {
  try {
    const response = await getHubIndependenceAnalysisAPI(
      server,
      id,
      days,
      endTime,
      "web"
    );

    if (response.ok) {
      return response.body;
    } else {
      return null;
    }
  } catch (err) {
    console.log(`Error fetching data for independence:`, err);
    return err;
  }
};

export default getHubIndependenceAnalysis;
