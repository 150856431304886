import React from "react";
import styles from "./styles.module.scss";
import GoBackButton from "../GoBackButton";
import DeleteButton from "../DeleteButton";

const ConfirmDelete = ({ confirm, confirmLabel, goBack }) => {
  return (
    <div className={styles.confirm_delete_buttons}>
      <GoBackButton goBack={goBack} flex={false} />
      <DeleteButton
        onClick={confirm}
        label={confirmLabel}
        padding={"7.5px 14px"}
      />
    </div>
  );
};

export default ConfirmDelete;
