import React from "react";
import styles from "./styles.module.scss";

const InstallationInstructions = ({ instructions }) => (
  <ol className={styles.list}>
    {instructions.map((instruction, index) => (
      <div key={index} className={styles.item}>
        <li>{instruction.text}</li>
        {instruction?.notes?.map((note) => (
          <p>
            <b>Note: </b>
            {note}
          </p>
        ))}
        {instruction?.images?.map((image) => (
          <img
            src={image.src}
            className={styles.image}
            alt={image.alt}
            style={{ width: image.width }}
          />
        ))}
      </div>
    ))}
  </ol>
);
export default InstallationInstructions;
