import React from "react";
import styles from "./styles.module.scss";
// Components
import { BehaviourTypeIcon } from "../../Services/Utils/Constants/behaviours";
import SummaryWeekMonth from "../SummaryWeekMonth";
import VictoryFlightpathChart from "../../Charts/VictoryFlightpathChart";
// Utils
import {
  BehaviourType,
  BehaviourTypeText,
  getRiskDates,
} from "@intelligentlilli/lilli-utils";
import { getAvgOrCountOfEvents, avgOrCountLabel } from "../../Services/Utils";
import { format, startOfDay, parseISO } from "date-fns";

const PdfBehaviourSummary = ({ week, pageNumber }) => {
  const data = week?.dailyResults;
  const from = startOfDay(
    week?.startDate ? parseISO(week?.startDate) : new Date()
  );
  const to = week?.endDate ? parseISO(week?.endDate) : new Date();

  // Adding a default behaviour type as one must be present for the Icon component generation
  const behaviourType = week?.behaviour || BehaviourType.MOVEMENT;
  const Icon = BehaviourTypeIcon[behaviourType];

  // Chart labels
  const getDayLabel = (value) => {
    if (value === 1) {
      return "Day";
    }
    return "Days";
  };

  const getYAxisLabel = (behaviour, behaviourData) => {
    switch (behaviour) {
      case BehaviourType.MOVEMENT:
        return "Movements";
      case BehaviourType.SUSTENANCE:
        return "Events";
      case BehaviourType.SLEEP:
        return "Disturbances";
      case BehaviourType.INDEPENDENCE:
        // Get the max value for the time outside. This is used to decide whether to convert the values to hours rather than minutes for readability in the graph
        const hasData = !!behaviourData;
        const maxValue = hasData && Math.max(behaviourData.map((d) => d.value));
        const maxFlightpathValue =
          hasData && Math.max(behaviourData?.map((d) => d?.expectedMax || 0));
        const maxOverallValue =
          hasData && Math.max(maxValue, maxFlightpathValue);
        const convertToHours = maxOverallValue / 60 > 1;
        return `Time outside (${convertToHours ? "hrs" : "mins"})`;
      case BehaviourType.TEMPERATURE:
        return "Temperature °C";
      default:
        return {};
    }
  };

  /* Summary stats */
  // Shared stats
  const average = getAvgOrCountOfEvents(data, "value", "average") || "--"; // This will not work for independence; do that one separately.
  const daysAtRisk = getRiskDates(data, 2)?.length;
  const daysUnexpected = getRiskDates(data, 1)?.length;
  const daysExpected = getRiskDates(data, 0)?.length;
  const daysWithNoData = data
    ? data?.filter((day) => day?.value === null)?.length
    : "--";
  const sharedStats = [
    {
      value: daysAtRisk,
      label: `At risk ${getDayLabel(daysAtRisk)}`,
    },
    {
      value: daysUnexpected,
      label: `Unexpected ${getDayLabel(daysUnexpected)}`,
    },
    {
      value: daysExpected,
      label: `Expected ${getDayLabel(daysExpected)}`,
    },
    daysWithNoData > 0 && {
      value: daysWithNoData,
      label: `${getDayLabel(daysWithNoData)} with no data`,
    },
  ];

  // Movement
  const movementData = [
    {
      value: average,
      label: `Avg. Event${average !== 1 && "s"} per day`,
    },
  ].concat([...sharedStats]);

  // Sustenance
  const sustenanceData = sharedStats;

  // Independence
  // Get the total duration outside in minutes
  const durationOutside = data
    ? [...data]?.reduce((total, val) => total + val?.value, 0)
    : "--";
  const showHoursAndMinutes = durationOutside >= 60 || false;
  const durationOutsideHours = durationOutside
    ? Math.floor(durationOutside / 60)
    : "--";
  const durationOutsideRemainder = durationOutside
    ? durationOutside - 60 * durationOutsideHours
    : "--";
  // Get the days with no time outside for the week view
  const noTimeOutside = data
    ? [...data]?.filter((day) => day.value === 0).length
    : "--";
  // Calculate the expected min and max time outdise
  // let expectedValuesInMinutes = true;
  // let expectedMin = data
  //   ? [...data]?.reduce((total, val) => total + val?.expectedMin || 0, 0)
  //   : "--";
  // let expectedMax = data
  //   ? [...data]?.reduce((total, val) => total + val?.expectedMax || 0, 0)
  //   : "--";
  // // Decide whether to convert the min/max numbers to hours only if they are both greater than an hour or zero
  // if (expectedMax >= 60 && (expectedMin >= 60 || expectedMin === 0)) {
  //   expectedMin = expectedMin / 60;
  //   expectedMax = expectedMax / 60;
  //   expectedValuesInMinutes = false;
  // }
  // // Finally round the expected values for rendering
  // expectedMin = expectedMin >= 0 ? Math.round(expectedMin) : "--";
  // expectedMax = expectedMax >= 0 ? Math.round(expectedMax) : "--";
  // Pull together all the summary stats for week/month view
  const independenceData = [
    {
      value: showHoursAndMinutes ? durationOutsideHours : durationOutside,
      units: showHoursAndMinutes ? "Hrs" : "min",
      value2: durationOutsideRemainder > 0 ? durationOutsideRemainder : null,
      units2: durationOutsideRemainder > 0 ? "min" : null,
      label: "Total time outside",
    },
    // TO DO: Bring this back at a later date
    // {
    //   value: `${expectedMin}-${expectedMax}`,
    //   units: `${expectedValuesInMinutes ? "min" : "Hrs"}`,
    //   label: "Expected range",
    // },
    {
      value: noTimeOutside,
      label: `${getDayLabel(noTimeOutside)} with no time outside`,
    },
    daysWithNoData > 0 && {
      value: daysWithNoData,
      label: `${getDayLabel(daysWithNoData)} with no data`,
    },
  ];
  // Nighttime activity
  const disturbedDays = data
    ? [...data].reduce(
        (total, day) => (day?.value > 0 ? total + 1 : total + 0),
        0
      )
    : "--";
  const totalDisturbances = data
    ? [...data].reduce((total, day) => total + day?.value, 0)
    : "--";
  const sleepLabel = data ? avgOrCountLabel("sleep", average, "week") : "Error";
  const sleepData = [
    {
      value: disturbedDays,
      label: `Disturbed night${disturbedDays !== 1 && "s"}`,
    },
    {
      value: totalDisturbances,
      label: `Total Disturbance${totalDisturbances !== 1 && "s"}`,
    },
    { value: average, label: sleepLabel },
    daysWithNoData > 0 && {
      value: daysWithNoData,
      label: `${getDayLabel(daysWithNoData)} with no data`,
    },
  ];
  //Temperature
  //Get min and max temperature
  const minTemp = isNaN(average)
    ? "--"
    : Math.round(
        Math.min(
          ...[...data]
            ?.filter((i) => i?.value !== null)
            ?.map((day) => day?.value)
        )
      );

  const maxTemp = isNaN(average)
    ? "--"
    : Math.round(
        Math.max(
          ...[...data]
            ?.filter((i) => i?.value !== null)
            ?.map((day) => day?.value)
        )
      );
  const temperatureData = [
    {
      value: isNaN(average) ? "--" : Math.round(average),
      label: "Avg. Temperature",
      units: "°C",
    },
    { value: minTemp, label: "Min. Temp.", units: "°C" },
    { value: maxTemp, label: "Max. Temp.", units: "°C" },
    daysWithNoData > 0 && {
      value: daysWithNoData,
      label: `${getDayLabel(daysWithNoData)} with no data`,
    },
  ];
  /* End Summary stats */

  // Return the correct summary stats
  const getSummaryData = (behaviour) => {
    switch (behaviour) {
      case BehaviourType.SLEEP:
        return sleepData;
      case BehaviourType.MOVEMENT:
        return movementData;
      case BehaviourType.SUSTENANCE:
        return sustenanceData;
      case BehaviourType.INDEPENDENCE:
        return independenceData;
      case BehaviourType.TEMPERATURE:
        return temperatureData;
      default:
        return {};
    }
  };

  return data ? (
    <div className={styles.behaviour_summary}>
      <div className={styles.behaviour}>
        <div className={styles.behaviour_top}>
          <div>
            <div className={styles.behaviour_title}>
              <Icon style={{ marginRight: 8 }} />
              <div className={styles.behaviour_name}>
                {behaviourType.toLowerCase() === "sleep"
                  ? "Night-time activity"
                  : behaviourType.toLowerCase() === "sustenance"
                  ? "Eating & Drinking"
                  : behaviourType.toLowerCase() === "independence"
                  ? "Time Outside"
                  : BehaviourTypeText[behaviourType]}
              </div>
            </div>
          </div>
          <div className={styles.week_summary_date}>
            Week {week?.weekNumber}: {format(from, "dd LLL")} -{" "}
            {format(to, "dd LLL")}
          </div>
        </div>

        <SummaryWeekMonth
          data={getSummaryData(behaviourType)}
          behaviour={behaviourType.toLowerCase()}
          small={false}
          isPDF={true}
        />

        <div className={styles.behaviour_chart}>
          <VictoryFlightpathChart
            data={data}
            behaviour={behaviourType.toLowerCase()}
            dateType={"week"}
            isPDFreport={true}
            pageNumber={1}
            yAxisTitle={getYAxisLabel(behaviourType)}
            setShowingTooltip={false}
            showingTooltip={false}
          />
        </div>

        <div
          className={`${styles.behaviour_sentences} ${
            pageNumber === 1 ? styles.small_margin_top : undefined
          } fs-mask`}
        >
          <ul>
            {week?.sentences?.map((sentence, index) => (
              <li key={index}>{sentence}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ) : null;
};

export default PdfBehaviourSummary;
