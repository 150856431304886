import { postCreateHub as postCreateHubAPI } from "@intelligentlilli/api-layer";

const postCreateHub = async (
  server,
  forenames,
  surname,
  houseNum,
  postcode
) => {
  try {
    const response = await postCreateHubAPI(
      server,
      forenames,
      surname,
      houseNum,
      postcode,
      "web"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default postCreateHub;
