import { getCheckAuthState as getCheckAuthStateAPI } from "@intelligentlilli/api-layer";

const getCheckAuthState = async (server) => {
  // This endpoint is not yet cached so make the call to the API layer and return the response.
  try {
    const response = await getCheckAuthStateAPI(server, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getCheckAuthState;
