import { getServerVersion as getServerVersionAPI } from "@intelligentlilli/api-layer";

const getServerVersion = async (server) => {
  try {
    const response = await getServerVersionAPI(server, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getServerVersion;
