import { postUserEmail as postUserEmailAPI } from "@intelligentlilli/api-layer";

const postUserEmail = async (server, id, email) => {
  try {
    const response = await postUserEmailAPI(server, id, email, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default postUserEmail;
