import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
// Utils
import {
  sortHigh,
  sortLow,
  areFiltersApplied,
} from "@intelligentlilli/lilli-utils";
import { toggleFilter } from "../../Services/Utils";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setLayoutPreference,
  setRefreshStaff,
} from "../../State/slices/session-slice";
// Components
import AppliedFilters from "../../Components/AppliedFilters";
import CreateStaffSplash from "../../Components/CreateStaffSplash";
import Filter from "../../Components/Filter";
import InstallerListItem from "../../Components/InstallerListItem";
import InstallerCard from "../../Components/InstallerCard";
import LayoutToggle from "../../Components/LayoutToggle";
import NewStyleModal from "../../Components/NewStyleModal";
import Page from "../../Components/Page";
import PageTitle from "../../Components/PageTitle";
import PrimaryButton from "../../Components/PrimaryButton";
import StaffCard from "../../Components/StaffCard";
import SecondaryButton from "../../Components/SecondaryButton";
import StaffListItem from "../../Components/StaffListItem";
import { useViewport } from "../../Components/ViewportProvider";
// icons
import {
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  AddIcon,
} from "../../Styles/Icons";
// forms
import AddUserForm from "../../Forms/AddUserForm";
// Hooks
import { useFade, useGetUserRole, useAddNewStaff } from "../../Services/Hooks";

const DummyStaffFilters = [
  {
    type: "Role",
    filters: [
      { id: 1, name: "Occupational therapist", filtered: false },
      { id: 2, name: "Carer", filtered: false },
      { id: 3, name: "GP", filtered: false },
    ],
  },
];

const Staff = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useViewport();
  const isMobile = width <= 425;
  // custom hooks
  const { userIsManagerOrAdmin } = useGetUserRole();
  const {
    newUserDescription,
    newUserRoles,
    onAddStaffMember,
    resetFormState,
    setNewUserRoles,
    serviceUsersAssigned,
    showSplashScreen,
    isProcessingForm,
    formSuccess,
    staffCreated,
    createStaffEmailError,
    setCreateStaffError,
    addingUser,
    setAddingUser,
  } = useAddNewStaff();

  // local state - other
  const [sortAZ, setSortAZ] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [staffFilters, setStaffFilters] = useState(DummyStaffFilters);

  // redux state
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.staff);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const serviceUsersToSelect = serviceUsers?.map((su) => {
    return {
      id: su?.id,
      label: su?.userstring,
      value: su?.userstring,
    };
  });
  const layoutPreference = useSelector(
    (state) => state.session.layoutPreference
  );

  // Custom hooks - Modal to fade in and fade out
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  const closeForm = () => {
    setShowAnimation(false);
  };

  // Seperate out the managers and staff members
  const managers =
    (staff &&
      staff.filter((staffMember) => staffMember?.roles?.includes("manager"))) ||
    [];
  const staffUsers =
    (staff &&
      staff.filter(
        (staffMember) =>
          !staffMember?.roles?.includes("admin") &&
          !staffMember?.roles?.includes("manager") &&
          staffMember?.roles?.includes("user")
      )) ||
    [];
  const installers =
    (staff &&
      staff.filter(
        (staffMember) =>
          !staffMember?.roles?.includes("admin") &&
          !staffMember?.roles?.includes("manager") &&
          !staffMember?.roles?.includes("user") &&
          staffMember?.roles?.includes("installer")
      )) ||
    [];

  // Variable for sorting the users (staff)
  let sortedStaff = staffUsers;
  if (sortAZ === true) {
    sortedStaff = sortHigh(staffUsers, "forenames");
  } else if (sortAZ === false) {
    sortedStaff = sortLow(staffUsers, "forenames");
  }

  // Variable for sorting the installers
  let sortedInstallers = installers;
  if (sortAZ === true) {
    sortedInstallers = sortHigh(installers, "forenames");
  } else if (sortAZ === false) {
    sortedInstallers = sortLow(installers, "forenames");
  }

  // Variable for sorting the managers
  let sortedManagers = managers;
  if (sortAZ === true) {
    sortedManagers = sortHigh(managers, "forenames");
  } else if (sortAZ === false) {
    sortedManagers = sortLow(managers, "forenames");
  }

  // If the user has arrived with an initial list of filters adding them
  useEffect(() => {
    if (location.state?.filters) {
      location.state?.filters.forEach((filter) =>
        toggleFilter(filter?.id, filter?.type, setStaffFilters)
      );
    }
  }, [location.state?.filters]);

  // The only time Staff members endpoint is called is on App start, with the useFetchInitialData hook
  // Its possible that new staff have been created since logging in (by other managers)
  // Call the staff members endpoint everytime this page is visited
  useEffect(() => {
    dispatch(setRefreshStaff(true));
  }, [dispatch]);

  return (
    <Page className={styles.page}>
      <div className={styles.content}>
        <PageTitle title={"Staff"} />
        <div className={styles.filters}>
          <div className={styles.filters_section}>
            <div className={styles.create_new_user_container}>
              {userIsManagerOrAdmin && (
                <PrimaryButton
                  onClick={() => {
                    setAddingUser(true);
                    setShowAnimation(true);
                    resetFormState();
                    setNewUserRoles({ key: "Installer", value: "installer" });
                  }}
                  startIcon={<AddIcon />}
                >
                  {isMobile ? "Installer" : "New Installer"}
                </PrimaryButton>
              )}
              {userIsManagerOrAdmin && (
                <PrimaryButton
                  onClick={() => {
                    setAddingUser(true);
                    setShowAnimation(true);
                    resetFormState();
                    setNewUserRoles({ key: "Staff", value: "installer|user" });
                  }}
                  startIcon={<AddIcon />}
                >
                  {isMobile ? "Staff" : "New Staff"}
                </PrimaryButton>
              )}
              {userIsManagerOrAdmin && (
                <PrimaryButton
                  onClick={() => {
                    setAddingUser(true);
                    setShowAnimation(true);
                    resetFormState();
                    setNewUserRoles({
                      key: "Manager",
                      value: "installer|manager",
                    });
                  }}
                  startIcon={<AddIcon />}
                >
                  {isMobile ? "Manager" : "New Manager"}
                </PrimaryButton>
              )}
            </div>
          </div>
          <div className={styles.filters_section}>
            {/* <SecondaryButton
            onClick={() => setShowFilter(true)}
            className={styles.button}
            startIcon={<FilterIcon />}
          >
            Filter
          </SecondaryButton> */}
            <SecondaryButton
              startIcon={sortAZ ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              onClick={() => setSortAZ(!sortAZ)}
            >
              Sort: A-Z
            </SecondaryButton>
            <LayoutToggle
              dispatch={dispatch}
              setLayoutPreference={setLayoutPreference}
              layoutPreference={layoutPreference}
            />
          </div>
        </div>
        {isVisible && (
          <NewStyleModal
            showCloseIcon={showSplashScreen ? false : true}
            hide={() => {
              // Works in conjunction with the "CloseIcon" inside the modal
              setAddingUser(false);
              setShowAnimation(false);
              setCreateStaffError("");
              resetFormState();
            }}
            title={
              addingUser && !showSplashScreen
                ? `Add new ${newUserRoles.key}`
                : ""
            }
            context={addingUser && !showSplashScreen ? newUserDescription : ""}
            useFade={true}
            showAnimation={showAnimation}
          >
            {!showSplashScreen && (
              <AddUserForm
                onSubmit={onAddStaffMember}
                createStaffEmailError={createStaffEmailError}
                serviceUsersToSelect={serviceUsersToSelect}
                isLoading={isProcessingForm}
                typeOfUser={newUserRoles?.key}
              />
            )}
            {showSplashScreen && (
              <CreateStaffSplash
                onSubmit={closeForm}
                staff={staffCreated}
                serviceUsersAssigned={serviceUsersAssigned}
                isSuccess={formSuccess}
                typeOfUser={newUserRoles?.key}
              />
            )}
          </NewStyleModal>
        )}

        {areFiltersApplied(staffFilters) && (
          <AppliedFilters filters={staffFilters} setFilters={setStaffFilters} />
        )}
        <h2>Managers</h2>
        {layoutPreference === "grid" && sortedManagers?.length > 0 && (
          <div className={styles.usersGrid}>
            {sortedManagers.map((staffMember, index) => (
              <StaffCard
                key={index}
                staffMember={staffMember}
                onClick={() => navigate(`/staff/${staffMember.id}`)}
              />
            ))}
          </div>
        )}
        {layoutPreference === "list" && sortedManagers?.length > 0 && (
          <div className={styles.usersList}>
            {/* Display when the screen is at a large size */}
            <div className={styles.usersList_header_lrg}>
              <div>Manager name</div>
              <div>Role</div>
              <div>No. service users</div>
            </div>
            {/* Display when the screen is at a small size */}
            <div className={styles.usersList_header_sm}>
              <div>Managers</div>
              <div className={styles.usersList_header_right}>
                No. service users
              </div>
            </div>
            {sortedManagers.map((staffMember, index) => (
              <StaffListItem
                key={index}
                staffMember={staffMember}
                onClick={() => navigate(`/staff/${staffMember.id}`)}
              />
            ))}
          </div>
        )}
        <h2>Staff</h2>
        {sortedStaff?.length === 0 && <div>none</div>}
        {layoutPreference === "grid" && sortedStaff?.length > 0 && (
          <div className={styles.usersGrid}>
            {sortedStaff.map((staffMember, index) => (
              <StaffCard
                key={index}
                staffMember={staffMember}
                onClick={() => navigate(`/staff/${staffMember.id}`)}
              />
            ))}
          </div>
        )}
        {layoutPreference === "list" && sortedStaff?.length > 0 && (
          <div className={styles.usersList}>
            {/* Display when the screen is at a large size */}
            <div className={styles.usersList_header_lrg}>
              <div>Staff name</div>
              <div>Role</div>
              <div>No. service users</div>
            </div>
            {/* Display when the screen is at a small size */}
            <div className={styles.usersList_header_sm}>
              <div>Staff Team</div>
              <div className={styles.usersList_header_right}>
                No. service users
              </div>
            </div>
            {sortedStaff.map((staffMember, index) => (
              <StaffListItem
                key={index}
                staffMember={staffMember}
                onClick={() => navigate(`/staff/${staffMember.id}`)}
              />
            ))}
          </div>
        )}
        <h2>Installers</h2>
        {layoutPreference === "grid" && sortedInstallers?.length > 0 && (
          <div className={styles.usersGrid}>
            {sortedInstallers.map((installer, index) => (
              <InstallerCard
                key={index}
                installer={installer}
                onClick={() => navigate(`/staff/${installer.id}`)}
              />
            ))}
          </div>
        )}
        {layoutPreference === "list" && sortedInstallers?.length > 0 && (
          <div className={styles.usersList}>
            <div className={styles.usersList_header}>
              <div>Installer</div>
              <div></div>
            </div>
            {sortedInstallers.map((installer, index) => (
              <InstallerListItem
                key={index}
                installer={installer}
                onClick={() => navigate(`/staff/${installer.id}`)}
              />
            ))}
          </div>
        )}
        {sortedInstallers?.length === 0 && <div>none</div>}
        {showFilter && (
          <Filter
            show={showFilter}
            hide={() => setShowFilter(false)}
            filters={staffFilters}
            setFilters={setStaffFilters}
          />
        )}
      </div>
    </Page>
  );
};

export default Staff;
