import React from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// Components
import Page from "../../../Components/Page";
import InstallationBanner from "../../../Components/InstallationBanner";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationHint from "../../../Components/InstallationHint";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallationArt from "../../../Components/InstallationArt";
import { mobileBreakpoint } from "../../../Services/config";

const TopContentDesktop = ({ hint }) => {
  return (
    <>
      <div className={styles.instructions}>
        <div className={styles.header_instructions}>
          Place and plug in the Lilli Hub
        </div>
        <ol className={styles.instructions_steps}>
          <li>The biggest box in your package is the Lilli hub.</li>
          <li>
            Plug in the Lilli hub in a central location in the home (see tips)
          </li>
        </ol>
      </div>
      <InstallationHint hints={hint} />
    </>
  );
};

const TopContentMobile = ({ hint }) => {
  return (
    <>
      <div className={styles.instructions}>
        <h2 className={styles.header_instructions}>Connect the Lilli Hub</h2>
        <InstallationHint hints={hint} />
      </div>
      <ol className={styles.instructions_steps}>
        <li>The biggest box in your package is the Lilli hub.</li>
        <li>
          Plug in the Lilli hub in a central location in the home (see tips)
        </li>
      </ol>
    </>
  );
};

const InstallHub = () => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;
  const installationHint = [
    "Try to find a spot for the Lilli hub that isn't behind any large objects or in a cupboard, as this will make it harder to receive the signals from the sensor network.",
    // "The Lilli hub uses mobile internet, please ensure there is a strong signal. One way to check is by using your mobile phone to check the location has a mobile internet signal.",
    // "If mobile internet is poor in the area and broadband is available then please use the provided ethernet cable to connect the Lilli hub to the broadband router.",
    // "Connectivity can be reduced by dense walls, metal structures and by being in a central location within larger homes.",
  ];

  return (
    <Page className={styles.page}>
      {isDesktop && <InstallationBanner />}
      <InstallationCard style={styles.card}>
        <div className={styles.card_container}>
          <div className={styles.card_left}>
            <img
              src="/images/installation/hub/hub.png"
              alt="Lilli hub"
              className={styles.logo}
            />
          </div>
          <div className={styles.card_right}>
            <InstallationArt />
            <div className={styles.content}>
              <img
                src="/images/installation/hub/hub.png"
                alt="Lilli hub"
                className={`${styles.logo} ${styles.mobile}`}
              />
              {isDesktop ? (
                <TopContentDesktop hint={installationHint} />
              ) : (
                <TopContentMobile hint={installationHint} />
              )}
              <div className={styles.buttons}>
                <InstallationGoBackButton
                  isDesktop={isDesktop}
                  marginRight={isDesktop ? "24px" : "0px"}
                  marginTop={"12px"}
                  onClick={() => navigate("/install")}
                  label={"Exit install"}
                  textColor={theme.error2}
                  cypresstestid="Installation-Hub-Tips-Go-Back-Btn-6a"
                />
                <InstallationNextButton
                  isDesktop={isDesktop}
                  onClick={() =>
                    navigate(`/install/hub/provisioning/${id}/${devicestring}`)
                  }
                  label={"Continue"}
                  cypresstestid={"Installation-Hub-Tips-6"}
                />
              </div>
            </div>
          </div>
        </div>
      </InstallationCard>
    </Page>
  );
};

export default InstallHub;
