import { getHubNotes as getHubNotesAPI } from "@intelligentlilli/api-layer";

const getHubNotes = async (server, id) => {
  try {
    const response = await getHubNotesAPI(server, id, "web");
    return response;
  } catch (error) {
    return error;
  }
};

export default getHubNotes;
