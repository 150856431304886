import { postHubAssignDevice as postHubAssignDeviceAPI } from "@intelligentlilli/api-layer";

const postHubAssignDevice = async (server, hubId, devicestring) => {
  try {
    const response = await postHubAssignDeviceAPI(
      server,
      hubId,
      devicestring,
      "web"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default postHubAssignDevice;
