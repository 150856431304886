import { getHubBathroomActivity as getHubBathroomActivityAPI } from "@intelligentlilli/api-layer";

const getHubBathroomActivity = async (server, id, startTime, endTime) => {
  const startTimeString = startTime.toISOString();
  const endTimeString = endTime.toISOString();

  try {
    const response = await getHubBathroomActivityAPI(
      server,
      id,
      startTimeString,
      endTimeString,
      "web"
    );
    if (response.ok) {
      return response.body;
    } else {
      return null;
    }
  } catch (err) {
    console.log(`Error fetching data for bathroom activity:`, err);
    return err;
  }
};

export default getHubBathroomActivity;
