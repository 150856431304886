import { postForgottenPasswordReset as postForgottenPasswordResetAPI } from "@intelligentlilli/api-layer";

const postForgottenPasswordReset = async (server, newPassword, token) => {
  try {
    const response = await postForgottenPasswordResetAPI(
      server,
      newPassword,
      token,
      "web"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default postForgottenPasswordReset;
