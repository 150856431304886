import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import theme from "../../Styles/theme.scss";
import StaffPill from "../StaffPill";

const ServiceUserImage = (props) => {
  return (
    <div
      className={`${styles.image} ${!props.removeBorder && styles.border} 
        ${props.small && styles.small}         
        ${props.extraSmall && styles.extra_small}         
        ${props.small_medium && styles.small_medium}
        ${props.medium && styles.medium}
        ${props.className}`}
      style={props.style}
    >
      {props.extraSmall && (
        <div className={styles.initials}>{props.initials}</div>
      )}

      {!props.extraSmall && (
        <>
          {props.staff ? (
            <div
              style={{
                backgroundColor: theme.primaryTeal,
                borderRadius: "50%",
                width: "100%",
                height: "100%",
              }}
            >
              <img
                style={{ backgroundColor: theme.primaryTeal }}
                src="/images/profile_white.png"
                alt={props.altText || "User profile"}
                className={styles.image_avatar}
              />
              <div
                className={styles.image_avatar_initials}
                style={{
                  color: theme.white,
                  fontSize:
                    props.small || props.small_medium
                      ? theme.baseFontSize
                      : props.medium
                      ? theme.heading2
                      : theme.heading1,
                  fontWeight:
                    props.small || props.small_medium ? "bold" : "normal",
                }}
              >
                {props.initials}
              </div>
              {!props.small && !props.small_medium && !props.hideStaffPill && (
                <StaffPill large={props.largePill} />
              )}
            </div>
          ) : (
            <div>
              <img
                style={{ backgroundColor: theme.white }}
                src="/images/profile_opaque.png"
                alt={props.altText || "User profile"}
                className={styles.image_avatar}
              />
              <div
                className={styles.image_avatar_initials}
                style={{
                  color: theme.primaryTeal,
                  fontSize:
                    props.small || props.small_medium
                      ? theme.baseFontSize
                      : props.medium
                      ? theme.heading2
                      : theme.heading1,
                  fontWeight:
                    props.small || props.small_medium ? "bold" : "normal",
                }}
              >
                {props.initials}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

ServiceUserImage.propTypes = {
  initials: PropTypes.string,
  staff: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  extraSmall: PropTypes.bool,
};

export default ServiceUserImage;
