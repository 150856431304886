import React from "react";
import styles from "./styles.module.scss";
import { ErrorOutlineIcon } from "../../Styles/Icons";

function NoDataBox(props) {
  return (
    <div className={styles.container}>
      <ErrorOutlineIcon />
      <h1 className="fs-mask">{props.title}</h1>
      <p>{props.text}</p>
      {props.children}
    </div>
  );
}

export default NoDataBox;
