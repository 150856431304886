import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
// components
import StaffItemMobile from "../StaffItemMobile";
import { useViewport } from "../ViewportProvider";
// services
import getUserProperties from "../../Services/Cache/Users/getUserProperties";
import { mobileBreakpoint } from "../../Services/config";
import { updateStaffMember } from "../../State/slices/staff-slice";
import FriendsAndFamilyItem from "../FriendsAndFamilyItem";

const DashboardFriendsAndFamily = ({ searchValue }) => {
  const [filteredStaff, setFilteredStaff] = useState([]);

  const dispatch = useDispatch();
  const staff = useSelector((state) => state.staff);
  const friendsAndFamilyUsers = useSelector(
    (state) =>
      state.staff.filter((staff) => staff.roles.includes("friend-or-family")),
    isEqual
  );
  const server = useSelector((state) => state.session.server);
  const navigate = useNavigate();
  const { width } = useViewport();
  const onMobile = width < mobileBreakpoint;

  const augmentedFriendsAndFamily = friendsAndFamilyUsers?.map((staff) => {
    return {
      ...staff,
      serviceUsersString: staff.hubs[0]?.userstring,
      roles: ["Friends & family"],
    };
  });

  const augmentedFriendsAndFamilyString = JSON.stringify(
    augmentedFriendsAndFamily
  );

  const staffIdsString = JSON.stringify(staff.map((staff) => staff.id));

  useEffect(() => {
    if (searchValue) {
      const filteredStaff = augmentedFriendsAndFamily.filter((staff) => {
        // check whether the surname matches the search value
        const surnameMatch =
          staff.surname?.slice(0, searchValue.length).toLowerCase() ===
          searchValue.toLowerCase();
        const firstNameMatch =
          staff.forenames?.slice(0, searchValue.length).toLowerCase() ===
          searchValue.toLowerCase();
        return surnameMatch || firstNameMatch;
      });
      setFilteredStaff(filteredStaff);
    } else {
      setFilteredStaff(augmentedFriendsAndFamily);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, augmentedFriendsAndFamilyString]);

  // Get request for the staff members data and photo
  useEffect(() => {
    async function fetchData() {
      const staffIds = JSON.parse(staffIdsString) || [];
      // Wait for both the getUser and getUserHubs API calls to finish
      await Promise.all(
        staffIds.map((id, index) =>
          getUserProperties(server, id).then(({ body }) => {
            dispatch(
              updateStaffMember({
                index,
                key: "lastActivity",
                value: body.lastActivity,
              })
            );
          })
        )
      );
    }
    fetchData();
  }, [server, staffIdsString, dispatch]);

  if (filteredStaff.length === 0) {
    return (
      <div className={styles.no_content_message}>
        Your service user’s do not have any friend and family users attached.
      </div>
    );
  }

  return (
    <>
      {!onMobile && (
        <div className={styles.table}>
          <div className={styles.table_header}>
            <div>User</div>
            <div>Service user</div>
            <div>Last seen</div>
          </div>
          {filteredStaff.map((staff, index) => (
            <FriendsAndFamilyItem
              key={index}
              staff={staff}
              navigate={navigate}
            />
          ))}
        </div>
      )}
      {onMobile &&
        filteredStaff.map((staff, index) => (
          <StaffItemMobile key={index} staff={staff} navigate={navigate} />
        ))}
    </>
  );
};

export default DashboardFriendsAndFamily;
