import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { useViewport } from "../../../Components/ViewportProvider";
import { mobileBreakpoint } from "../../../Services/config";
// Components
import Page from "../../../Components/Page";
import CTAButton from "../../../Components/CTAButton";
import InstallationBanner from "../../../Components/InstallationBanner";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationArt from "../../../Components/InstallationArt";

const Install = () => {
  const navigate = useNavigate();
  const { width } = useViewport();
  const onDesktop = width > mobileBreakpoint;

  // Use state to show the second "screen" which is simply different information
  const [secondScreen, setSecondScreen] = useState(false);

  return (
    <Page className={styles.page}>
      {onDesktop && <InstallationBanner />}

      <InstallationCard>
        <div className={styles.card_left}>
          <img
            src="/logo/lilli-128-teal.png"
            alt="Lilli"
            className={styles.logo}
          />
        </div>

        <div
          className={`${styles.card_right} ${
            secondScreen && styles.card_right_second
          }`}
        >
          <InstallationArt />
          <div className={styles.content}>
            <div
              className={`${styles.instructions} ${
                secondScreen && styles.instructions_second
              }`}
            >
              <div className={styles.instructions_header}>
                <h2>{secondScreen ? "Set up your Lilli Kit" : "Welcome,"}</h2>
                {!secondScreen && <h2>let's get your installation started</h2>}
              </div>
              <p className={styles.instructions_secondary_text}>
                {secondScreen
                  ? "We've broken it down into simple steps to take you through it, just follow all the steps and you'll be up and running very soon"
                  : "Make sure you have your Lilli Kit box open and ready in front of you."}
              </p>
            </div>
            <CTAButton
              style={{
                width: "100%",
                padding: "12px",
                marginBottom: "40px",
              }}
              cypresstestid={
                secondScreen
                  ? "Installation-Get-Started-2"
                  : "Installation-Welcome-1"
              }
              className={`${!secondScreen && styles.button}`}
              onClick={
                secondScreen
                  ? () => navigate("/install/service-user")
                  : () => setSecondScreen(true)
              }
            >
              Continue
            </CTAButton>
          </div>
        </div>
      </InstallationCard>
    </Page>
  );
};

export default Install;
