import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import Select from "react-select"; // https://react-select.com/home#getting-started
import CTAButton from "../../Components/CTAButton";
import FormError from "../../Components/FormError";
// utils
import { hasWhiteSpace, sortHigh } from "@intelligentlilli/lilli-utils";
import { isValidName, isValidEmail } from "../../Services/Utils";
// icons
import { CloseIcon } from "../../Styles/Icons";

const AddUserForm = ({
  onSubmit,
  createStaffEmailError,
  serviceUsersToSelect,
  isLoading,
  typeOfUser,
}) => {
  // This is the custom style for the select component
  const customSelectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: `${theme.neutral1}`,
      padding: "2px 0",
      borderRadius: 2,
      border: `1px solid ${theme.neutral2}`,
      color: `${theme.neutral7}`,
      fontSize: "16px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "16px",
        backgroundColor: isFocused ? `${theme.neutral2}` : `${theme.white}`,
        color: `${theme.neutral7}`,
        ":active": {
          backgroundColor: `${theme.oldprimary1}`,
        },
      };
    },
  };

  return (
    <Form
      onSubmit={(values) => {
        onSubmit(
          values.forename,
          values.surname,
          values.email.trim(),
          values.serviceUserAssignment
        );
      }}
      validate={(values) => {
        const errors = {};
        if (!values.forename) {
          errors.forename = "Required";
        }
        if (values.forename && !isValidName(values.forename)) {
          errors.forename = "Invalid forename";
        }
        if (!values.surname) {
          errors.surname = "Required";
        }
        if (values.surname && !isValidName(values.surname)) {
          errors.surname = "Invalid surname";
        }
        if (!values.email) {
          errors.email = "Required";
        }
        if (values.email && !isValidEmail(values.email.trim())) {
          errors.email = "Invalid email";
        }
        if (values.email && hasWhiteSpace(values?.email)) {
          errors.email = "Please remove whitespace";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, pristine, valid, form }) => (
        <form
          className={styles.form}
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <div className={styles.form_fields}>
            <Field name="forename">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>Forename(s)</div>
                  <input className={styles.input} {...input} type="text" />
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
            <Field name="surname">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>Surname</div>
                  <input className={styles.input} {...input} type="text" />
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
            <Field name="email">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>Email</div>
                  <input className={styles.input} {...input} type="text" />
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
          </div>

          <div className={styles.form_separator}></div>
          <div className={styles.assign_users_to_staff_title}>
            Assign users to {typeOfUser}
          </div>

          <div className={styles.select_service_users}>
            <Field name="serviceUserAssignment">
              {({ input, meta }) => {
                // Only show staff who have not been assigned in the list
                const serviceUsersNotYetSelected = sortHigh(
                  serviceUsersToSelect,
                  "label"
                )?.filter((serviceUser) => !input.value.includes(serviceUser));
                return (
                  <div className={styles.field}>
                    <div className={styles.label}>User assignment</div>
                    <div className={`${styles.select_box}`}>
                      <Select
                        className={`${styles.choose_a_service_user} fs-mask`}
                        placeholder="Select a service user"
                        options={serviceUsersNotYetSelected}
                        name="serviceUsers"
                        maxMenuHeight={205}
                        value={[]}
                        styles={customSelectStyle}
                        onChange={(change) => {
                          // Take the new staff member and add them to the array of staff members assigned
                          let newServiceUsers = [];
                          if (Array.isArray(input.value)) {
                            newServiceUsers = [...input.value];
                          }
                          newServiceUsers.push(change);
                          form.change("serviceUserAssignment", newServiceUsers);
                        }}
                        components={{
                          IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
                        }}
                        isClearable
                        isSearchable
                        menuPlacement={"top"}
                      />
                    </div>
                    {/* render the list of staff members assigned */}
                    <div className={styles.service_users}>
                      {input?.value?.length > 0 &&
                        input?.value?.map((user, index) => {
                          return (
                            <div className={styles.service_user} key={index}>
                              <CloseIcon
                                onClick={() => {
                                  const newServiceUsers = [...input.value];
                                  newServiceUsers.splice(index, 1);
                                  form.change(
                                    "serviceUserAssignment",
                                    newServiceUsers
                                  );
                                }}
                                className={styles.deleteStaff}
                              />
                              <span className="fs-mask">{user?.value}</span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
          {createStaffEmailError && <FormError error={createStaffEmailError} />}
          <CTAButton
            type="submit"
            disabled={isLoading || submitting || pristine || !valid}
            className={styles.submit}
            cypresstestid="Create-New-Staff-Btn-Submit"
          >
            {isLoading
              ? `Creating new ${typeOfUser.toLowerCase()}...`
              : "Create"}
          </CTAButton>
        </form>
      )}
    />
  );
};

export default AddUserForm;
