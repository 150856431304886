import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { mobileBreakpoint } from "../../../Services/config";
import theme from "../../../Styles/theme.scss";
import { useViewport } from "../../../Components/ViewportProvider";
// State
import { useDispatch, useSelector } from "react-redux";
import { setSensors } from "../../../State/slices/sensors-slice";
// Components
import Page from "../../../Components/Page";
import InstallationBanner from "../../../Components/InstallationBanner";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import Select from "react-select"; // https://react-select.com/home#getting-started
import InstallationArt from "../../../Components/InstallationArt";

const ConfirmServiceUserDetails = ({ name, address }) => {
  return (
    <div className={`${styles.confirm_card} fs-mask`}>
      <div className={styles.confirm_name}>{name}</div>
      {address.length > 0 &&
        address?.map((line, index) => (
          <div key={index} className={styles.confirm_address}>
            {line}
          </div>
        ))}
    </div>
  );
};

const InstallSelectSU = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;

  // Local state
  const [serviceUserSelected, setServiceUserSelected] = useState(
    location.serviceUser
  );
  const [isCheckingSuDetails, setIsCheckingSuDetails] = useState(false);
  const hasNotStartedInstallation =
    serviceUserSelected?.installationStatus !== "started";

  // redux state
  const dispatch = useDispatch();
  const serviceUsers = useSelector((state) => state.serviceUsers);

  const modifiedSUs = serviceUsers
    .filter(
      (serviceUser) =>
        serviceUser.serviceUserProperties?.installationStatus !== "complete"
    )
    .map((serviceUser) => ({
      value: serviceUser.id,
      label: `${serviceUser.userstring} ${
        serviceUser.serviceUserProperties?.installationStatus === "started"
          ? "(in progress)"
          : ""
      }`,
      // Depending on whether the user is a manager or just a OT the postcode and house number are in slightly different places
      postcode:
        serviceUser.postcode || serviceUser.serviceUserProperties?.postcode,
      houseNumber:
        serviceUser.houseNumberOrName ||
        serviceUser.serviceUserProperties?.houseNumberOrName,
      devicestring: serviceUser.devicestring,
      installationStatus: serviceUser.serviceUserProperties?.installationStatus,
    }));

  const goBack = () => {
    setIsCheckingSuDetails(false);
  };

  const onSelectNotStartedSU = () => {
    // Reset persisted sensor status in redux so that pervious installs do not interfere with new ones
    dispatch(setSensors(null));
    // Show 'confirm address'
    setIsCheckingSuDetails(true);
  };

  const onSelectInProgressSU = () => {
    // Reset persisted sensor status in redux so that pervious installs do not interfere with new ones
    dispatch(setSensors(null));

    // If the user has a device string, then take them to the hub pairing stage
    // otherwise, take the user to the QR scanning stage
    if (serviceUserSelected.devicestring) {
      navigate(
        `/install/hub/${serviceUserSelected.value}/${serviceUserSelected.devicestring}`
      );
    } else {
      navigate(`/install/link/${serviceUserSelected.value}`);
    }
  };

  const suAddress = serviceUserSelected
    ? [serviceUserSelected?.houseNumber, serviceUserSelected?.postcode]
    : [];

  return (
    <Page className={styles.page}>
      {isDesktop && <InstallationBanner />}
      <InstallationCard>
        {!isCheckingSuDetails && (
          <div className={styles.card_left}>
            <img
              src="/logo/lilli-128-teal.png"
              alt="Lilli"
              className={styles.logo}
            />
          </div>
        )}
        <div
          className={`
                  ${styles.card_right} 
                  ${
                    isCheckingSuDetails
                      ? styles.checking_su_details
                      : styles.not_checking_su_details
                  }
                `}
        >
          <InstallationArt />
          <div className={styles.content}>
            <div className={`${styles.select_SU}`}>
              {isCheckingSuDetails ? (
                <>
                  <div className={styles.is_checking_header}>
                    Are these details correct?
                  </div>
                  <ConfirmServiceUserDetails
                    name={serviceUserSelected?.label?.trim()}
                    address={suAddress}
                  />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <h2 className={styles.select_SU_header}>
                    Let's get you started
                  </h2>
                  <label className="fs-mask">
                    Select your Lilli user
                    <Select
                      placeholder="Select the name of your Lilli user"
                      options={modifiedSUs}
                      name="serviceUsers"
                      value={serviceUserSelected}
                      onChange={setServiceUserSelected}
                      isSearchable={false}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: theme.neutral1,
                          borderColor: theme.neutral2,
                          marginTop: 12,
                          fontSize: 14,
                          borderRadius: 0,
                        }),
                      }}
                    />
                  </label>
                </div>
              )}
            </div>
            <div
              className={`${
                isCheckingSuDetails ? styles.two_buttons : undefined
              }${styles.buttons}`}
            >
              <InstallationNextButton
                isDesktop={isDesktop}
                onClick={
                  hasNotStartedInstallation && !isCheckingSuDetails
                    ? onSelectNotStartedSU
                    : onSelectInProgressSU
                }
                disabled={!serviceUserSelected}
                label={isCheckingSuDetails ? "Yes" : "Next"}
                cypresstestid={
                  isCheckingSuDetails
                    ? "Installation-Confirm-SU-Details-4"
                    : "Installation-Select-SU-3"
                }
              />
              {isCheckingSuDetails && (
                <InstallationGoBackButton
                  isDesktop={isDesktop}
                  marginRight={isDesktop ? "24px" : "0px"}
                  marginTop={!isDesktop && "6px"}
                  onClick={goBack}
                  label={"No, go back"}
                />
              )}
            </div>
          </div>
        </div>
      </InstallationCard>
    </Page>
  );
};

export default InstallSelectSU;
