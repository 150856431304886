import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// API
import postCreateUser from "../../Services/Cache/Users/postCreateUser";
import postUserHubAssociation from "../../Services/Cache/Users/postUserHubAssociation";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setRefreshStaff,
  setRefreshOrganisationHubs,
} from "../../State/slices/session-slice";
// Constants
import { getNewUserRoleDescription } from "../Constants";

export const useAddNewStaff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const serviceUsersToSelect = serviceUsers?.map((su) => {
    return {
      id: su?.id,
      label: su?.userstring,
      value: su?.userstring,
    };
  });

  const [newUserRoles, setNewUserRoles] = useState("");
  const [createStaffEmailError, setCreateStaffError] = useState("");
  const [staffCreated, setStaffCreated] = useState(false);
  const [serviceUsersAssigned, setServiceUsersAssigned] = useState(false);
  const [isProcessingForm, setIsProcessingForm] = useState(false);
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [addingUser, setAddingUser] = useState(false);

  const newUserDescription = getNewUserRoleDescription[newUserRoles?.key];

  // Form helpers
  const resetFormState = () => {
    setIsProcessingForm(false);
    setCreateStaffError("");
    setShowSplashScreen(false);
    setServiceUsersAssigned(false);
    setStaffCreated(false);
    setFormSuccess(false);
  };

  // Assign service users to newly created staff (only managers and admin)
  // If new staff member was created successfully and they have been assigned service users
  // then make the POST request
  useEffect(() => {
    // The 'assignUsersToStaff' function must be inside the useEffect Hook, otherwise it will trigger a re-render.
    // Another option is to move it inside the useEffect callback.
    async function assignUsersToStaff(staffId, arrayOfServiceUsers) {
      // Build an array of promises
      const promises = [];
      for (let i = 0; i < arrayOfServiceUsers.length; i++) {
        promises.push(
          postUserHubAssociation(server, staffId, arrayOfServiceUsers?.[i]?.id)
        );
      }
      // Await all promises here
      await Promise.all(promises)
        .then((res) => {
          if (res.status === 401) {
            navigate("/login");
          }
          // update local state
          setShowSplashScreen(true);
          setFormSuccess(true);
          setServiceUsersAssigned(arrayOfServiceUsers);
          setIsProcessingForm(false);
          // refresh redux
          dispatch(setRefreshStaff(true));
          dispatch(setRefreshOrganisationHubs(true));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          setShowSplashScreen(true);
          setServiceUsersAssigned(false);
          setFormSuccess(false);
          setIsProcessingForm(false);
        });
    }

    if (staffCreated?.id && serviceUsersAssigned) {
      assignUsersToStaff(staffCreated.id, serviceUsersAssigned);
    }
  }, [staffCreated?.id, dispatch, navigate, server, serviceUsersAssigned]);

  async function createStaff(forename, surname, email, arrayOfServiceUsers) {
    const roles = newUserRoles?.value; // taken from local state, changes with the button
    postCreateUser(server, forename, surname, email, roles)
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
          console.log("postCreateUser err:", res?.response?.body);
          setCreateStaffError(res.response.body.error);
          dispatch(setLoading(false));
          setIsProcessingForm(false);
        } else {
          // on success, store response data locally
          setStaffCreated({
            name: `${forename} ${surname}`,
            ...res?.body,
          });
          // refresh redux
          dispatch(setRefreshStaff(true));
          dispatch(setRefreshOrganisationHubs(true));
          dispatch(setLoading(false));
          // If staff was assigned service users then update local state
          // Otherwise, skip assign SU stage and show success splash right away
          if (arrayOfServiceUsers) {
            setServiceUsersAssigned(arrayOfServiceUsers);
          } else {
            setShowSplashScreen(true);
            setServiceUsersAssigned(false);
            setFormSuccess(true);
            setIsProcessingForm(false);
          }
          return res?.body;
        }
      })
      .catch((err) => {
        console.log("err", err);
        // On error, show the modal, set the error, and store the name of the staff
        dispatch(setLoading(false));
        setShowSplashScreen(true);
        setFormSuccess(false);
        setStaffCreated(false);
        setIsProcessingForm(false);
        return err;
      });
  }

  // Handle the manager adding a new staff member to their organisation
  const onAddStaffMember = async (
    forename,
    surname,
    email,
    arrayOfServiceUsers
  ) => {
    setCreateStaffError("");
    dispatch(setLoading(true));
    setIsProcessingForm(true);
    const staffResponse = await createStaff(
      forename,
      surname,
      email,
      arrayOfServiceUsers
    );
    // If staffReponse is successful, it will update local state, triggering the useEffect hook.
    // And if service users were assigned to the new staff, it triggers the "assignUsersToStaff" async function
    console.log("staffResponse:", staffResponse);
    return staffResponse;
  };

  return {
    newUserDescription,
    newUserRoles,
    onAddStaffMember,
    resetFormState,
    setNewUserRoles,
    serviceUsersAssigned,
    showSplashScreen,
    isProcessingForm,
    formSuccess,
    setFormSuccess,
    staffCreated,
    createStaffEmailError,
    setCreateStaffError,
    addingUser,
    setAddingUser,
    serviceUsersToSelect,
  };
};
