import { postUserNotifications as postUserNotificationsAPI } from "@intelligentlilli/api-layer";

const postUserNotifications = async (server, id, hubId, date, readAt) => {
  try {
    const response = await postUserNotificationsAPI(
      server,
      id,
      hubId,
      date,
      readAt,
      "web"
    );
    return response;
  } catch (err) {
    return err;
  }
};

export default postUserNotifications;
