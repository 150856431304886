import { useEffect, useState } from "react";
// API
import getHubNotes from "../Cache/Hubs/getHubNotes";
import postHubNotes from "../Cache/Hubs/postHubNotes";
import deleteHubNote from "../Cache/Hubs/deleteHubNote";
// State
import { useDispatch } from "react-redux";
import { setLoading } from "../../State/slices/session-slice";
import { parseISO, isSameDay } from "date-fns";

export const useHubDailyNotes = (navigate, server, id) => {
  // Local state
  // Bool to handle refreshing hub notes (via useEffect hook) when required
  const [hubNotes, setHubNotes] = useState();
  const [refreshHubNotes, setRefreshHubNotes] = useState(true);
  const [error, setError] = useState();
  // Redux state
  const dispatch = useDispatch();

  useEffect(() => {
    if (refreshHubNotes) {
      setRefreshHubNotes(false);
      getHubNotes(server, id)
        .then((res) => {
          if (res.ok) {
            setHubNotes(res.body);
          } else {
            setError("Error getting service user notes");
            if (res.status === 401) {
              navigate("/login");
            }
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          setError("Error getting service user notes");
          console.log("This is the error", err);
          dispatch(setLoading(false));
        });
    }
  }, [refreshHubNotes, server, id, navigate, dispatch]);

  const getHubNoteForTheDay = (
    date // Look through the service user notes and find the one for the day selected
  ) =>
    hubNotes?.find((hubNote) => isSameDay(parseISO(hubNote.ts), date))?.value;

  const deleteHubNoteForTheDay = (date) => {
    deleteHubNote(
      server,
      id,
      hubNotes?.find((hubNote) => isSameDay(parseISO(hubNote.ts), date))?.id
    )
      .then((res) => {
        if (!res.ok) {
          setError("Error deleting note");
          if (res.status === 401) {
            navigate("/login");
          }
        }
      })
      .catch((_) => {
        setError("Error deleting note");
      });
  };

  const deleteNote = (noteId) => {
    dispatch(setLoading(true));
    deleteHubNote(server, id, noteId)
      .then((res) => {
        if (!res.ok) {
          setError("Error deleting note");
          if (res.status === 401) {
            navigate("/login");
          }
        }
        setRefreshHubNotes(true);
      })
      .catch((_) => {
        setError("Error deleting note");
        dispatch(setLoading(false));
      });
  };

  const postHubNoteForTheDay = (note, date, callback) => {
    postHubNotes(server, id, note, Date.parse(date))
      .then((res) => {
        if (!res.ok) {
          setError("Error saving note");
          if (res.status === 401) {
            navigate("/login");
          }
        } else {
          setRefreshHubNotes(true);
          callback && callback();
        }
      })
      .catch((_) => {
        setError("Error saving note");
      })
      .finally((_) => {
        dispatch(setLoading(false));
      });
  };

  const onSaveNote = (note, date, callback) => {
    const newNote = note?.length > 0; // I.e. if the new note is not an empty string
    const oldNoteExists = getHubNoteForTheDay(date); // If a note already exists for the day

    dispatch(setLoading(true));

    if (oldNoteExists) {
      deleteHubNoteForTheDay(date);
    }
    if (newNote) {
      postHubNoteForTheDay(note, date, callback);
    } else {
      setRefreshHubNotes(true);
    }
  };

  return {
    onSaveNote,
    getHubNoteForTheDay,
    setRefreshHubNotes,
    hubNotes,
    error,
    deleteNote,
  };
};
