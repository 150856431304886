import theme from "../../Styles/theme.scss";

// This function adds the additional data the frontend requires about the sensors paired with a hub
export const augmentSensorData = (devices, id, devicestring) => {
  // Note: All name regex checks are taken from the backend implementation
  const additionalData = [
    {
      nameRegex: new RegExp("SquidZigBee$", "i"),
      displayName: "Lilli hub",
      img: "/images/installation/hub/hub.png",
    },
    {
      defaultName: "Smart Plug Mini UK",
      nameRegex: new RegExp("Kitchen/[\\w]+/Power$", "i"), // Kitchen/<anything>/Power
      displayName: "Smart plug",
      img: "/images/installation/sensors/plug/plug_thumb.png",
      alt: "plug icon",
      colour: theme.white,
      route: `/install/sensors/plug/${id}/${devicestring}`,
      locationRequired: true,
      locationOptions: [
        { value: "Kettle", label: "Kettle" },
        { value: "Microwave", label: "Microwave" },
        { value: "Toaster", label: "Toaster" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      defaultName: "Motion Sensor Mini",
      nameRegex: new RegExp("Bathroom/Wall/Motion$", "i"), // Bathroom/Wall/Motion
      displayName: "Bathroom sensor",
      description:
        "This motion sensor is to be placed at any height in the bathroom. Be sure the sensor is as close to the bathing area as possible and not obstructed so it has as much view of the room as possible.",
      img: "/images/installation/sensors/motion/motion_thumb.png",
      alt: "motion icon",
      colour: theme.stickerBlue,
      route: `/install/sensors/motion/${id}/${devicestring}/Bathroom`,
      id: "Bathroom",
      locationRequired: true,
      locationOptions: [
        { value: "Bath/Shower and Toilet", label: "Bath/Shower and Toilet" },
        { value: "Bath/Shower room only", label: "Bath/Shower room only" },
        { value: "Toilet only", label: "Toilet only" },
      ],
    },
    {
      defaultName: "Motion Sensor Mini",
      nameRegex: new RegExp("Bedroom/Wall/Motion$", "i"), //Bedroom/Wall/Motion,
      displayName: "Bedroom sensor",
      description:
        "This motion sensor is ideally placed outside of the bedroom door where it can capture entering/exiting of the bedroom but avoiding any other in-home movement, as much as possible.",
      img: "/images/installation/sensors/motion/motion_thumb.png",
      alt: "motion icon",
      colour: theme.stickerRed,
      route: `/install/sensors/motion/${id}/${devicestring}/Bedroom`,
      id: "Bedroom",
      locationRequired: false,
    },
    {
      defaultName: "Motion Sensor Mini",
      nameRegex: new RegExp("Hall/Wall/Motion$", "i"), //Hall/Wall/Motion,
      displayName: "Movement sensor",
      description:
        "This motion sensor should be used to monitor Movement around the home. The sensor works best facing an area in the home that has high traffic throughout the day and should be placed above waist height.",
      img: "/images/installation/sensors/motion/motion_thumb.png",
      alt: "motion icon",
      colour: theme.stickerRed,
      route: `/install/sensors/motion/${id}/${devicestring}/Hall`,
      id: "Hall",
      locationRequired: true,
      locationOptions: [
        { value: "Hallway", label: "Hallway" },
        { value: "Stairs", label: "Stairs" },
        { value: "Living Room", label: "Living Room" },
        { value: "Kitchen", label: "Kitchen" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      defaultName: "Window Sensor",
      nameRegex: new RegExp("Kitchen/Fridge/Door$", "i"), // Kitchen/Fridge/Door
      displayName: "Fridge sensor",
      img: "/images/installation/sensors/door/door_thumb.png",
      alt: "door icon",
      colour: theme.stickerGreen,
      route: `/install/sensors/door/${id}/${devicestring}/Kitchen`,
      locationRequired: true,
      locationOptions: [
        { value: "Fridge", label: "Fridge" },
        { value: "Cupboard", label: "Cupboard" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      defaultName: "Window Sensor",
      nameRegex: new RegExp(
        "[\\w]+/Front/Door$|[\\w]+/Back/Door$|[\\w]+/Main/Door$",
        "i"
      ), //<anything>/[Front|Back|Main]/Door
      displayName: "Main door sensor",
      img: "/images/installation/sensors/door/door_thumb.png",
      alt: "door icon",
      colour: theme.stickerYellow,
      route: `/install/sensors/door/${id}/${devicestring}/Hall`,
      locationRequired: true,
      locationOptions: [
        { value: "Front door", label: "Front door" },
        { value: "Back door", label: "Back door" },
      ],
    },
    {
      defaultName: "Vibration Sensor",
      nameRegex: new RegExp("[\\w]+/[\\w]+/Vibration$", "i"), //<anything>/<anything>/Vibration
      displayName: "Vibration sensor",
      img: "/images/installation/sensors/vibration/vibration_thumb.png",
      alt: "vibration sensor icon",
      colour: theme.white,
      route: `/install/sensors/vibration/${id}/${devicestring}`,
      locationRequired: false,
    },
    {
      defaultName: "Air Quality Sensor",
      nameRegex: new RegExp("[\\w]+/[\\w]+/VOC", "i"), //<anything>/<anything>/VOC  (which stands for volatile organic compounds)
      displayName: "Air quality sensor",
      img: "/images/installation/sensors/airquality/airquality_thumb.png",
      alt: "air quality sensor icon",
      colour: theme.white,
      route: `/install/sensors/airquality/${id}/${devicestring}`,
      locationRequired: false,
    },
    {
      defaultName: "Humidity Sensor",
      nameRegex: new RegExp("[\\w]+/[\\w]+/Humidity", "i"), //<anything>/<anything>/Humidity
      displayName: "Humidity sensor",
      img: "/images/installation/sensors/humidity/humidity_thumb.png",
      alt: "humidity sensor icon",
      colour: theme.white,
      route: `/install/sensors/humidity/${id}/${devicestring}`,
      locationRequired: false,
    },
  ];
  // Combine the devices data from the API with the additional data above
  const augmentedSensorData = devices?.map((sensor) => {
    const additionalSensorData = additionalData.find((data) =>
      data.nameRegex.test(sensor.name)
    );
    return { ...sensor, ...additionalSensorData };
  });

  return augmentedSensorData;
};
