import React from "react";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
// Hooks
import { useOpenHubPairing, useGetSensorStatus } from "../../../Services/Hooks";
// Components
import InstallSensor from "../../../Components/InstallSensor";
import InstallationInstructions from "../../../Components/InstallationInstructions";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallSensorLocation from "../../../Components/InstallSensorLocation";
import InstallationButtons from "../../../Components/InstallationButtons";
import InstallTroubleshooting from "../../../Components/InstallTroubleshooting";
import InstallationHint from "../../../Components/InstallationHint";

const InstallMotionStand = () => {
  const { devicestring, id, name } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();

  // sizing
  const { width } = useViewport();
  const isDesktop = width > 600;

  // Trigger the enter hub pairing mode
  const secondsUntilNextRepair = 120000; // 2 minutes
  useOpenHubPairing({
    hubId: id,
    installationComplete: false,
    frequencyOfRepairing: secondsUntilNextRepair,
  });

  // Trigger the sensor status fetching
  const { loading, fetchSensorStatus } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: false,
  });

  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const selectedSuObject = serviceUsers.filter((i) => i.id === parseInt(id));
  const selectedSUName = selectedSuObject?.[0]?.userstring || "";

  // Get the correct sensor
  const sensors = useSelector((state) => state.sensors);
  const sensorIndex = sensors?.devices?.findIndex(
    (device) => device.id === name
  );
  const sensor =
    (sensorIndex || sensorIndex === 0) && sensors?.devices?.[sensorIndex];

  const sensorLocations = useSelector((state) => state.sensorLocations);
  const sensorLocation = sensorLocations?.[sensor?.name];

  // hints
  const motionStandHint = [
    "It is important to keep this sensor away from sources of heat like heating vents, space heaters, fireplaces, or windows facing direct sunlight.",
  ];

  // installation instructions
  const instructions = [
    {
      text: "Open the casing of the device by pushing the fastening on the top of the device to remove the front cover from the back cover.",
    },
    {
      text: "Insert the enclosed batteries into the sensor and close the casing.",
    },
    {
      text: "Insert the plastic stand in the opening on the back of the sensor.",
    },
    {
      text: "Place the motion sensor facing into the room in an area where it is unlikely to be disturbed.",
    },
    // {
    //   text: "Check the motion sensor is working by waving a hand in front of it. It should flash green.",
    // },
  ];

  // image to be used regardless which room it is placed
  const image = "/images/installation/sensors/motion/motion-stand.png";

  return (
    <InstallSensor
      image={image}
      altText="Lilli motion sensor stand"
      sensor={sensor}
      imageStyle={styles.image}
      userstring={selectedSUName}
      devicestring={devicestring}
      hints={motionStandHint}
      testConnection={fetchSensorStatus}
      loading={loading}
      locationSelected={!!sensorLocation && sensor.locationRequired}
      cypresstestid={"Installation-Motion-Stand-Sensor"}
    >
      <div className={styles.motion_stand_top}>
        <h2>Motion sensor stand</h2>
        <InstallationHint hints={motionStandHint} />
        <p>{sensor.description}</p>
        <p>Follow the instructions to install the motion sensor stand:</p>
        <InstallationInstructions instructions={instructions} />
        <InstallSensorLocation sensor={sensor} />
      </div>
      <InstallTroubleshooting imageLocation={image} />
      <InstallationButtons
        loading={loading}
        goBack={() => navigate(-2)}
        isDesktop={isDesktop}
        sensor={sensor}
        sensorLocation={sensorLocation}
        fetchSensorStatus={fetchSensorStatus}
        id={id}
      />
    </InstallSensor>
  );
};

export default InstallMotionStand;
