import { postUpdateProperty as postUpdatePropertyAPI } from "@intelligentlilli/api-layer";

const postUpdateProperty = async (server, key, value) => {
  try {
    const response = await postUpdatePropertyAPI(server, key, value, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default postUpdateProperty;
