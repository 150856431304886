import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
// State
import { useSelector, useDispatch } from "react-redux";
import { updateSensor } from "../../../State/slices/sensors-slice";
import { setSensorLocations } from "../../../State/slices/sensorLocations-slice";

// Hooks
import { useGetSensorStatus } from "../../../Services/Hooks";
// Components
import InstallSticker from "../../../Components/InstallSticker";
import Select from "react-select"; // https://react-select.com/home#getting-started
import InstallSensor from "../../../Components/InstallSensor";
import InstallationHint from "../../../Components/InstallationHint";

const InstallVibration = () => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();

  // Trigger the sensor status fetching
  const { loading, fetchSensorStatus } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: false,
  });

  // redux state
  const dispatch = useDispatch();
  const sensors = useSelector((state) => state.sensors);
  const sensorLocations = useSelector((state) => state.sensorLocations);
  // Local state
  const [appliance, setAppliance] = useState();

  const sensorIndex = sensors?.devices?.findIndex(
    (device) => device?.displayName === "Vibration sensor"
  );
  // Get the correct sensor
  const sensor =
    (sensorIndex || sensorIndex === 0) && sensors?.devices?.[sensorIndex];

  // If the installer is returning to this page and has already chosen a location get it here
  const sensorLocation = sensorLocations?.[sensor?.name];

  // Keep track of which pages the user has visited
  useEffect(() => {
    dispatch(
      updateSensor({
        index: sensorIndex,
        key: "checkedConnection",
        value: true,
      })
    );
  }, [dispatch, sensorIndex]);

  const vibrationInstallationHint = [
    `The location of the sensor is derived from whether you are monitoring
  nighttime activity (bed) or continence management (near toilet flush
  handle or on cistern)`,
  ];

  // Set the selected location
  useEffect(() => {
    if (sensorLocation) {
      setAppliance({ value: sensorLocation, label: sensorLocation });
    }
  }, [sensorLocation]);

  const onComplete = () => {
    const updatedLocations = { ...sensorLocations };
    updatedLocations[sensor.name] = appliance.label;
    dispatch(setSensorLocations(updatedLocations));
    navigate(-1);
  };

  return (
    <InstallSensor
      image="/images/installation/sensors/vibration/vibration.png"
      altText="Lilli door sensor"
      handleNext={onComplete}
      disabled={!appliance}
      sensor={sensor}
      nextText="Next"
      loading={loading}
      testConnection={fetchSensorStatus}
      goBack={() => navigate(-1)}
      cypresstestid={"Installation-Vibration-Sensor"}
    >
      <h2>Install vibration sensor</h2>
      <InstallSticker text="Vibration" />
      <img
        src="/images/installation/sensors/door/door-open.png"
        alt="install vibration"
        style={{ width: 250, alignSelf: "center" }}
      />
      <ol className={styles.steps}>
        <li>
          Open the casing of the device by pushing the fastening on the top of
          the device to remove the front cover from the back cover.
        </li>
        <li>
          Insert the enclosed batteries into the sensor and close the casing.
        </li>
        <li>
          Remove the backing paper of sensor to expose the adhesive strip.
        </li>
        <li>Mount the vibration sensor</li>
      </ol>
      <label>
        Select the location of the sensor:
        <Select
          placeholder="Choose location..."
          options={[
            { value: "Bed", label: "Bed" },
            { value: "Toilet", label: "Toilet" },
          ]}
          value={appliance}
          onChange={setAppliance}
          components={{
            IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
          }}
        />
      </label>
      <InstallationHint hints={vibrationInstallationHint} />
    </InstallSensor>
  );
};

export default InstallVibration;
