import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// Utils
import { getRiskColour, RiskLevelText } from "@intelligentlilli/lilli-utils";
import {
  RiskLevelIcon,
  RiskLevelIconNew,
} from "../../Services/Utils/Constants/risks";

const RiskStatus = (props) => {
  const RiskIcon = RiskLevelIcon[props.status];
  const RiskIconNew = RiskLevelIconNew[props.status];

  // There are two different styles for the RiskStatus, one for pdf reports and one for the app
  if (props.pdf) {
    return (
      <div className={styles.riskStatus}>
        {props.status && (
          <>
            <RiskIcon
              style={{
                color: getRiskColour(props.status, {
                  red: theme.risk3,
                  orange: theme.unexpected,
                  green: theme.green,
                }),
                width: 12,
                height: 12,
              }}
            />
            <div
              style={{
                fontWeight: "bold",
                color: getRiskColour(props.status, {
                  red: theme.risk3,
                  orange: theme.unexpected,
                  green: theme.green,
                }),
                marginLeft: 3,
              }}
            >
              {RiskLevelText[props.status]}
            </div>
          </>
        )}
      </div>
    );
  }
  return (
    <div
      className={`${
        props.square ? styles.riskStatus_square : styles.riskStatus
      }`}
    >
      {props.status && (
        <>
          <div
            style={
              props.square && {
                width: 32,
                height: 32,
                backgroundColor: getRiskColour(props.status, {
                  red: theme.risk3,
                  orange: theme.unexpected,
                  green: theme.green,
                }),
                marginRight: 16,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 2,
              }
            }
          >
            {props.square && <RiskIconNew width={16} />}
            {!props.square && (
              <RiskIcon
                style={{
                  color: getRiskColour(props.status, {
                    red: theme.risk3,
                    orange: theme.unexpected,
                    green: theme.green,
                  }),
                }}
              />
            )}
          </div>
          <div
            className={`${props.square && styles.riskStatus_text}`}
            style={props.bold && { fontWeight: "bold" }}
          >
            {RiskLevelText[props.status]}
          </div>
          <div>
            {props.count && `${props.count} day${props.count === 1 ? "" : "s"}`}
          </div>
        </>
      )}
    </div>
  );
};

export default RiskStatus;
