import theme from "../../../Styles/theme.scss";
import {
  getMinAndMax,
  getHoursAndMinutesAsString,
  getBucketNumber,
  getColorGradient,
} from "../";

export const handleNighttime = (data, filterName) => {
  // prepare min and max values needed for the heatmap
  const isNighttimeFilter =
    filterName === "nightTimeActivity" ||
    filterName === "aboveExpectedNightTime";
  const arrayOfNighttimeDisturbances = isNighttimeFilter
    ? data?.map((day) => day.durationMinutes).filter((i) => i)
    : [];

  const MAX_NIGHTTIME_DISTURBANCES = isNighttimeFilter
    ? getMinAndMax(arrayOfNighttimeDisturbances, "nightTime").max
    : 0;
  const MIN_NIGHTTIME_DISTURBANCES = isNighttimeFilter
    ? getMinAndMax(arrayOfNighttimeDisturbances, "nightTime").min
    : 0;

  return data?.map((day) => {
    const nighttimeDuration = day?.durationMinutes?.nightTime ?? null;
    const nighttimeExpectedMin = day?.expectedDurationMinutes?.nighttimeMin;
    const nighttimeExpectedMax = day?.expectedDurationMinutes?.nighttimeMax;

    const isNightModelReady =
      day?.night?.insights?.[day?.night?.insights.length - 1]?.modelReady;

    const commonObj = {
      date: day?.date,
      dayRating: null, // TO DO: add day rating
    };
    const nighttimeCommonObj = {
      expectedMin: nighttimeExpectedMin,
      expectedMax: nighttimeExpectedMax,
      value: nighttimeDuration,
      firstLineText:
        nighttimeDuration === null
          ? "No data"
          : getHoursAndMinutesAsString(nighttimeDuration, true),
      secondLineText: "",
      max: MAX_NIGHTTIME_DISTURBANCES,
      min: MIN_NIGHTTIME_DISTURBANCES,
      noData: nighttimeDuration === null,
    };
    // night-time disturbances
    const nightTimeDisturbances = {
      ...commonObj,
      ...nighttimeCommonObj,
      isHighlighted: !!nighttimeDuration && nighttimeDuration >= 0,
      highlightColour: !nighttimeDuration
        ? null
        : getColorGradient(
            getBucketNumber(nighttimeDuration, MAX_NIGHTTIME_DISTURBANCES),
            theme.green6
          ),
    };
    switch (filterName) {
      case "nightTimeActivity":
        return nightTimeDisturbances;
      case "aboveExpectedNightTime":
        return {
          ...commonObj,
          ...nighttimeCommonObj,
          isHighlighted: isNightModelReady
            ? nighttimeDuration > nighttimeExpectedMax
              ? true
              : false
            : false,
        };
      case "belowExpectedNightTime":
        return {
          ...commonObj,
          ...nighttimeCommonObj,
          isHighlighted: isNightModelReady
            ? nighttimeDuration < nighttimeExpectedMin
              ? true
              : false
            : false,
        };
      default:
        return nightTimeDisturbances;
    }
  });
};
