import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// API
import postHubAssignDevice from "../../../Services/Cache/Hubs/postHubAssignDevice";
import postHubProperty from "../../../Services/Cache/Hubs/postHubProperty";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setRefreshOrganisationHubs,
  setRefreshUser,
} from "../../../State/slices/session-slice";
import {
  updateServiceUser,
  updateServiceUserProperties,
} from "../../../State/slices/serviceUsers-slice";
// Utils
import { getInitials } from "@intelligentlilli/lilli-utils";
// Components
import Page from "../../../Components/Page";
import { ManuallyEnterQRCode, ScanQRCode } from "../../../Components/InstallQR";
import InstallationArt from "../../../Components/InstallationArt";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import InstallQRLoading from "../../../Components/InstallQRLoading";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
// Icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";

const InstallLinkDevice = () => {
  const { id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  // sizes
  const { width } = useViewport();
  const isMobile = width <= 600;
  const isBiggerThan500 = width > 500;
  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const selectedSuObject = serviceUsers.filter((i) => i.id === parseInt(id));
  const indexOfSU = serviceUsers.findIndex(
    (serviceUser) => serviceUser.id.toString() === id
  );
  const selectedSUName = selectedSuObject?.[0]?.userstring || "user";
  const serviceUserInitials = getInitials(selectedSUName) || "";

  // Local state
  const [devicestringError, setDevicestringError] = useState();
  const [stage, setStage] = useState("start");
  const [deviceId, setDeviceId] = useState(false);
  const [brokenQRCode, setBrokenQRCode] = useState(false);

  // redux state
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);

  const onLinkDevice = (deviceId) => {
    // API call passing the device string and hubId
    setStage("loading");
    postHubAssignDevice(server, id, deviceId)
      .then((res) => {
        if (res.ok) {
          setStage("success");
          setDeviceId(deviceId);
          postHubProperty(
            server,
            id,
            "InstallationStatus",
            "started"
            // Refreshing the organisation hubs so that the  devicestring and installation status are up to date in redux
          );
        } else {
          setDevicestringError(res.body.error);
          if (res.status === 401) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log("caught error", err);
        setStage("fail");
        setDeviceId(false);
      });
  };

  return (
    <Page className={styles.page}>
      {stage !== "start" && <InstallationArt />}
      <InstallationCard
        className={`${
          stage === "start"
            ? styles.start_screen_flex_direction
            : styles.flex_direction
        }`}
        cypresstestid={"Installation-Link-QR-Code-Page-5"}
      >
        {stage === "start" && ( // "I.e. Just arrived onto the page, has not attempted a link"
          <div className={styles.start}>
            <div className={styles.card_left}>
              <ScanQRCode
                devicestringError={devicestringError}
                onLinkDevice={onLinkDevice}
              />
            </div>
            <div className={styles.card_right}>
              <div className={styles.instructions}>
                <h2>Let's connect your camera</h2>
                <p>
                  Please scan the QR code on the Lilli kit box. This is a white
                  sticker. It will be on either the front or bottom of the box.
                </p>
              </div>
              <ManuallyEnterQRCode
                brokenQRCode={brokenQRCode}
                setBrokenQRCode={setBrokenQRCode}
                onLinkDevice={onLinkDevice}
                devicestringError={devicestringError}
                buttonClass={styles.buttons}
                isDesktop={isBiggerThan500}
              />
            </div>
          </div>
        )}
        {stage === "loading" && (
          <div className={styles.qr_loading_container}>
            <div className={styles.connecting_user_to_hub}>
              Connecting your Lilli User
            </div>
            <InstallQRLoading initials={serviceUserInitials} />
            <InstallationGoBackButton
              label={"Exit install"}
              onClick={() => navigate("/install")}
              isDesktop={!isMobile}
              textColor={theme.error2}
              marginTop={"24px"}
            />
          </div>
        )}
        {stage === "success" && (
          <div className={styles.card_right}>
            <InstallationSplash
              icon={<ThumbsUpIcon />}
              headers={["Great!", "Your Lilli user is connected"]}
              message={"Let's get your hub connected"}
              goBackButtonLabel={"Exit install"}
              goBackOnClick={() => navigate("/install")}
              nextButtonLabel={"Continue"}
              nextButtonOnClick={() => {
                dispatch(
                  updateServiceUserProperties({
                    index: indexOfSU,
                    key: "installationStatus",
                    value: "started",
                  })
                );
                dispatch(
                  updateServiceUser({
                    index: indexOfSU,
                    key: "devicestring",
                    value: `${deviceId}`,
                  })
                );
                navigate(`/install/hub/${id}/${deviceId}`);
              }}
              isDesktop={!isMobile}
            />
          </div>
        )}
        {stage === "fail" && (
          <InstallationSplash
            icon={<ThumbsDownIcon />}
            headers={["Uh-oh!", "Something went wrong"]}
            message={"Let's try again, but if the problem continues, contact "}
            email={"support@intelligentlilli.com"}
            goBackButtonLabel={"Exit install"}
            goBackOnClick={() => {
              dispatch(setRefreshUser(true));
              dispatch(setRefreshOrganisationHubs(true));
              navigate("/install");
            }}
            nextButtonLabel={"Try again"}
            nextButtonOnClick={() => setStage("start")}
            isDesktop={!isMobile}
          />
        )}
      </InstallationCard>
    </Page>
  );
};

export default InstallLinkDevice;
