// Font awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faWalking,
  faUtensils,
  faUserCheck,
  faThermometerHalf,
  faBed,
  faDoorOpen,
  faDoorClosed,
} from "@fortawesome/free-solid-svg-icons";
// Material UI icons
import Add from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import Assignment from "@mui/icons-material/Assignment";
import Close from "@mui/icons-material/Close";
import CameraAlt from "@mui/icons-material/CameraAlt";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Delete from "@mui/icons-material/Delete";
import Dashboard from "@mui/icons-material/Dashboard";
import Error from "@mui/icons-material/Error";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import Edit from "@mui/icons-material/Edit";
import ErrorOutline from "@mui/icons-material/ErrorOutlineOutlined";
import Help from "@mui/icons-material/Help";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import ImportExport from "@mui/icons-material/ImportExport";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import List from "@mui/icons-material/List";
import MailOutline from "@mui/icons-material/MailOutline";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import Person from "@mui/icons-material/Person";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import Search from "@mui/icons-material/Search";
import Settings from "@mui/icons-material/Settings";
import Tune from "@mui/icons-material/Tune";

//
//
// Material UI
export const AddIcon = (props) => <Add {...props} />;
export const ArrowBackIcon = (props) => <ArrowBack {...props} />;
export const ArrowDownwardIcon = (props) => <ArrowDownward {...props} />;
export const ArrowUpwardIcon = (props) => <ArrowUpward {...props} />;
export const AssignmentIcon = (props) => <Assignment {...props} />;
export const AtRiskIcon = (props) => <Error {...props} />;
export const CancelOutlinedIcon = (props) => <CancelOutlined {...props} />;
export const CloseIcon = (props) => <Close {...props} />;
export const CheckCircleOutlineIcon = (props) => (
  <CheckCircleOutline {...props} />
);
export const CameraAltIcon = (props) => <CameraAlt {...props} />;
export const CalendarTodayIcon = (props) => <CalendarToday {...props} />;
export const DashboardIcon = (props) => <Dashboard {...props} />;
export const DeleteIcon = (props) => <Delete {...props} />;
export const EditIcon = (props) => <Edit {...props} />;
export const ExpectedIcon = (props) => <CheckCircle {...props} />;
export const ErrorOutlineIcon = (props) => <ErrorOutline {...props} />;
export const FAQIcon = (props) => <QuestionAnswerIcon {...props} />;
export const HomeIcon = (props) => <HomeOutlined {...props} />;
export const ImportExportIcon = (props) => <ImportExport {...props} />;
export const InfoOutlined = (props) => <InfoOutlinedIcon {...props} />;
export const ListIcon = (props) => <List {...props} />;
export const LightbulbIcon = (props) => <EmojiObjectsOutlinedIcon {...props} />;
export const MailOutlineIcon = (props) => <MailOutline {...props} />;
export const NavigateBeforeIcon = (props) => <NavigateBefore {...props} />;
export const NavigateNextIcon = (props) => <NavigateNext {...props} />;
export const PersonIcon = (props) => <Person {...props} />;
export const ReportsIcon = (props) => <AssessmentOutlinedIcon {...props} />;
export const RadioButtonUncheckedIcon = (props) => (
  <RadioButtonUnchecked {...props} />
);
export const SearchIcon = (props) => <Search {...props} />;
export const SettingsIcon = (props) => <Settings {...props} />;
export const FilterIcon = (props) => <Tune {...props} />;
export const UnexpectedIcon = (props) => <Help {...props} />;
//
//
// Font awesome
export const BriefcaseIcon = (props) => (
  <FontAwesomeIcon icon={faBriefcase} {...props} />
);
export const DoorOpenIcon = (props) => (
  <FontAwesomeIcon icon={faDoorOpen} {...props} />
);
export const DoorClosedIcon = (props) => (
  <FontAwesomeIcon icon={faDoorClosed} {...props} />
);
export const MovementIcon = (props) => (
  <FontAwesomeIcon icon={faWalking} {...props} />
);
export const SustenanceIcon = (props) => (
  <FontAwesomeIcon icon={faUtensils} {...props} />
);
export const IndependenceIcon = (props) => (
  <FontAwesomeIcon icon={faUserCheck} {...props} width={22} height={22} />
);
export const TemperatureIcon = (props) => (
  <FontAwesomeIcon icon={faThermometerHalf} {...props} />
);
export const SleepIcon = (props) => <FontAwesomeIcon icon={faBed} {...props} />;
