import React, { useState } from "react";
import { LightbulbIcon } from "../../Styles/Icons";
import { DownArrow, UpArrow } from "../../Styles/Icons/DesignSystem";
import theme from "../../Styles/theme.scss";
import styles from "./styles.module.scss";

const InstallationHint = ({ hints }) => {
  const [open, setOpen] = useState(true);
  return (
    <div className={styles.hint}>
      <div className={styles.hint_top}>
        <div className={styles.hint_top_icon}>
          <LightbulbIcon
            style={{
              width: 25,
              height: 25,
              marginRight: 6,
              marginLeft: -4,
              color: theme.primary6,
            }}
          />
          <div
            style={{
              color: theme.primary6,
            }}
          >
            Tips
          </div>
        </div>
        <div onClick={() => setOpen((p) => !p)}>
          {open && <UpArrow width={15} height={8} color={theme.primary6} />}
          {!open && <DownArrow width={15} height={8} color={theme.primary6} />}
        </div>
      </div>
      {open &&
        hints?.map((hint, index) => {
          return (
            <p key={index} className={styles.hint_description}>
              {hint}
            </p>
          );
        })}
    </div>
  );
};

export default InstallationHint;
