import { getReports as getReportsAPI } from "@intelligentlilli/api-layer";

const getReports = async (server) => {
  try {
    const response = await getReportsAPI(server, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getReports;
