import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { SERVER, CLIENT_VERSION } from "./Services/config";
import { refreshUser } from "../src/Services/Utils";
// API
import getOrganisationStaff from "./Services/Cache/Organisations/getOrganisationStaff";
import getOrganisationHubs from "./Services/Cache/Organisations/getOrganisationHubs";
// Components
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import TitledRoute from "./Components/TitledRoute";
import Spinner from "./Components/Spinners/Spinner";
// Pages
import LilliUsers from "./Pages/LilliUsers";
import Dashboard from "./Pages/Dashboard";
import LilliUser from "./Pages/LilliUser";
import Profile from "./Pages/Profile";
import QRCodePage from "./Pages/QRCodePage";
import LoginPage from "./Pages/Login";
import About from "./Pages/About";
import FAQs from "./Pages/FAQs";
import Privacy from "./Pages/Privacy";
import OldPrivacy from "./Pages/PrivacyOld";
import Error404 from "./Pages/Error404";
import UserOnboarding from "./Pages/UserOnboarding";
import Staff from "./Pages/Staff";
import StaffMember from "./Pages/StaffMember";
import Reports from "./Pages/Reports";
import ResetPassword from "./Pages/ResetPassword";
import PdfGenerator from "./Pages/PdfGenerator";
import Notifications from "./Pages/Notifications";

// State
import { useDispatch, useSelector } from "react-redux";
import {
  setServer,
  setClientVersion,
  setLoading,
  setRefreshUser,
  setRefreshStaff,
  setRefreshOrganisationHubs,
} from "./State/slices/session-slice";
import { setStaff } from "./State/slices/staff-slice";
import { setServiceUsers } from "./State/slices/serviceUsers-slice";
import { updateUser } from "./State/slices/user-slice";
// Hooks
import {
  usePersistURLParameters,
  useFetchInitialData,
  useFetchNotifications,
} from "./Services/Hooks";
// Pages
import PdfPreview from "./Pages/PdfPreview";
import InstallLinkDevice from "./Pages/Installation/InstallLinkDevice";
import InstallSelectSU from "./Pages/Installation/InstallSelectSU";
import InstallHub from "./Pages/Installation/InstallHub";
import InstallHubProvisioning from "./Pages/Installation/InstallHubProvisioning";
import InstallSensors from "./Pages/Installation/InstallSensors";
import InstallMotion from "./Pages/Installation/InstallMotion";
import InstallMotionStand from "./Pages/Installation/InstallMotionStand";
import InstallMotionBracket from "./Pages/Installation/InstallMotionBracket";
import InstallMotionStrip from "./Pages/Installation/InstallMotionStrip";
import InstallPlug from "./Pages/Installation/InstallPlug";
import InstallDoor from "./Pages/Installation/InstallDoor";
import InstallVibration from "./Pages/Installation/InstallVibration";
import InstallFinish from "./Pages/Installation/InstallFinish";
import { getCheckAuthState } from "@intelligentlilli/api-layer";
import Install from "./Pages/Installation/Install";
import NativeAppDownload from "./Pages/NativeAppDownload";

const App = () => {
  // Use react router hook to get the current url
  let location = useLocation();

  const navigate = useNavigate();
  // Redux state
  const dispatch = useDispatch();
  const fetchInitialState = useSelector(
    (state) => state.session.fetchInitialState
  );
  const refreshUserRequested = useSelector(
    (state) => state.session.refreshUser
  );
  const refreshStaffRequested = useSelector(
    (state) => state.session.refreshStaff
  );
  const refreshOrganisationHubs = useSelector(
    (state) => state.session.refreshOrganisationHubs
  );
  const user = useSelector((state) => state.user);
  const qr = useSelector((state) => state.session.qr);

  const isAdmin = user?.roles?.includes("admin");
  const isManager = user?.roles?.includes("manager");
  const isFriendsAndFamily = user?.roles?.includes("friend-or-family");

  // Local state
  const [checkedAuth, setCheckedAuth] = useState(false);
  // Dispatch the config to redux
  useEffect(() => {
    dispatch(setServer(SERVER));
    dispatch(setClientVersion(CLIENT_VERSION));
  }, [dispatch]);

  // The initial URL is used when the user has an expired session and are redirected to /login. When they do login again they get redirected
  const initialURL = document.location.href;

  // Take any parameters that have been passed in the URL and save them to redux
  usePersistURLParameters();

  // Get the initial data required to render the app
  useFetchInitialData(SERVER, setCheckedAuth, dispatch, fetchInitialState);

  // Fetch user's notifications
  useFetchNotifications();

  useEffect(() => {
    if (isFriendsAndFamily) {
      navigate("/download-app");
    }
  }, [navigate, isFriendsAndFamily]);

  // If there is a change to the service users of the organisation refresh them here
  useEffect(() => {
    if (refreshOrganisationHubs) {
      dispatch(setRefreshOrganisationHubs(false));
      // 1- Admin and managers' hubs come from the /organisationHubs endpoint
      if (isAdmin || isManager) {
        dispatch(setLoading(true));
        getOrganisationHubs(SERVER, user.organisationId).then((res) => {
          dispatch(setLoading(false));
          if (!res.ok) {
            if (res.status === 401) {
              navigate("/login");
            }
          } else {
            dispatch(setServiceUsers(res.body));
          }
        });
      }
      // 2- Everyone else's hubs come from the /auth endpoint
      else {
        dispatch(setLoading(true));
        getCheckAuthState(SERVER, "web").then((res) => {
          dispatch(setLoading(false));
          if (!res.ok) {
            if (res.status === 401) {
              navigate("/login");
            }
          } else {
            dispatch(setServiceUsers(res.body.hubs));
          }
        });
      }
    }
  }, [
    dispatch,
    navigate,
    user?.organisationId,
    refreshOrganisationHubs,
    isAdmin,
    isManager,
  ]);

  // Hook to allow refreshing the user data from the server.
  // Other components can post changes to the user object and get it refreshed from here
  useEffect(() => {
    if (refreshUserRequested) {
      refreshUser(
        SERVER,
        dispatch,
        updateUser,
        setLoading,
        setServiceUsers,
        getCheckAuthState,
        "web"
      );
      dispatch(setRefreshUser(false));
    }
  }, [refreshUserRequested, dispatch]);

  // Getting the staff and their profile photos for the users organisation
  useEffect(() => {
    if (refreshStaffRequested) {
      // prevent infinite refreshes
      dispatch(setRefreshStaff(false));
      getOrganisationStaff(SERVER, user?.organisationId)
        .then((res) => {
          if (!res.ok) {
            alert("Could not fetch staff members");
          } else {
            dispatch(setStaff(res?.body));
          }
        })
        .catch((err) => {
          alert("Could not fetch staff members", err);
        });
    }
  }, [dispatch, user?.organisationId, refreshStaffRequested, user?.key]);

  // Add the askai script to all routes apart from the pdf report generator page
  useEffect(() => {
    const reportPaths = ["pdf-report", "pdf-preview"];
    const notAReportPath = !reportPaths.some((reportPath) =>
      location.pathname.includes(reportPath)
    );
    // Check if the current path is not included in the reportPaths array
    if (notAReportPath) {
      if (!document.getElementById("askai-floating-button")) {
        const script = document.createElement("script");
        script.src =
          "https://myaskai.com/api/1.1/wf/embed-v2?i=oWFVHQguyK3ufW5PGRsCuX9bKA8T1G";
        script.defer = true;
        script.type = "text/javascript";
        script.id = "oWFVHQguyK3ufW5PGRsCuX9bKA8T1G";
        document.body.appendChild(script);
      }
    } else {
      document.getElementById("askai-floating-button")?.remove();
    }
  }, [location.pathname]);

  return (
    <>
      {checkedAuth ? (
        <Routes>
          <Route
            end
            path={"/"}
            element={
              <AuthenticatedRoute qr={qr} title="dashboard">
                <Dashboard />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/lilli-users"}
            element={
              <AuthenticatedRoute qr={qr} initialURL={initialURL} title="users">
                <LilliUsers />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/lilli-users/:id"}
            element={
              <AuthenticatedRoute qr={qr} initialURL={initialURL} title="user">
                <LilliUser />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/profile"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="profile"
              >
                <Profile />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/staff"}
            element={
              <AuthenticatedRoute qr={qr} initialURL={initialURL} title="staff">
                <Staff />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/staff/:staffMemberId"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="staff member"
              >
                <StaffMember />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/finish/:id/:devicestring/"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallFinish />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <Install />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/service-user"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallSelectSU />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/link/:id"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallLinkDevice />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/hub/:id/:devicestring/"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallHub />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/hub/provisioning/:id/:devicestring/"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallHubProvisioning />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/sensors/motion/:id/:devicestring/:name"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallMotion />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/sensors/motion/:id/:devicestring/stand/:name"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallMotionStand />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/sensors/motion/:id/:devicestring/strip/:name"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallMotionStrip />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/sensors/motion/:id/:devicestring/bracket/:name"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallMotionBracket />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/sensors/plug/:id/:devicestring"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallPlug />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/sensors/door/:id/:devicestring/:name"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallDoor />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/sensors/vibration/:id/:devicestring"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallVibration />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/install/sensors/:id/:devicestring"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="install"
              >
                <InstallSensors />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/reports"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="reports"
              >
                <Reports />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/notifications"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="notifications"
              >
                <Notifications />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/pdf-preview/:id"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="preview"
              >
                <PdfPreview />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/download-app"}
            element={
              <AuthenticatedRoute
                qr={qr}
                initialURL={initialURL}
                title="download app"
              >
                <NativeAppDownload />
              </AuthenticatedRoute>
            }
          />
          <Route
            end
            path={"/pdf-report"}
            element={
              <TitledRoute>
                <PdfGenerator />
              </TitledRoute>
            }
          />
          <Route
            end
            path={"/qr/hw/develco/:gatewayId"}
            element={
              <TitledRoute title="hub info">
                <QRCodePage />
              </TitledRoute>
            }
          />
          <Route
            end
            path={"/login"}
            element={
              <TitledRoute title="log in">
                <LoginPage />
              </TitledRoute>
            }
          />
          <Route
            end
            path={"/about"}
            element={
              <TitledRoute title="about">
                <About />
              </TitledRoute>
            }
          />
          <Route
            end
            path={"/faqs"}
            element={
              <TitledRoute title="FAQs">
                <FAQs />
              </TitledRoute>
            }
          />
          <Route
            end
            path={"/privacy"}
            element={
              <TitledRoute title="privacy">
                <Privacy />
              </TitledRoute>
            }
          />
          <Route
            end
            path={"/privacy-old"}
            element={
              <TitledRoute title="old privacy">
                <OldPrivacy />
              </TitledRoute>
            }
          />
          <Route
            end
            path={"/user/onboarding/token/:token"}
            element={
              <TitledRoute title="onboarding">
                <UserOnboarding />
              </TitledRoute>
            }
          />
          <Route
            end
            path={"/user/reset-password/token/:token"}
            element={
              <TitledRoute title="reset password">
                <ResetPassword />
              </TitledRoute>
            }
          />

          {/* A catch-all route if the path isn't found */}
          <Route
            path="*"
            element={
              <TitledRoute title="error">
                <Error404 />
              </TitledRoute>
            }
          />
        </Routes>
      ) : (
        <Spinner loadingBaseData={1} />
      )}
    </>
  );
};

export default App;
