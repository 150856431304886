import React from "react";
import styles from "./styles.module.scss";
import Report from "../Report";
import { DownArrow, UpArrow } from "../../Styles/Icons/DesignSystem";
import { SortArrows } from "../SortArrows";

const SortableHeading = ({
  headingName,
  isSelected,
  sortDirection,
  handleFilterClick,
}) => (
  <th
    onClick={() => handleFilterClick(headingName)}
    className={styles.table_header_sortable}
  >
    <span>{headingName}</span>
    {isSelected ? (
      sortDirection === "ascending" ? (
        <DownArrow />
      ) : (
        <UpArrow />
      )
    ) : (
      <SortArrows />
    )}
  </th>
);

function ReportsTable({
  isDashboard,
  sortBy,
  sortDirection,
  handleFilterClick,
  reportsToDisplay,
  server,
  setReportToDelete,
  setCloseConfirmModal,
}) {
  if (reportsToDisplay?.length === 0)
    return (
      <div className={styles.no_reports_msg}>
        You haven't set up any reports yet
      </div>
    );

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.table_header}>
          <SortableHeading
            headingName="Name"
            isSelected={sortBy === "Name"}
            sortDirection={sortDirection}
            handleFilterClick={handleFilterClick}
          />
          <SortableHeading
            headingName="User"
            isSelected={sortBy === "User"}
            sortDirection={sortDirection}
            handleFilterClick={handleFilterClick}
          />
          <SortableHeading
            headingName="Created"
            isSelected={sortBy === "Created"}
            sortDirection={sortDirection}
            handleFilterClick={handleFilterClick}
          />
          {!isDashboard && (
            <>
              <th className={styles.table_header_element}>Dates</th>
              <SortableHeading
                headingName="Covers"
                isSelected={sortBy === "Covers"}
                sortDirection={sortDirection}
                handleFilterClick={handleFilterClick}
              />
            </>
          )}
          <th className={styles.table_header_buttons} />
        </tr>
      </thead>

      {/* table body */}
      <tbody>
        {reportsToDisplay.map((one_report, index) => (
          <Report
            //TODO: find a better key to use than index
            key={index}
            report={one_report}
            server={server}
            isDashboard={isDashboard}
            onMobile={false}
            setReportToDelete={setReportToDelete}
            setCloseConfirmModal={setCloseConfirmModal}
          />
        ))}
      </tbody>
    </table>
  );
}

export default ReportsTable;
