import React from "react";
import theme from "../../Styles/theme.scss";
// Components
import PrimaryButton from "../PrimaryButton";
import { DeleteIcon } from "../../Styles/Icons/DesignSystem";

const DeleteButton = ({ label, onClick, padding, disabled }) => (
  <PrimaryButton
    startIcon={<DeleteIcon colour={theme.neutral7} />}
    onClick={onClick}
    backgroundColour={theme.atrisk2}
    hoverColour={theme.error2}
    padding={padding ? padding : `4px 16px`}
    disabled={disabled}
  >
    {label}
  </PrimaryButton>
);

export default DeleteButton;
