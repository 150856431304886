import { getDevelcoGatewaySensors as getDevelcoGatewaySensorsAPI } from "@intelligentlilli/api-layer";

const getDevelcoGatewaySensors = async (server, devicestring) => {
  try {
    const response = await getDevelcoGatewaySensorsAPI(
      server,
      devicestring,
      "web"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default getDevelcoGatewaySensors;
