import React from "react";
import theme from "../../theme.scss";

export const CloseIcon = ({ width, color }) => {
  return (
    <svg width={width || 30} viewBox="0 0 28 28" id="CloseIcon">
      <path
        d="M26.4999 1.49962C26.1092 1.10906 25.5794 0.889648 25.0269 0.889648C24.4745 0.889648 23.9447 1.10906 23.554 1.49962L13.9999 11.0538L4.4457 1.49962C4.05502 1.10906 3.52521 0.889648 2.97278 0.889648C2.42036 0.889648 1.89055 1.10906 1.49986 1.49962C1.1093 1.8903 0.889893 2.42011 0.889893 2.97254C0.889893 3.52496 1.1093 4.05477 1.49986 4.44545L11.054 13.9996L1.49986 23.5538C1.1093 23.9445 0.889893 24.4743 0.889893 25.0267C0.889893 25.5791 1.1093 26.1089 1.49986 26.4996C1.89055 26.8902 2.42036 27.1096 2.97278 27.1096C3.52521 27.1096 4.05502 26.8902 4.4457 26.4996L13.9999 16.9455L23.554 26.4996C23.9447 26.8902 24.4745 27.1096 25.0269 27.1096C25.5794 27.1096 26.1092 26.8902 26.4999 26.4996C26.8904 26.1089 27.1098 25.5791 27.1098 25.0267C27.1098 24.4743 26.8904 23.9445 26.4999 23.5538L16.9457 13.9996L26.4999 4.44545C26.8904 4.05477 27.1098 3.52496 27.1098 2.97254C27.1098 2.42011 26.8904 1.8903 26.4999 1.49962Z"
        fill={color || theme.white}
      />
    </svg>
  );
};
