import React from "react";
import theme from "../../theme.scss";

export const ErrorIcon = ({ size, color }) => {
  return (
    <svg
      width={size || "28"}
      height={size || "28"}
      viewBox="0 0 28 28"
      fill="none"
      id="Error-Icon"
    >
      <g id="Icon-Error">
        <path
          id="Vector"
          d="M18.4448 9.55574C18.011 9.12188 17.3079 9.12188 16.8735 9.55574L14.0001 12.4292L11.1266 9.55574C10.6855 9.1297 9.98184 9.14165 9.55528 9.5828C9.13965 10.0135 9.13965 10.6958 9.55528 11.1266L12.4287 14L9.55528 16.8734C9.12923 17.3146 9.14124 18.0182 9.58238 18.4448C10.0131 18.8604 10.6954 18.8604 11.1261 18.4448L13.9996 15.5714L16.873 18.4448C17.3141 18.8708 18.0178 18.8589 18.4443 18.4177C18.86 17.987 18.86 17.3047 18.4443 16.874L15.5709 14.0005L18.4443 11.1271C18.8782 10.6932 18.8782 9.99011 18.4443 9.55574H18.4448Z"
          fill={color || theme.error2}
        />
        <path
          id="Vector_2"
          d="M14 0.666687C6.63648 0.666687 0.666687 6.63648 0.666687 14C0.666687 21.3636 6.63648 27.3334 14 27.3334C21.3636 27.3334 27.3334 21.3636 27.3334 14C27.3255 6.6396 21.3604 0.6745 14 0.666687ZM14 25.111C7.86356 25.111 2.88908 20.1365 2.88908 14C2.88908 7.86356 7.86356 2.88908 14 2.88908C20.1365 2.88908 25.1109 7.86356 25.1109 14C25.1041 20.1339 20.1334 25.1042 14 25.111Z"
          fill={color || theme.error2}
        />
      </g>
    </svg>
  );
};
