import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// State
import { useSelector, useDispatch } from "react-redux";
import { updateSensor } from "../../../State/slices/sensors-slice";
// Components
import { useViewport } from "../../../Components/ViewportProvider";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import InstallSensor from "../../../Components/InstallSensor";
// Hooks
import { useGetSensorStatus } from "../../../Services/Hooks";

const InstallMotion = () => {
  const { devicestring, id, name } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;

  // sizing
  const { width } = useViewport();
  const isDesktop = width > 600;

  // Trigger the sensor status fetching
  const { loading, fetchSensorStatus } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: false,
  });

  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const selectedSuObject = serviceUsers.filter((i) => i.id === parseInt(id));
  const selectedSUName = selectedSuObject?.[0]?.userstring || "";

  // redux state
  const dispatch = useDispatch();
  // Local state
  const [selectedOption, setSelectedOption] = useState();

  // Get the correct sensor
  const sensors = useSelector((state) => state.sensors);
  const sensorIndex = sensors?.devices?.findIndex(
    (device) => device.route === pathname
  );
  // Bool to check we have data
  const foundSensor = sensorIndex >= 0;
  const sensor = foundSensor && sensors?.devices?.[sensorIndex];

  const sensorLocations = useSelector((state) => state.sensorLocations);
  const sensorLocation = sensorLocations?.[sensor?.name];

  // Keep track of which pages the user has visited
  useEffect(() => {
    foundSensor &&
      dispatch(
        updateSensor({
          index: sensorIndex,
          key: "checkedConnection",
          value: true,
        })
      );
  }, [dispatch, sensorIndex, foundSensor]);

  // installation
  const installationOptions = [
    {
      title: "Motion sensor stand",
      body: "Where there is a suitable shelf or mantlepiece to place the sensor on",
      route: `/install/sensors/motion/${id}/${devicestring}/stand/${name}`,
      image: "/images/installation/sensors/motion/motion-stand.png",
    },
    {
      title: "Adhesive strip",
      body: "Where there is a smooth surface to attach the sensor",
      route: `/install/sensors/motion/${id}/${devicestring}/strip/${name}`,
      image: "/images/installation/sensors/motion/motion-adhesive.png",
    },
    {
      title: "Mounting bracket",
      body: "Where there is only rough surfaces to attach the sensor",
      route: `/install/sensors/motion/${id}/${devicestring}/bracket/${name}`,
      image: "/images/installation/sensors/motion/motion-mount.png",
    },
  ];

  const motionHints = [
    "We want a transition zone, i.e. hallway, doorway. It should not be in a place where the Lilli user will spend large amounts of time in front of it, i.e. do not place it in front of the dining table or sofa.",
    "It should be above waist height.",
    "For it to work best there needs to be minimal obstructions between the Lilli user's movement and the sensor.",
    "Low battery mode: The device will flash red twice every minute when the battery is low.",
    "Searching gateway mode: Red light flashing every second.",
  ];

  return (
    <InstallSensor
      image="/images/installation/sensors/motion/motion.png"
      altText="Lilli motion sensors - different ways to set it up"
      sensor={sensor}
      devicestring={devicestring}
      userstring={selectedSUName}
      hints={motionHints}
      loading={loading}
      testConnection={fetchSensorStatus}
      locationSelected={!!sensorLocation && sensor.locationRequired}
      cypresstestid={"Installation-Motion-Sensor"}
    >
      <div className={styles.motion_top}>
        <div className={styles.installation_options_header}>
          There are three options for setup. Please select your preferred
          method:
        </div>
        <div className={styles.sensors_title}>Sensors</div>
        {installationOptions.map((option, index) => (
          <div
            className={`${styles.option} ${
              selectedOption?.title === option.title && styles.selected
            }`}
            key={index}
            onClick={() => setSelectedOption(option)}
          >
            <div className={styles.image_container}>
              <img src={option.image} alt={option.title} />
            </div>
            <div>
              <div className={styles.option_title}>{option.title}</div>
              <div className={styles.option_body}>{option.body}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <div className={styles.buttons_button}>
          <InstallationGoBackButton
            textColor={theme.primary6}
            onClick={() => navigate(-1)}
            marginRight={isDesktop ? "24px" : "0px"}
            marginTop={!isDesktop && "12px"}
            label={"Go back"}
          />
        </div>
        <div className={styles.buttons_button}>
          <InstallationNextButton
            onClick={() => navigate(selectedOption.route)}
            label={"Get started"}
            disabled={!selectedOption}
          />
        </div>
      </div>
    </InstallSensor>
  );
};

export default InstallMotion;
