import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// components
import StaffItem from "../StaffItem";
import StaffItemMobile from "../StaffItemMobile";
import { useViewport } from "../ViewportProvider";
// services
import getUserProperties from "../../Services/Cache/Users/getUserProperties";
import { mobileBreakpoint } from "../../Services/config";
import { updateStaffMember } from "../../State/slices/staff-slice";
import { capitalize } from "@intelligentlilli/lilli-utils";
import { getSentenceSeperator } from "../../Services/Utils";

const DashboardStaff = ({ searchValue }) => {
  const [filteredStaff, setFilteredStaff] = useState([]);

  const dispatch = useDispatch();
  const staff = useSelector((state) => state.staff);
  const server = useSelector((state) => state.session.server);
  const navigate = useNavigate();
  const { width } = useViewport();
  const onMobile = width < mobileBreakpoint;

  const staffIdsString = JSON.stringify(staff.map((staff) => staff.id));

  const augmentedStaff = staff
    ?.map((staff) => {
      const augmentedRoles = staff.roles
        .map((role) => {
          switch (role) {
            case "app":
              return "carer";
            case "user":
              return "carer";
            case "manager":
              return "manager";
            case "installer":
              return "installer";
            case "friend-or-family":
              return "Friends & family";
            default:
              return "unknown";
          }
        })
        .filter((role) => role !== "Unknown");

      const uniqueAugmentedRoles = [...new Set(augmentedRoles)];
      const roleAsString = getSentenceSeperator(uniqueAugmentedRoles, "and");

      return {
        ...staff,
        roles: uniqueAugmentedRoles.map((role) =>
          role ? capitalize(role) : null
        ),
        rolesString: roleAsString ? capitalize(roleAsString) : null,
        serviceUsersString: `${staff?.hubs?.length} service user${
          staff?.hubs?.length === 1 ? "" : "s"
        }`,
      };
    })
    .filter((staff) => staff.rolesString !== "Friends & family");

  const augmentedStaffString = JSON.stringify(augmentedStaff);

  useEffect(() => {
    const staffToFilter = JSON.parse(augmentedStaffString);
    if (searchValue) {
      const filteredStaff = staffToFilter.filter((staff) => {
        // check whether the surname matches the search value
        const surnameMatch =
          staff.surname?.slice(0, searchValue.length).toLowerCase() ===
          searchValue.toLowerCase();
        const firstNameMatch =
          staff.forenames?.slice(0, searchValue.length).toLowerCase() ===
          searchValue.toLowerCase();
        return surnameMatch || firstNameMatch;
      });
      setFilteredStaff(filteredStaff);
    } else {
      setFilteredStaff(staffToFilter);
    }
  }, [searchValue, augmentedStaffString]);

  // Get request for the staff members data and photo
  useEffect(() => {
    async function fetchData() {
      const staffIds = JSON.parse(staffIdsString) || [];
      // Wait for both the getUser and getUserHubs API calls to finish
      await Promise.all(
        staffIds.map((id, index) =>
          getUserProperties(server, id).then(({ body }) => {
            dispatch(
              updateStaffMember({
                index,
                key: "lastActivity",
                value: body.lastActivity,
              })
            );
          })
        )
      );
    }
    fetchData();
  }, [server, staffIdsString, dispatch]);

  return (
    <>
      {!onMobile && (
        <div className={styles.table}>
          <div className={styles.table_header}>
            <div>User</div>
            <div>Role</div>
            <div>Service users</div>
            <div>Last seen</div>
          </div>
          {filteredStaff.map((staff, index) => (
            <StaffItem key={index} staff={staff} navigate={navigate} />
          ))}
        </div>
      )}
      {onMobile &&
        filteredStaff.map((staff, index) => (
          <StaffItemMobile key={index} staff={staff} navigate={navigate} />
        ))}
    </>
  );
};

export default DashboardStaff;
