import React from "react";
import styles from "./styles.module.scss";

const PdfFooter = () => {
  return (
    <div className={styles.footer}>
      <img src="/logo/lilli-128-teal.png" alt="Lilli" width="60" />
      <div className={styles.icons}>
        <div className={styles.icons_icon}>
          <img src={"/images/icons/connect.svg"} alt={"Connect"} />
          <div>Connect</div>
        </div>
        <div className={styles.icons_icon}>
          <img src={"/images/icons/protect.svg"} alt={"Protect"} />
          <div>Protect</div>
        </div>
        <div className={styles.icons_icon}>
          <img src={"/images/icons/live.svg"} alt={"Live"} />
          <div>Live</div>
        </div>
      </div>
    </div>
  );
};

export default PdfFooter;
