import React from "react";
import styles from "./styles.module.scss";
// components
import InstallationBanner from "../InstallationBanner";
import InstallationHint from "../InstallationHint";
import InstallationSuDetails from "../InstallationSuDetails";
import SensorDetails from "../SensorDetails";
import Card from "../Card";
import Page from "../Page";
import { useViewport } from "../ViewportProvider";
import InstallationArt from "../InstallationArt";

const InstallSensor = ({
  images,
  image,
  altText,
  children,
  imageStyle,
  sensor,
  userstring,
  devicestring,
  hints,
  testConnection,
  loading,
  locationSelected = true,
  cypresstestid,
}) => {
  // sizing
  const { width } = useViewport();
  const isDesktop = width > 600;

  return (
    <Page className={styles.page} cypresstestid={cypresstestid}>
      {isDesktop && <InstallationBanner />}
      <Card style={styles.card}>
        {isDesktop ? (
          <InstallationSuDetails
            userstring={userstring}
            devicestring={devicestring}
            onClick={testConnection}
            loading={loading}
            online={sensor?.online && locationSelected}
            isDesktop={isDesktop}
            allowRefresh={false}
          />
        ) : (
          <div className={styles.card_top}>
            <InstallationArt half />
            <SensorDetails
              sensor={sensor}
              loading={loading}
              locationSelected={locationSelected}
            />
          </div>
        )}
        <div className={styles.card_bottom}>
          {/* "Card Left" becomes invisible at width of 900px */}
          <div className={styles.card_left}>
            <div>
              {image && (
                <img
                  src={image}
                  alt={altText}
                  className={imageStyle || styles.logo}
                />
              )}
              {images && (
                <div className={styles.images_container}>
                  {images.map((image, index) => {
                    return (
                      <img
                        key={index}
                        src={image.image}
                        alt={image.altText}
                        className={image.className || imageStyle || styles.logo}
                      />
                    );
                  })}
                </div>
              )}
              {width > 900 && <InstallationHint hints={hints} />}
            </div>
          </div>
          <div className={styles.card_right}>
            <h2>
              Set up: <br />
              {sensor?.displayName}
            </h2>

            <img
              src={image}
              alt={altText}
              className={`${imageStyle || styles.logo} ${
                imageStyle || styles.logo_mobile
              }`}
            />
            {children}
          </div>
        </div>
      </Card>
    </Page>
  );
};
export default InstallSensor;
