import React from "react";
// Utils
import theme from "../../theme.scss";

export const HelpIcon = ({ width, colour }) => {
  return (
    <svg
      width={width || 22}
      height={width || 22}
      viewBox="0 0 22 22"
      id="HelpIcon"
    >
      <path
        d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.7805 0.83733 6.79048C0.00476615 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9968 8.08359 20.8369 5.28753 18.7747 3.22531C16.7125 1.16309 13.9164 0.00315432 11 0V0ZM11 20.1667C9.18701 20.1667 7.41473 19.6291 5.90728 18.6218C4.39983 17.6146 3.22491 16.1829 2.53111 14.5079C1.83731 12.8329 1.65578 10.9898 2.00947 9.21167C2.36317 7.43351 3.23621 5.80017 4.51819 4.51819C5.80017 3.23621 7.43352 2.36317 9.21168 2.00947C10.9898 1.65577 12.8329 1.8373 14.5079 2.5311C16.1829 3.22491 17.6146 4.39982 18.6218 5.90727C19.6291 7.41472 20.1667 9.18701 20.1667 11C20.164 13.4303 19.1974 15.7604 17.4789 17.4789C15.7604 19.1974 13.4303 20.164 11 20.1667Z"
        fill={colour || theme.error1}
      />
      <path
        d="M11.6575 4.64096C11.1289 4.54465 10.5856 4.56569 10.066 4.7026C9.54643 4.83951 9.06328 5.08894 8.65077 5.43324C8.23826 5.77754 7.90645 6.2083 7.67884 6.69503C7.45123 7.18175 7.33338 7.71256 7.33362 8.24988C7.33362 8.49299 7.4302 8.72615 7.6021 8.89806C7.77401 9.06997 8.00717 9.16654 8.25028 9.16654C8.4934 9.16654 8.72656 9.06997 8.89846 8.89806C9.07037 8.72615 9.16695 8.49299 9.16695 8.24988C9.16672 7.98017 9.226 7.71374 9.34056 7.46957C9.45512 7.22541 9.62214 7.00952 9.82972 6.83732C10.0373 6.66512 10.2803 6.54083 10.5414 6.47333C10.8025 6.40582 11.0753 6.39676 11.3404 6.44679C11.7025 6.51709 12.0354 6.69373 12.2967 6.95416C12.5579 7.21459 12.7356 7.54698 12.807 7.90888C12.8792 8.28875 12.8294 8.6817 12.6647 9.03155C12.5001 9.38141 12.229 9.67025 11.8904 9.85679C11.3295 10.1817 10.8661 10.6511 10.5483 11.216C10.2305 11.781 10.0701 12.4208 10.0836 13.0688V13.7499C10.0836 13.993 10.1802 14.2261 10.3521 14.3981C10.524 14.57 10.7572 14.6665 11.0003 14.6665C11.2434 14.6665 11.4766 14.57 11.6485 14.3981C11.8204 14.2261 11.9169 13.993 11.9169 13.7499V13.0688C11.9054 12.7498 11.9782 12.4334 12.1278 12.1515C12.2775 11.8695 12.4988 11.632 12.7694 11.4628C13.4336 11.098 13.9684 10.5365 14.3004 9.85533C14.6323 9.1742 14.7451 8.40696 14.6232 7.65911C14.5013 6.91126 14.1507 6.21954 13.6197 5.67908C13.0886 5.13862 12.4031 4.77595 11.6575 4.64096Z"
        fill={colour || theme.error1}
      />
      <path
        d="M11.917 16.4999C11.917 15.9936 11.5065 15.5832 11.0003 15.5832C10.494 15.5832 10.0836 15.9936 10.0836 16.4999C10.0836 17.0061 10.494 17.4165 11.0003 17.4165C11.5065 17.4165 11.917 17.0061 11.917 16.4999Z"
        fill={colour || theme.error1}
      />
    </svg>
  );
};
