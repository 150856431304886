import { postCreateUser as postCreateUserAPI } from "@intelligentlilli/api-layer";

const postCreateUser = async (server, forename, surname, email, roles) => {
  try {
    const response = await postCreateUserAPI(
      server,
      forename,
      surname,
      email,
      roles,
      "web"
    );
    return response;
  } catch (err) {
    return err;
  }
};

export default postCreateUser;
