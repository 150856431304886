import React from "react";
import theme from "../../Styles/theme.scss";
import styles from "./styles.module.scss";
// Components
import InstallationNextButton from "../InstallationNextButton";
import InstallationGoBackButton from "../InstallationGoBackButton";

const InstallationSplash = ({
  isDesktop,
  icon,
  headers,
  message,
  email,
  goBackOnClick,
  goBackButtonLabel,
  nextButtonOnClick,
  nextButtonLabel,
  cypresstestid,
}) => {
  return (
    <div className={styles.splash_container} cypresstestid={cypresstestid}>
      <div className={styles.splash_top}>
        <div className={styles.icon}>{icon}</div>
        {headers.map((header) => (
          <div className={styles.installation_header}>{header}</div>
        ))}
        {message && (
          <div className={styles.installation_message}>
            {message}
            {email && (
              <span>
                <a
                  href={`mailto: ${email}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: theme.primary6 }}
                >
                  {email}
                </a>
              </span>
            )}
          </div>
        )}
      </div>
      <div className={styles.splash_bottom}>
        {goBackButtonLabel && (
          <InstallationGoBackButton
            textColor={theme.error2}
            onClick={goBackOnClick}
            isDesktop={isDesktop}
            marginRight={isDesktop ? "24px" : "0px"}
            label={goBackButtonLabel}
            marginTop={"12px"}
          />
        )}
        <InstallationNextButton
          isDesktop={isDesktop}
          onClick={nextButtonOnClick}
          label={nextButtonLabel}
        />
      </div>
    </div>
  );
};

export default InstallationSplash;
