import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import theme from "../../Styles/theme.scss";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// Components
import PrimaryButton from "../../Components/PrimaryButton";
import Page from "../../Components/Page";
import SearchInput from "../../Components/SearchInput";
// Hooks
import { useReportsHook, useFade } from "../../Services/Hooks";
// Icons
import { SortIcon } from "../../Styles/Icons/DesignSystem";
import { AddIcon } from "../../Styles/Icons";
// Utils
import { sortHigh, sortLow } from "@intelligentlilli/lilli-utils";
import { useViewport } from "../../Components/ViewportProvider";
import PageTitle from "../../Components/PageTitle";
import ReportsTable from "../../Components/ReportsTable";
import MobileReportsTable from "../../Components/MobileReportsTable";
import MobileSortReportsOverlay from "../../Components/MobileSortReportsOverlay";
import CreateNewReportModal from "../../Components/CreateNewReportModal";
import ConfirmDeleteReportModal from "../../Components/ConfirmDeleteReportModal";

function Reports() {
  const navigate = useNavigate();

  // redux state
  const server = useSelector((state) => state.session.server);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const user = useSelector((state) => state.user);
  // Local state
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("Created");
  const [sortDirection, setSortDirection] = useState("ascending");
  const [reportsToDisplay, setReportsToDisplay] = useState([]);
  const [showMobileSort, setShowMobileSort] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const { width } = useViewport();
  const onMobile = width < 1100; // Below 1100 the table view no longer compresses so switch to mobile view

  // A ref to keep track of when the search value is changing so that sorting can be reset
  const searchValueRef = useRef();

  const handleFilterClick = (clickedFilter) => {
    if (sortBy === clickedFilter) {
      setSortDirection((previousSortDirection) =>
        previousSortDirection === "ascending" ? "descending" : "ascending"
      );
    } else {
      setSortBy(clickedFilter);
      setSortDirection("ascending");
    }
  };

  // custom hooks
  const {
    reportTypes,
    reports,
    deleteSelectedReport,
    closeConfirmModal,
    setCloseConfirmModal,
  } = useReportsHook(server, navigate);

  // When the user searches or sorts update the reports to display
  useEffect(() => {
    let filteredReports = reports;
    // First extract the userstring and convert all report names to lower case as the sorting functions cannot handle a case mix
    filteredReports = filteredReports.map((report) => {
      const userstring = serviceUsers?.find(
        (hub) => hub.id === report.serviceuserids?.[0]
      )?.userstring;
      return {
        ...report,
        nameLower: report.name.toLowerCase(),
        nameArray: report.name.toLowerCase().split(" "),
        userstring: userstring,
        userstringLower: userstring?.toLowerCase(),
        userstringArray: userstring?.toLowerCase().split(" "),
      };
    });

    // This function searches an array of strings and determines whether any of them match the search value
    const searchArray = (array, inputValue, inputLength) => {
      let matched = false;
      array?.forEach((string) => {
        if (string.slice(0, inputLength) === inputValue) {
          matched = true;
        }
      });
      return matched;
    };

    // Apply the search
    if (searchValue.length > 0) {
      const inputValue = searchValue.trim().toLowerCase();
      const inputLength = inputValue.length;
      filteredReports = filteredReports.filter(
        (report) =>
          report.nameLower?.slice(0, inputLength) === inputValue ||
          report.userstringLower?.slice(0, inputLength) === inputValue ||
          searchArray(report.nameArray, inputValue, inputLength) ||
          searchArray(report.userstringArray, inputValue, inputLength)
      );
    }

    // Hold the correct keys for sorting reports given a table column heading
    const sortKeys = {
      Name: "nameLower",
      User: "userstringLower",
      Created: "ts",
      Covers: "days",
    };

    if (sortDirection === "ascending") {
      filteredReports = sortHigh(filteredReports, sortKeys[sortBy]);
    }

    if (sortDirection === "descending") {
      filteredReports = sortLow(filteredReports, sortKeys[sortBy]);
    }
    // Update the ref for the current search term
    searchValueRef.current = searchValue;

    setReportsToDisplay(filteredReports);
  }, [
    user.key,
    user.organisation,
    server,
    reports,
    searchValue,
    sortBy,
    sortDirection,
    serviceUsers,
  ]);

  //TODO: see if useFade can be moved to the CreateNewReportModal component
  // Fade Modal
  const [isCreateReportModalVisible, setShowAnimation, showAnimation] = useFade(
    false,
    150
  ); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  return (
    <Page className={styles.page}>
      <div className={styles.content}>
        <PageTitle title="Report list" />
        <div className={styles.header}>
          <div className={styles.header_top}>
            <SearchInput
              className={`${styles.header_search} fs-mask`}
              placeholder="Search reports"
              value={searchValue}
              setValue={setSearchValue}
            />
            <PrimaryButton
              className={styles.mobile_sort}
              backgroundColour={theme.white}
              border={`1px solid ${theme.neutral2}`}
              colour={theme.neutral5}
              hoverColour={theme.white}
              startIcon={<SortIcon />}
              padding="8px 15px"
              onClick={() => setShowMobileSort(true)}
            >
              Sort
            </PrimaryButton>
          </div>

          <PrimaryButton
            startIcon={<AddIcon />}
            onClick={() => setShowAnimation(true)} //kicks off a series of actions controlled by the useFade hook
            // when set to True, the useEffect hook then sets the NewModal visibility to true
            // when showAnimation is true, then the class ".fade_in" animation kicks in
          >
            New Report
          </PrimaryButton>
        </div>
        {/* ------------------ Desktop View ----------------- */}
        {!onMobile && (
          <ReportsTable
            isDashboard={false}
            sortBy={sortBy}
            sortDirection={sortDirection}
            handleFilterClick={handleFilterClick}
            reportsToDisplay={reportsToDisplay}
            server={server}
            setReportToDelete={setReportToDelete}
            setCloseConfirmModal={setCloseConfirmModal}
          />
        )}
        {/* ------------------ Mobile View ----------------- */}
        {onMobile && (
          <MobileReportsTable
            reportsToDisplay={reportsToDisplay}
            server={server}
            setReportToDelete={setReportToDelete}
            setCloseConfirmModal={setCloseConfirmModal}
          />
        )}
        {/* New report */}
        {isCreateReportModalVisible && (
          <CreateNewReportModal
            serviceUsers={serviceUsers}
            reportTypes={reportTypes}
            showAnimation={showAnimation}
            setShowAnimation={setShowAnimation}
          />
        )}
        {showMobileSort && (
          <MobileSortReportsOverlay
            setShowMobileSort={setShowMobileSort}
            sortBy={sortBy}
            handleFilterClick={handleFilterClick}
          />
        )}
      </div>

      {/* Confirm Delete */}
      {!closeConfirmModal && (
        <ConfirmDeleteReportModal
          setCloseConfirmModal={setCloseConfirmModal}
          reportToDelete={reportToDelete}
          deleteSelectedReport={deleteSelectedReport}
        />
      )}
    </Page>
  );
}

export default Reports;
