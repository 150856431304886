import { getOrganisationHubs as getOrganisationHubsAPI } from "@intelligentlilli/api-layer";

const getOrganisationHubs = async (server, id) => {
  try {
    const response = await getOrganisationHubsAPI(server, id, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getOrganisationHubs;
