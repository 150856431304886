import { getHubBedroomAnalysis as getHubBedroomAnalysisAPI } from "@intelligentlilli/api-layer";

const getHubBedroomAnalysis = async (server, id, days, endTime) => {
  try {
    const response = await getHubBedroomAnalysisAPI(
      server,
      id,
      days,
      endTime,
      "web"
    );
    if (response.ok) {
      return response.body;
    } else {
      return null;
    }
  } catch (err) {
    console.log(`Error fetching data for Bedroom Analysis:`, err);
    return err;
  }
};

export default getHubBedroomAnalysis;
