import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// components
import InstallationGoBackButton from "../InstallationGoBackButton";
import InstallationNextButton from "../InstallationNextButton";
import AltSpinner from "../Spinners/AltSpinner";

const InstallationButtons = ({
  loading,
  goBack,
  sensor,
  sensorLocation,
  fetchSensorStatus,
}) => {
  return (
    <div className={styles.buttons}>
      {!sensor?.online && (
        <div className={styles.error} style={{ alignSelf: "flex-end" }}>
          Sensor not yet connected
        </div>
      )}

      <div className={styles.buttons_row}>
        <div className={styles.buttons_button}>
          <InstallationGoBackButton
            textColor={theme.primary6}
            onClick={() => goBack()}
            marginTop={0}
            label={"Go back"}
          />
        </div>
        <div className={styles.buttons_button}>
          <InstallationNextButton
            onClick={() => goBack()}
            disabled={
              !sensor?.online ||
              loading ||
              (!sensorLocation && sensor?.locationRequired)
            }
            label={"Next"}
          />
        </div>
      </div>

      {!sensorLocation && sensor?.locationRequired && (
        <div className={styles.error}>
          Select the location before testing the connection
        </div>
      )}
      <div className={styles.buttons_button}>
        <InstallationNextButton
          icon={loading && <AltSpinner small style={{ marginRight: 6 }} />}
          onClick={fetchSensorStatus}
          disabled={loading || (!sensorLocation && sensor?.locationRequired)}
          label={!loading ? "Test connection" : "Testing..."}
        />
      </div>
    </div>
  );
};

export default InstallationButtons;
