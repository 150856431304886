import { postHubNotes as postHubNotesAPI } from "@intelligentlilli/api-layer";

const postHubNotes = async (server, id, note, date) => {
  try {
    const response = await postHubNotesAPI(server, id, note, date, "web");
    return response;
  } catch (error) {
    return error;
  }
};

export default postHubNotes;
