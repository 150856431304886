import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Motion, spring } from "react-motion";
import { AutoSizer } from "react-virtualized";

// Get the row/col pair from a linear index.
const getMatrixPosition = (index, cols) => {
  return {
    row: Math.floor(index / cols),
    col: index % cols,
  };
};

const getVisualPosition = ({ row, col }, width, height) => {
  return {
    x: col * width,
    y: row * height,
  };
};

const canSwap = (src, dest, cols) => {
  const { row: srcRow, col: srcCol } = getMatrixPosition(src, cols);
  const { row: destRow, col: destCol } = getMatrixPosition(dest, cols);
  return Math.abs(srcRow - destRow) + Math.abs(srcCol - destCol) === 1;
};

const Tile = ({ hole, number, index, cols, width, height, onClick }) => {
  const matrixPos = getMatrixPosition(index, cols);
  const visualPos = getVisualPosition(matrixPos, width, height);
  const motionStyle = {
    translateX: spring(visualPos.x),
    translateY: spring(visualPos.y),
  };
  const style = {
    boxSizing: "border-box",
    display: "block",
    position: "absolute",
    ...(number === hole ? { opacity: 0 } : {}),
    width,
    height,
  };

  return (
    <Motion style={motionStyle}>
      {({ translateX, translateY }) => (
        <img
          style={{
            ...style,
            transform: `translate3d(${translateX}px, ${translateY}px, 0)`,
            transition: `opacity 400ms ease-out`,
          }}
          onClick={onClick}
          src={`/images/installation/banner/${number}.png`}
          alt=""
          className={styles.art_image}
        />
      )}
    </Motion>
  );
};

const InstallationArt = ({ half, puzzle }) => {
  const [numbers, setNumbers] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const hole = puzzle && 6;

  const swapTiles = (numbers, src, dest) => {
    [numbers[src], numbers[dest]] = [numbers[dest], numbers[src]];
    return numbers;
  };

  useEffect(() => {
    if (half) {
      setNumbers([1, 2, 3]);
    } else {
      setNumbers([1, 2, 3, 4, 5, 6]);
    }
  }, [half]);

  useEffect(() => {
    if (puzzle) {
      setTimeout(() => {
        setNumbers([1, 2, 6, 4, 5, 3]);
      }, 400);
    }
  }, [puzzle]);

  const cols = 3;
  const rows = half ? 1 : 2;

  const swap = (tileIndex) => {
    const holeIndex = numbers.indexOf(hole);
    if (canSwap(tileIndex, holeIndex, cols)) {
      const newNumbers = swapTiles([...numbers], tileIndex, holeIndex);
      setNumbers(newNumbers);
    }
  };

  const pieceWidth = Math.round(width / cols);
  const pieceHeight = Math.round(height / rows);
  return (
    <div style={{ width: "100%", height: height }}>
      <AutoSizer>
        {({ width }) => {
          const aspectRatio = 0.6846;
          const fullHeight = (width * aspectRatio * 2) / 3;
          const height = half ? fullHeight / 2 : fullHeight;
          setWidth(width);
          setHeight(height);
          return (
            <div
              style={{
                width,
                height: height,
              }}
            >
              {numbers?.map((number, index) => (
                <Tile
                  index={index}
                  number={number}
                  key={number}
                  hole={hole}
                  cols={cols}
                  width={pieceWidth}
                  height={pieceHeight}
                  onClick={() => swap(index)}
                />
              ))}
            </div>
          );
        }}
      </AutoSizer>
    </div>
  );
};
export default InstallationArt;
