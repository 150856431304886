import { postForgottenPassword as postForgottenPasswordAPI } from "@intelligentlilli/api-layer";

const postForgottenPassword = async (server, email) => {
  try {
    const response = await postForgottenPasswordAPI(server, email, "web");
    return response;
  } catch (error) {
    return error;
  }
};

export default postForgottenPassword;
