import React from "react";
import { HelpIcon } from "../../Styles/Icons/DesignSystem";
import NewStyleModal from "../NewStyleModal";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useFade } from "../../Services/Hooks/useFade";

const InstallTroubleshooting = ({ imageLocation, imageWidth }) => {
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  return (
    <>
      <div
        className={styles.troubleshooting_container}
        onClick={() => setShowAnimation(true)}
        cypresstestid="Installation-Troubleshooting-Btn"
      >
        <HelpIcon />
        <div className={styles.text}>Having trouble?</div>
      </div>
      {isVisible && (
        <NewStyleModal
          showCloseIcon={true}
          hide={() => {
            setShowAnimation(false);
          }}
          title="Need a hand?"
          useFade={true}
          showAnimation={showAnimation}
          helpIcon
        >
          <img
            src={imageLocation}
            className={styles.troubleshooting_image}
            style={{ width: imageWidth }}
            alt={"Sensor is online"}
          />
          <h3 className={styles.troubleshooting_question}>
            How do I know when a sensor is in pairing mode?
          </h3>
          <p className={styles.troubleshooting_answer}>
            When a sensor is in pairing mode there will be a red flashing light.
          </p>
          <p className={styles.troubleshooting_answer}>
            Red flashing = Sensor is pairing
          </p>
          <h3 className={styles.troubleshooting_question}>
            My sensor isn't pairing, what do I do?
          </h3>
          <p className={styles.troubleshooting_answer}>
            Hold down the reset button until it starts flashing (the 20-second
            long press)
          </p>
          <hr className={styles.hr} />
          <div>
            <div>If problem persists please contact support on</div>
            <a
              href="mailto: support@intelligentlilli.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.primary6 }}
            >
              support@intelligentlilli.com
            </a>
          </div>
        </NewStyleModal>
      )}
    </>
  );
};

export default InstallTroubleshooting;
