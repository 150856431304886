import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// Icons
import { EnvelopeIcon, ErrorIcon } from "../../Styles/Icons/DesignSystem";

const EmailSuccess = ({ userEmail }) => {
  const descriptionPart1 = "An email is on the way";
  const descriptionPart2 = " with instructions for resetting your password.";
  const descriptionPart3 =
    "If you did not receive an email, check your spam folder. It may also be that this is not the email associated with your account.";
  return (
    <>
      {descriptionPart1}
      <span className={styles.to_whom}> to </span>
      <span className={styles.user_email}>{userEmail}</span>
      {descriptionPart2}
      <div className={styles.success_info}>{descriptionPart3}</div>
    </>
  );
};

const EmailFail = ({ userEmail, message }) => {
  const descriptionPart1 = " We could not send a reset password email";
  const descriptionPart2 =
    " Please try again or contact support@intelligentlilli.com";

  return (
    <>
      {message ? message : descriptionPart1}
      <span className={styles.fail_mobile_only}>{"."}</span>
      <span className={styles.to_whom}> for </span>
      <span className={styles.user_email}>
        {userEmail}
        {". "}
      </span>
      {descriptionPart2}
    </>
  );
};

const EmailRequestMessage = ({ userEmail, isEmailSuccess, message }) => {
  return (
    <div
      className={
        isEmailSuccess
          ? styles.pw_reset_success_container
          : styles.pw_reset_fail_container
      }
    >
      <div className={styles.inner_container}>
        <div className={styles.top_container}>
          <div className={styles.icon}>
            {isEmailSuccess ? (
              <EnvelopeIcon />
            ) : (
              <ErrorIcon color={theme.error2} size={24} />
            )}
          </div>
          <div className={styles.text}>
            <div className={styles.title}>
              {isEmailSuccess ? "Please check your inbox" : "Unable to send"}
            </div>
          </div>
        </div>
        <div className={styles.bottom_container}>
          <div className={styles.description}>
            {isEmailSuccess ? (
              <EmailSuccess userEmail={userEmail} />
            ) : (
              <EmailFail userEmail={userEmail} message={message} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailRequestMessage;
