import React from "react";
import theme from "../../theme.scss";

export const NotificationItemIcon = ({ colour, width }) => {
  return (
    <svg
      width={width || 20}
      height={width || 20}
      fill="none"
      id="NotificationItemIcon"
    >
      <g>
        <path
          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99076 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z"
          fill={colour || theme.error2}
        />
        <path
          d="M9.99996 4.16699C9.77895 4.16699 9.56698 4.25479 9.4107 4.41107C9.25442 4.56735 9.16663 4.77931 9.16663 5.00033V11.667C9.16663 11.888 9.25442 12.1 9.4107 12.2562C9.56698 12.4125 9.77895 12.5003 9.99996 12.5003C10.221 12.5003 10.4329 12.4125 10.5892 12.2562C10.7455 12.1 10.8333 11.888 10.8333 11.667V5.00033C10.8333 4.77931 10.7455 4.56735 10.5892 4.41107C10.4329 4.25479 10.221 4.16699 9.99996 4.16699Z"
          fill={colour || theme.error2}
        />
        <path
          d="M10.8333 15.0003C10.8333 14.5401 10.4602 14.167 9.99996 14.167C9.53972 14.167 9.16663 14.5401 9.16663 15.0003C9.16663 15.4606 9.53972 15.8337 9.99996 15.8337C10.4602 15.8337 10.8333 15.4606 10.8333 15.0003Z"
          fill={colour || theme.error2}
        />
      </g>
    </svg>
  );
};
