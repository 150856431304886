import React from "react";
import styles from "./styles.module.scss";
// Components
import SensorSignal from "../SensorSignal";
// Icons
import SensorSticker from "../SensorSticker";

const SensorDetails = ({ sensor, loading, locationSelected }) => {
  return (
    <div className={styles.sensor_container}>
      <SensorSticker
        labelType={sensor?.labelType}
        label={sensor?.label?.toUpperCase()}
        colour={sensor?.colour}
      />
      <div className={styles.details_container}>
        <div className={styles.sensor_name}>{sensor?.displayName}</div>
        <SensorSignal
          online={
            sensor?.online
            // && (!sensor?.locationRequired || locationSelected)
          }
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SensorDetails;
