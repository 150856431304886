import React from "react";
import theme from "../../Styles/theme.scss";

const InstallSticker = ({ text, backgroundColour, colour }) => {
  return (
    <div
      style={{
        backgroundColor: backgroundColour || theme.white,
        color: colour || theme.primaryTeal,
        fontSize: theme.small_font_size,
        alignSelf: "flex-start",
        padding: "4px 8px 0 8px",
        marginBottom: 24,
        borderRadius: 2,
        border: !backgroundColour && `1px solid ${theme.primaryTeal}`,
      }}
    >
      {text}
    </div>
  );
};

export default InstallSticker;
