import React from "react";
import styles from "./styles.module.scss";

import Report from "../Report";

function MobileReportsTable({
  reportsToDisplay,
  server,
  setReportToDelete,
  setCloseConfirmModal,
}) {
  if (reportsToDisplay?.length === 0)
    return (
      <div className={styles.no_reports_msg}>
        You haven't set up any reports yet
      </div>
    );

  return (
    <>
      {reportsToDisplay.map((one_report, index) => (
        <Report
          key={index}
          report={one_report}
          server={server}
          onMobile={true}
          setReportToDelete={setReportToDelete}
          setCloseConfirmModal={setCloseConfirmModal}
        />
      ))}
    </>
  );
}

export default MobileReportsTable;
