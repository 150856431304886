import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import FormError from "../../Components/FormError";
import CTAButton from "../../Components/CTAButton";
import Select from "react-select"; // https://react-select.com/home#getting-started
// icons
import { CloseIcon } from "../../Styles/Icons";
import { CalendarIcon } from "../../Styles/Icons/DesignSystem";
// utils
import { checkDOB, reverseDate, sortHigh } from "@intelligentlilli/lilli-utils";

// n.b. we store dates in the iso format: YYYY-MM-DD (ISO standard for dates).
// However we display dates in the DD-MM-YYYY format in the UK therefore the order needs to be reversed when displaying dates

const EditSUProfileForm = ({
  staff,
  onSubmit,
  dateOfBirth,
  phoneNumber,
  careType,
  conditions,
  nextOfKin,
  doctor,
  error,
  careId,
  viewers,
}) => {
  let staffToSelect = sortHigh(
    staff?.map((staffMember) => ({
      ...staffMember,
      value: `${staffMember?.forenames} ${staffMember?.surname}`,
      label: `${staffMember?.forenames} ${staffMember?.surname}`,
    })),
    "label"
  );

  const initialAssociations = viewers?.map((viewer) => ({
    ...viewer,
    value: `${viewer?.forenames} ${viewer?.surname}`,
    label: `${viewer?.forenames} ${viewer?.surname}`,
  }));

  // This is the custom style for the select component
  const customSelectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fafafa",
      borderRadius: 2,
      border: `1px solid ${theme.neutral2}`,
      padding: "2px 0",
    }),
  };

  return (
    <Form
      onSubmit={(values) =>
        onSubmit({
          dateOfBirth: reverseDate(values.dateOfBirth),
          phone: values.phone,
          careType: values.careType,
          conditions: values.conditions,
          nextOfKin: values.nextOfKin,
          doctor: values.doctor,
          carerAssignment: values.carerAssignment,
          careId: values.careId,
        })
      }
      validate={(values) => {
        const errors = {};
        if (values.dateOfBirth?.length > 0) {
          if (!checkDOB(values.dateOfBirth)) {
            errors.dateOfBirth = true;
          } else {
            delete errors.dateOfBirth;
          }
        }
        // Create a regex to ensure the date is in the format DD-MM-YYYY
        const dateRegex = new RegExp(
          /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-(19|20)\d\d$/
        );
        const dateIsValid = dateRegex.test(values.dateOfBirth);
        if (!dateIsValid) {
          errors.dateOfBirth = "Invalid date: use DD-MM-YYYY";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, valid, form, values }) => {
        const hasFormChanged = () => {
          return (
            values.dateOfBirth !== reverseDate(dateOfBirth) ||
            values.phone !== phoneNumber ||
            values.careId !== careId ||
            values.careType !== careType ||
            values.conditions !== conditions ||
            values.nextOfKin !== nextOfKin ||
            values.doctor !== doctor ||
            values.carerAssignment !== initialAssociations
          );
        };
        return (
          <form
            className={styles.form}
            onSubmit={(event) => {
              handleSubmit(event);
            }}
          >
            <div className={styles.form_fields}>
              <Field name="dateOfBirth" initialValue={reverseDate(dateOfBirth)}>
                {({ input, meta }) => {
                  return (
                    <div className={styles.field}>
                      <div className={styles.label}>Date of birth</div>
                      <div className={styles.field_dob}>
                        <CalendarIcon />
                        <input
                          className={styles.input_dob}
                          {...input}
                          type="text"
                          placeholder="DD-MM-YYYY"
                          maxlength="10"
                          onChange={(event) => {
                            const newValue = event.target.value;

                            if (
                              (newValue.length === 2 ||
                                newValue.length === 5) &&
                              input.value[input.value.length - 1] !== "-"
                            ) {
                              form.change(
                                "dateOfBirth",
                                `${event.target.value}-`
                              );
                            } else if (
                              (newValue.length === 2 ||
                                newValue.length === 5) &&
                              input.value[input.value.length - 1] === "-"
                            ) {
                              form.change(
                                "dateOfBirth",
                                `${event.target.value.slice(0, -1)}`
                              );
                            } else {
                              form.change("dateOfBirth", event.target.value); // Use form.change instead of change
                            }
                          }}
                        />
                      </div>

                      {meta.error && meta.touched && (
                        <span className={styles.error}>{meta.error}</span>
                      )}
                    </div>
                  );
                }}
              </Field>
              <Field name="phone" initialValue={phoneNumber}>
                {({ input, meta }) => (
                  <div className={styles.field}>
                    <div className={styles.label}>Phone number</div>
                    <input className={styles.input} {...input} type="text" />

                    {meta.error && meta.touched && (
                      <span className={styles.error}>{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
              <Field name="careId" initialValue={careId}>
                {({ input, meta }) => (
                  <div className={styles.field}>
                    <div className={styles.label}>Care ID</div>
                    <input className={styles.input} {...input} type="text" />

                    {meta.error && meta.touched && (
                      <span className={styles.error}>{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
              <Field name="careType" initialValue={careType}>
                {({ input, meta }) => (
                  <div className={styles.field}>
                    <div className={styles.label}>Care Type</div>
                    <input className={styles.input} {...input} type="text" />
                    <span className={styles.tip}>
                      Separate conditions with commas
                    </span>
                    {meta.error && meta.touched && (
                      <span className={styles.error}>{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
              <Field name="conditions" initialValue={conditions}>
                {({ input, meta }) => (
                  <div className={styles.field}>
                    <div className={styles.label}>Conditions</div>
                    <input className={styles.input} {...input} type="text" />
                    <span className={styles.tip}>
                      Separate conditions with commas
                    </span>
                    {meta.error && meta.touched && (
                      <span className={styles.error}>{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
              <Field name="nextOfKin" initialValue={nextOfKin}>
                {({ input, meta }) => (
                  <div className={styles.field}>
                    <div className={styles.label}>Next of kin</div>
                    <input className={styles.input} {...input} type="text" />
                    {meta.error && meta.touched && (
                      <span className={styles.error}>{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
              <Field name="doctor" initialValue={doctor}>
                {({ input, meta }) => {
                  return (
                    <div className={styles.field}>
                      <div>Doctor</div>
                      <input className={styles.input} {...input} type="text" />
                      {meta.error && meta.touched && (
                        <span className={styles.error}>{meta.error}</span>
                      )}
                    </div>
                  );
                }}
              </Field>
              <Field name="carerAssignment">
                {({ input, meta }) => {
                  // Only show staff who have not been assigned in the list
                  const staffNotYetSelected = staffToSelect.filter(
                    (staffMember) =>
                      !(input.value || []).find(
                        (staff) => staff.id === staffMember.id
                      )
                  );
                  return (
                    <div className={styles.field}>
                      <div className={styles.label}>Carer assignment</div>
                      <Select
                        placeholder="Select carer name"
                        options={staffNotYetSelected}
                        name="serviceUsers"
                        onChange={(change) => {
                          // Take the new staff member and add them to the array of staff members assigned
                          let newStaff = [];
                          if (meta.modified && Array.isArray(input.value)) {
                            newStaff = [...input.value];
                          }
                          if (!meta.modified) {
                            newStaff = [...initialAssociations];
                          }
                          newStaff.push(change);
                          form.change("carerAssignment", newStaff);
                        }}
                        styles={customSelectStyle}
                        value={[]}
                        isClearable
                        isSearchable
                        menuPlacement={"top"}
                        components={{
                          IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
                        }}
                      />
                      {/* render the list of staff members assigned */}
                      <div className={styles.users}>
                        {meta.modified &&
                          input?.value?.length > 0 &&
                          input?.value?.map((user, index) => {
                            return (
                              <div className={styles.user} key={index}>
                                <CloseIcon
                                  className={styles.deleteStaff}
                                  onClick={() => {
                                    const newStaff = [...input.value];
                                    newStaff.splice(index, 1);
                                    form.change("carerAssignment", newStaff);
                                  }}
                                />
                                <span>{user?.value}</span>
                              </div>
                            );
                          })}
                        {!meta.modified &&
                          initialAssociations?.map((user, index) => {
                            return (
                              <div className={styles.user} key={index}>
                                <CloseIcon
                                  className={styles.deleteStaff}
                                  onClick={() => {
                                    const newStaff = [...initialAssociations];
                                    newStaff.splice(index, 1);
                                    form.change("carerAssignment", newStaff);
                                  }}
                                />
                                <span>{user?.value}</span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                }}
              </Field>
            </div>
            {error && <FormError error={error} />}
            <CTAButton
              type="submit"
              disabled={submitting || !valid || !hasFormChanged()}
              className={styles.submit}
              cypresstestid="Edit-SU-Profile-Btn-Submit"
            >
              Save
            </CTAButton>
          </form>
        );
      }}
    />
  );
};

export default EditSUProfileForm;
