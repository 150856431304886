import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
// State
import { useSelector, useDispatch } from "react-redux";
import { updateSensor } from "../../../State/slices/sensors-slice";
// Hooks
import { useOpenHubPairing, useGetSensorStatus } from "../../../Services/Hooks";
// Components
import InstallSensor from "../../../Components/InstallSensor";
import InstallationInstructions from "../../../Components/InstallationInstructions";
import InstallationHint from "../../../Components/InstallationHint";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallSensorLocation from "../../../Components/InstallSensorLocation";
import InstallationButtons from "../../../Components/InstallationButtons";
import InstallTroubleshooting from "../../../Components/InstallTroubleshooting";

const InstallDoor = () => {
  const navigate = useNavigate();
  const { devicestring, id, name } = useParams(); // Get the service user id from the url parameter

  // sizing
  const { width } = useViewport();
  const isDesktop = width > 600;

  // Trigger the enter hub pairing mode
  const secondsUntilNextRepair = 120000; // 2 minutes
  useOpenHubPairing({
    hubId: id,
    installationComplete: false,
    frequencyOfRepairing: secondsUntilNextRepair,
  });

  // Trigger the sensor status fetching
  const { loading, fetchSensorStatus } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: false,
  });

  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const selectedSuObject = serviceUsers.filter((i) => i.id === parseInt(id));
  const selectedSUName = selectedSuObject?.[0]?.userstring || "";

  // redux state
  const dispatch = useDispatch();

  // sensor location
  const isKitchen = name.toLowerCase() === "kitchen";

  // Get the correct sensor
  const sensors = useSelector((state) => state.sensors);
  const sensorIndex = sensors?.devices?.findIndex(
    (device) =>
      device.name === (isKitchen ? "Kitchen/Fridge/Door" : "Hall/Main/Door")
  );
  const foundSensor = sensorIndex >= 0;

  const sensor = foundSensor && sensors?.devices?.[sensorIndex];

  // If the installer is returning to this page and has already chosen a location get it here
  const sensorLocations = useSelector((state) => state.sensorLocations);
  const sensorLocation = sensorLocations?.[sensor?.name];

  // Keep track of which pages the user has visited
  useEffect(() => {
    foundSensor &&
      dispatch(
        updateSensor({
          index: sensorIndex,
          key: "checkedConnection",
          value: true,
        })
      );
  }, [dispatch, sensorIndex, foundSensor]);

  // hints
  const installDoorHint = [
    "This sensor is to be placed on the door that the Lilli user uses the most to enter and exit the property day-to-day.",
  ];
  const installFridgeHint = [
    "This sensor is intended for the fridge door. If the fridge door isn’t possible the sensor can be placed on a cupboard door so it can capture eating events.",
    "Optimal location is towards the top of the door to limit interference.",
  ];
  const hints = isKitchen ? installFridgeHint : installDoorHint;

  // images of the sensor
  const imageLocations = {
    mainImage: "/images/installation/sensors/door/door-labeled.png",
    doorOpen: "/images/installation/sensors/door/door-open.png",
    activeDoorSensor: "/images/installation/sensors/door/door-active.png",
  };
  const images = [
    {
      image: imageLocations["mainImage"],
      altText:
        name === "Hall"
          ? "Lilli door sensor for the hallway"
          : "Lilli fridge sensor",
      className: undefined,
    },
    {
      image: imageLocations["doorOpen"],
      altText: "A picture showing the door or fridge sensor being opened.",
      className: undefined,
    },
    {
      image: imageLocations["activeDoorSensor"],
      altText: "A picture of an active door or fridge sensor.",
      className: undefined,
    },
  ];

  // installation instructions
  const instructions = [
    {
      text: "Open the casing of the sensor by pushing the fastening on the top of the device to remove the front cover from the base.",
      images: [
        {
          src: imageLocations["doorOpen"],
          alt: "Door sensor open",
          width: "25%",
        },
      ],
    },
    {
      text: "Insert the enclosed batteries into the device and close the casing.",
    },
    {
      text: "Remove the backing paper of sensor and magnet to expose the adhesive strip",
    },
    {
      text: "Stick the magnet to the very edge of the door and the sensor to the door frame, lining the magnet up no more than half a cm apart from the small triangle on one side of the sensor.",
      notes: [
        "When the door opens and closes, the sensor and magnet must separate.",
      ],
    },
    {
      text: "To test the positioning of the sensor and magnet check whether the green light on the sensor flashes when you open or close the door.",
      images: [
        {
          src: imageLocations["activeDoorSensor"],
          alt: "Door sensor active",
          width: "25%",
        },
      ],
    },
  ];

  return (
    <InstallSensor
      sensor={sensor}
      userstring={selectedSUName}
      devicestring={devicestring}
      loading={loading}
      testConnection={fetchSensorStatus}
      hints={hints}
      locationSelected={!!sensorLocation && sensor.locationRequired}
      cypresstestid={"Installation-Door-Sensor"}
    >
      <div className={styles.door_sensor_top}>
        <p style={{ marginTop: 24 }}>
          The door sensor consists of two parts - a sensor and a magnet.
        </p>
        {/* Main image */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={images[0].image}
            className={images[0].className}
            alt={images[0].altText}
            style={{ width: "75%" }}
          />
        </div>
        {width <= 900 && <InstallationHint hints={hints} />}
        <InstallationInstructions instructions={instructions} />
        <InstallTroubleshooting
          imageLocation={imageLocations.activeDoorSensor}
        />
        <InstallSensorLocation sensor={sensor} />
      </div>
      <InstallationButtons
        loading={loading}
        goBack={() => navigate(-1)}
        isDesktop={isDesktop}
        sensor={sensor}
        sensorLocation={sensorLocation}
        fetchSensorStatus={fetchSensorStatus}
        id={id}
      />
    </InstallSensor>
  );
};

export default InstallDoor;
