import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
// API
import getUser from "../../Services/Cache/Users/getUser";
import deleteUserHubAssociation from "../../Services/Cache/Users/deleteUserHubAssociation";
import postUserHubAssociation from "../../Services/Cache/Users/postUserHubAssociation";
import getUserHubs from "../../Services/Cache/Users/getUserHubs";
import getUserProperties from "../../Services/Cache/Users/getUserProperties";
// import postUserRoles from "../../Services/Cache/Users/postUserRoles";
// State
import { useSelector, useDispatch } from "react-redux";
import {
  setLoading,
  setRefreshUser,
  setRefreshStaff,
  setRefreshOrganisationHubs,
} from "../../State/slices/session-slice";
// Components
import Page from "../../Components/Page";
import StaffInfo from "../../Components/StaffInfo";
import ServiceUserCard from "../../Components/ServiceUserCard";
import PrimaryButton from "../../Components/PrimaryButton";
import AssignServiceUser from "../../Components/AssignServiceUser";
// Hooks
import { useGetUserRole } from "../../Services/Hooks";
// Utils
import { fromUnixTime, formatDistance } from "date-fns";
import {
  getUserRoleAsString,
  getUserHasInstallerRole,
  getIsUserJustAnInstaller,
} from "../../Services/Utils";

const StaffMember = () => {
  // Get the staff members user id from the url parameter
  const { staffMemberId } = useParams();
  const navigate = useNavigate();

  // User's permissions (whether or not they can remove SUs from staff)
  const { userIsManagerOrAdmin } = useGetUserRole();

  // redux state
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const server = useSelector((state) => state.session.server);
  const serviceUsersData = useSelector((state) => state.serviceUsersData);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  // Local state
  const [staffMember, setStaffMember] = useState();
  const [staffMemberPhoto, setStaffMemberPhoto] = useState();
  const [staffMemberHubs, setStaffMemberHubs] = useState([]);
  const [overlappingHubs, setOverlappingHubs] = useState([]);
  const [staffMemberProperties, setStaffMemberProperties] = useState();
  const [addServiceUserAssociation, setAddServiceUserAssociation] =
    useState(false);
  const [fetchStaffMember, setFetchStaffMember] = useState(true);

  // is this you
  const thisIsYou = staffMemberId === user?.key;

  // If the user is already on the StaffMember page but routes to another staff member through search allow another data fetch
  useEffect(() => {
    setFetchStaffMember(true);
  }, [staffMemberId]);

  // Get request for the staff members data and photo
  useEffect(() => {
    if (fetchStaffMember) {
      dispatch(setLoading(true));
      // Refresh local state when switching between staff members without leaving this page
      setStaffMember(null);
      setStaffMemberPhoto(null);
      setOverlappingHubs([]);
      setStaffMemberHubs([]);
      setStaffMemberProperties(null);

      async function fetchData() {
        // Wait for both the getUser and getUserHubs API calls to finish
        await Promise.all([
          getUser(server, staffMemberId).then(({ body }) =>
            setStaffMember(body)
          ),
          getUserHubs(server, staffMemberId).then(({ body }) => {
            const overlap = body?.filter((staffSU) =>
              serviceUsers.find((su) => su?.id === staffSU?.id)
            );
            setStaffMemberHubs(body);
            setOverlappingHubs(
              userIsManagerOrAdmin || thisIsYou ? body : overlap
            );
          }),
          getUserProperties(server, staffMemberId).then(({ body }) =>
            setStaffMemberProperties(body)
          ),
        ])
          .then(() => {
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
      fetchData();
      setFetchStaffMember(false);
    }
  }, [
    dispatch,
    serviceUsers,
    thisIsYou,
    userIsManagerOrAdmin,
    staffMemberId,
    server,
    fetchStaffMember,
  ]);

  // Handle removing a service user from the care of a user
  const removeServiceUserFromUser = (serviceUserId) => {
    dispatch(setLoading(true));
    deleteUserHubAssociation(server, staffMember?.id, serviceUserId)
      .then((res) => {
        if (res.ok) {
          // Refresh the staff member so state represents the server again.
          setFetchStaffMember(true);
          // If the staff member is the current user also need to refresh the user
          if (staffMember?.id === user?.key) {
            dispatch(setRefreshUser(true));
          }
          // Refresh organisation staff so that /staff page is updated
          dispatch(setRefreshStaff(true));
          // Also have to refresh the organisation hubs as the lilli-users page relies on it to filter service users by staff member
          dispatch(setRefreshOrganisationHubs(true));
        } else {
          // TODO handle further error codes
          if (res.status === 401) {
            navigate("/login");
          } else {
            alert(
              "Sorry, there's been a problem in removing this service user from this member of staff."
            );
          }
        }
      })
      .catch((err) => {
        // TODO handle error beyond the simple alert?
        console.log("This is the error", err);
        alert("Error fetching user data");
      })
      .finally((_) => {
        dispatch(setLoading(false));
      });
  };

  // Assign service user to the staff member
  const assignServiceUser = (serviceUserId) => {
    setAddServiceUserAssociation(false);
    dispatch(setLoading(true));
    postUserHubAssociation(server, staffMember?.id, serviceUserId)
      .then((res) => {
        if (res.ok) {
          // Refresh the staff member so state represents the server again.
          setFetchStaffMember(true);
          // If the staff member is the current user also need to refresh the user
          if (staffMember?.id === user?.key) {
            dispatch(setRefreshUser(true));
          }
          // Refresh organisation staff so that /staff page is updated
          dispatch(setRefreshStaff(true));
          // Also have to refresh the organisation hubs as the lilli-users page relies on it to filter service users by staff member
          dispatch(setRefreshOrganisationHubs(true));
        } else {
          // TODO handle further error codes
          if (res.status === 401) {
            navigate("/login");
          } else {
            alert("Error making association");
          }
        }
      })
      .catch((err) => {
        // TODO handle error beyond the simple alert?
        console.log("This is the error", err);
        alert("Error fetching user data");
      })
      .finally((_) => {
        dispatch(setLoading(false));
      });
  };

  // // Handle adding the installer role to a staff member
  // const assignRole = (role) => {
  //   console.log("assignRole:", role);
  //   dispatch(setLoading(true));
  //   postUserRoles(server, staffMember?.id, role)
  //     .then((res) => {
  //       if (res.ok) {
  //         // Refresh the staff member so state represents the server again.
  //         setFetchStaffMember(true);
  //         // Refresh organisation staff so that /staff page is updated
  //         dispatch(setRefreshStaff(true));
  //       } else {
  //         // TODO handle further error codes
  //         if (res.status === 401) {
  //           navigate("/login");
  //         } else {
  //           alert("Sorry, there was an error assigning this role to this user");
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       // TODO handle error beyond the simple alert?
  //       console.log("This is the error", err);
  //       alert("Error fetching user data");
  //     })
  //     .finally((_) => {
  //       dispatch(setLoading(false));
  //     });
  // };

  // Filter out service users the user is already associated with or those that are awaiting installation
  const serviceUsersAvailable = serviceUsers?.filter((serviceUser) => {
    let alreadyAssociated = false;
    overlappingHubs?.forEach((hub) => {
      if (hub.id === serviceUser.id) {
        alreadyAssociated = true;
      }
    });
    return !alreadyAssociated;
  });

  // staff member's roles
  const name = staffMember?.forenames ?? staffMember?.username;
  const staffRoleAsString = getUserRoleAsString(staffMember?.roles);
  const staffHasInstallerRole = getUserHasInstallerRole(staffMember?.roles);
  const staffIsJustInstaller = getIsUserJustAnInstaller(staffMember?.roles);
  const displayStaffRole = staffIsJustInstaller
    ? "Installer"
    : `${staffRoleAsString}${staffHasInstallerRole ? " & Installer" : ""}`;

  // number of staff service users
  const totalUsersAssignedToStaff = thisIsYou
    ? `You have ${staffMemberHubs?.length} users assigned to you`
    : userIsManagerOrAdmin
    ? `${name} has ${staffMemberHubs?.length} users`
    : `${name} shares ${overlappingHubs?.length} of ${staffMemberHubs?.length} users with you`;
  // no service users
  const noServiceUsersAssignedMessage = thisIsYou
    ? "You don't have any service users assigned to you"
    : userIsManagerOrAdmin
    ? `${name} does not currently have any service users`
    : `${name} does not have any service users that overlap with you`;

  return (
    <Page className={styles.page}>
      <StaffInfo
        staffMember={staffMember}
        addServiceUser={() => setAddServiceUserAssociation(true)}
        profilePhoto={staffMemberPhoto}
        thisIsYou={thisIsYou}
        userIsManagerOrAdmin={userIsManagerOrAdmin}
      />
      <div className={styles.info}>
        <div>{totalUsersAssignedToStaff}</div>
        <div>
          Last logged in:{" "}
          {staffMemberProperties?.lastActivity &&
            formatDistance(
              fromUnixTime(staffMemberProperties?.lastActivity?.slice(0, -3)),
              new Date()
            )}{" "}
          {staffMemberProperties?.lastActivity ? "ago" : "unknown"}
        </div>
      </div>
      <div className={styles.content}>
        {/* {!staffMember?.roles.includes("lilli.auth/manager") && ( */}
        <>
          <h2>Roles & Permissions</h2>
          <div className={styles.option}>
            <div className={styles.user_roles}>
              {thisIsYou ? "Your" : `${name}'s`} roles: {displayStaffRole}
            </div>
            {/* <PrimaryButton onClick={() => assignRole("installer")}> 
                Make {name} an installer only
              </PrimaryButton>
              <PrimaryButton onClick={() => assignRole("user|installer")}>
                Give {name} the role of staff & installer
              </PrimaryButton> */}
          </div>
        </>
        {/* )} */}

        <h2>Lilli users</h2>
        {/* 
          Staff (i.e. role of "user") can see only overlapping hubs.
          Managers & admin can see ALL of the staff's hubs (in which case 'overlappingHubs' will be all of the staff hubs). 
        */}
        {overlappingHubs?.length === 0 && (
          <div>{noServiceUsersAssignedMessage}</div>
        )}
        {overlappingHubs?.map((serviceUser, index) => (
          <div className={styles.serviceUser} key={index}>
            <ServiceUserCard
              key={index}
              onClick={() => navigate(`/lilli-users/${serviceUser?.id}`)}
              // Getting the service user from state as that one has the photo whereas the one from staffMemberHubs wont
              hub={serviceUsers?.find(
                (yourServiceUser) => yourServiceUser?.id === serviceUser?.id
              )}
              hubData={serviceUsersData?.[serviceUser?.id]}
            />
            {userIsManagerOrAdmin && (
              <PrimaryButton
                onClick={() => removeServiceUserFromUser(serviceUser?.id)}
                cypresstestid="remove_this_service_user_from_staff"
              >
                Remove this service user from {thisIsYou ? "me" : name}
              </PrimaryButton>
            )}
          </div>
        ))}

        <AssignServiceUser
          serviceUsersAvailable={serviceUsersAvailable}
          addServiceUserAssociation={addServiceUserAssociation}
          assignServiceUser={assignServiceUser}
          setAddServiceUserAssociation={setAddServiceUserAssociation}
          serviceUsersData={serviceUsersData}
        />
      </div>
    </Page>
  );
};

export default StaffMember;
