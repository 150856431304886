import { getOrganisation as getOrganisationAPI } from "@intelligentlilli/api-layer";

const getOrganisation = async (server, id) => {
  try {
    const response = await getOrganisationAPI(server, id, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getOrganisation;
