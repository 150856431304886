import { postHubsRiskScores as postHubsRiskScoresAPI } from "@intelligentlilli/api-layer";

const postHubRiskScores = async (server, serviceUserIds) => {
  try {
    const response = await postHubsRiskScoresAPI(server, serviceUserIds, "web");
    return response;
  } catch (error) {
    return error;
  }
};

export default postHubRiskScores;
