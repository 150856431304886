import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// Hooks
import { useReportsHook } from "../../Services/Hooks";
// Services
import { parseISO, addDays, format, subDays } from "date-fns";
// Utils
import {
  sortByObjectProperty,
  capitalize,
} from "@intelligentlilli/lilli-utils";
import {
  getArrayOfReportOptions,
  updateOptions,
  removeUnderscore,
} from "../../Services/Utils";
// Components
import Select from "react-select"; // https://react-select.com/home#getting-started
import { useViewport } from "../../Components/ViewportProvider";
import CTAButton from "../../Components/CTAButton";
import DatePicker from "../DatePicker";

const NewReport = ({ reportTypes, serviceUsers, closeReport, from, to }) => {
  const navigate = useNavigate();
  const server = useSelector((state) => state.session.server);
  const { id } = useParams(); // Get the service user id from the url parameter
  const creatingReportOnSUPage = id !== undefined;
  const selectedReport = reportTypes?.[0]; // -- For V1 Dorset Release - only return 1 report: 'General Report'
  // responsiveness
  const { width } = useViewport();
  const isMobile = width <= 720;

  const { createReport } = useReportsHook(server, navigate);

  // form values
  const initialFormValues = {
    options: selectedReport?.options.map((item) => {
      let newObj = { [item]: true };
      if (item === "temperature") {
        newObj = { [item]: false };
        return newObj;
      }
      return newObj;
    }) || [
      { sustenance: true },
      { movement: true },
      { independence: true },
      { nighttime_activity: true },
      { temperature: false },
      { bathroom_activity: false },
    ],
    reportStartDate: "",
    numberOfDays: "", // days,
    reportName: "",
    reportType: selectedReport?.id || "general-report",
    // serviceUsers: selectedReport.serviceuserids[0] || selectedReport.serviceuserids,
    serviceUsers: creatingReportOnSUPage ? { value: parseInt(id) } : "",
  };

  // add "Bathroom Acitivity" to the options array
  if (selectedReport?.id === "general-report") {
    initialFormValues.options.push({ bathroom_activity: false });
  }

  // local state
  const [form, setForm] = useState(initialFormValues);
  const [reportEndDate, setReportEndDate] = useState("");
  const [clickedSubmit, setClickedSubmit] = useState(false);

  const toggleCheckBox = (e) => {
    var { name, value } = e.target;
    value === "true" ? (value = false) : (value = true);
    setForm({ ...form, options: updateOptions(form.options, name, value) });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  /*  Creating a seperate handler for updating the report end date as it is not part of the API response, therefore would get complicated 
  if it was part of the main form. It may be that this is not required if the backend implementation changes */
  const onChangeEndDate = (e) => {
    const { value } = e.target;
    setReportEndDate(value);
  };

  const handleSelectChange = (name) => (value) => {
    setForm({ ...form, [name]: value });
  };

  // Date range min and max values
  const startMinDate = reportEndDate
    ? format(subDays(parseISO(reportEndDate), 30), "yyyy-MM-dd")
    : null;
  const startMaxDate = reportEndDate
    ? format(subDays(parseISO(reportEndDate), 6), "yyyy-MM-dd")
    : null;
  // Minimum end date to be 7 days after the start date. TODO: also link this to user's device installation date?
  const endMinDate = form?.reportStartDate
    ? format(addDays(parseISO(form?.reportStartDate), 6), "yyyy-MM-dd")
    : null;
  // Maximum end date to be 30 days after the start date. TODO: also link this to user's device installation date?
  const endMaxDate = form?.reportStartDate
    ? format(addDays(parseISO(form?.reportStartDate), 30), "yyyy-MM-dd")
    : null;

  // Form validation
  const reportMessage = !form?.reportName ? "Give this report a name" : "";
  const selectUserMessage = !form?.serviceUsers ? "Select an individual" : "";
  const startDateMessage = !form?.reportStartDate ? "Select a start date" : "";
  const endDateMessage = !reportEndDate ? "Select an end date" : "";
  const optionsMessage =
    getArrayOfReportOptions(form.options).length === 0
      ? "Select at least one behaviour"
      : "";

  const formIsInvalid =
    !form?.reportName ||
    !form?.serviceUsers ||
    !form?.reportStartDate ||
    !reportEndDate ||
    getArrayOfReportOptions(form.options).length === 0;

  const hasNoDate = !form?.reportStartDate || !reportEndDate;

  const onSubmit = (event) => {
    setClickedSubmit(true);
    event.preventDefault();
    if (!formIsInvalid) {
      createReport(form, reportEndDate, creatingReportOnSUPage);
      closeReport();
    }
  };

  // Custom style for the select component
  const customSelectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme.neutral1,
      borderRadius: 2,
      border: `1px solid ${theme.neutral2}`,
      padding: "2px 0",
      color: `${theme.neutral7}`,
    }),
    // controls the height of the drop-down list
    menuList: (styles) => {
      return {
        ...styles,
        maxHeight: 200,
      };
    },
  };

  // -- DATA MANIPULATIONS
  // Note: only shows the service users who have devices installed.
  const suPostInstallation = serviceUsers
    ?.filter(
      (user) => user?.serviceUserProperties?.installationStatus === "complete"
    )
    .reduce((acc, item) => {
      var newObj = {
        name: item?.userstring,
        value: item?.id,
        label: item?.userstring,
      }; // "value & label" are required properties for the 'react-select' library
      return acc.concat(newObj);
    }, []);
  sortByObjectProperty(suPostInstallation, "userstring"); // sort service users alphabetically

  return (
    <form className={styles.form}>
      <div className={styles.first_row}>
        <div className={styles.field}>
          <label htmlFor="reportName">Report Name</label>
          <input
            className={`${styles.report_name} fs-mask`}
            name="reportName"
            value={form?.reportName}
            onChange={onInputChange}
            type="text"
          />
          <div id={styles.error_message}>{clickedSubmit && reportMessage}</div>
        </div>

        <div className={`${styles.field} fs-mask`}>
          <label htmlFor="serviceUsers" className={styles.main_label}>
            Select an individual:
          </label>
          <Select
            placeholder="Select an individual"
            options={suPostInstallation}
            name="serviceUsers"
            value={
              creatingReportOnSUPage
                ? suPostInstallation[0]
                : form?.serviceUsers
            }
            onChange={handleSelectChange("serviceUsers")}
            isClearable={creatingReportOnSUPage ? false : true}
            isSearchable={creatingReportOnSUPage ? false : true}
            menuPlacement={"bottom"}
            styles={customSelectStyle}
            components={{
              IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
            }}
          />
          <div id={styles.error_message}>
            {clickedSubmit && selectUserMessage}
          </div>
        </div>
      </div>

      <div className={styles.date_range}>
        <div className={styles.field}>
          <div>{isMobile ? "Start date:" : "Period of time:"}</div>
          <DatePicker
            name="reportStartDate"
            min={startMinDate}
            max={startMaxDate}
            value={form?.reportStartDate}
            onChange={onInputChange}
          />
          <div id={styles.error_message}>
            {clickedSubmit && startDateMessage}
          </div>
        </div>

        {!isMobile && (
          <div className={styles.hyphen_group}>
            <div
              className={
                clickedSubmit && hasNoDate
                  ? styles.hyphen_error
                  : styles.hyphen_no_error
              }
            >
              -
            </div>
          </div>
        )}

        <div className={styles.field}>
          <div className={`${isMobile ? undefined : styles.invisible}`}>
            {isMobile ? "End date:" : "Period of time:"}
          </div>
          <DatePicker
            name="reportEndDate"
            min={endMinDate}
            max={endMaxDate}
            value={reportEndDate}
            onChange={onChangeEndDate}
          />
          <div id={styles.error_message}>{clickedSubmit && endDateMessage}</div>
        </div>
      </div>
      <div className={styles.report_period_explanation}>
        Reports can only cover up to 31 days
      </div>

      <div className={styles.behaviour_title}>Behaviours:</div>

      <div className={styles.behaviours_group}>
        {form?.options?.map((option, index) => {
          const behaviourName = Object.keys(option).join("");
          const behaviourValue = Object.values(option).join("");
          const behaviourLabel =
            behaviourName === "sustenance"
              ? "Eating & drinking"
              : behaviourName === "independence"
              ? "Time outside"
              : removeUnderscore(capitalize(behaviourName));
          const isDisabled =
            behaviourName === "temperature" ||
            behaviourName === "bathroom_activity";
          return (
            <div
              className={
                isDisabled
                  ? styles.disabled_behaviour_option
                  : styles.behaviour_option
              }
              key={`${behaviourName} ${index}`}
            >
              <input
                type="checkbox"
                id={behaviourName} // => { 'sustenance' : true } => ['sustenance'] => 'sustenance'
                name={behaviourName}
                checked={behaviourValue === "true"} // => { 'sustenance' : true } => [true] => "true" => true / false (boolean)
                value={behaviourValue}
                onChange={toggleCheckBox}
                disabled={isDisabled}
              ></input>
              <label htmlFor={behaviourName}>
                {behaviourLabel === "Nighttime activity"
                  ? "Night-time activity"
                  : behaviourLabel}
              </label>
            </div>
          );
        })}
      </div>
      <div className={styles.coming_soon_container}>
        <div className={styles.coming_soon_header}>Coming soon</div>
        <div className={styles.coming_soon_text}>
          <span className={styles.medium_bold}>Bathroom activity</span> and{" "}
          <span className={styles.medium_bold}>Temperature</span> are currently
          unavailable while we update our reporting feature.{" "}
        </div>
        <div className={styles.coming_soon_text}>
          Movement and Night-time activity data is based on the old analysis and
          will be updated to match the new Day-time and Night-time behaviours
          with the upcoming release.
        </div>
      </div>
      <div id={styles.error_message}>{clickedSubmit && optionsMessage}</div>
      <CTAButton
        type="submit"
        className={styles.submit_button}
        onClick={onSubmit}
        cypresstestid="Create Report"
        id="Create Report"
      >
        Create
      </CTAButton>
    </form>
  );
};

export default NewReport;
