import { getHubMovementAnalysisV3 as getHubMovementAnalysisAPI } from "@intelligentlilli/api-layer";

const getHubMovementAnalysisV3 = async (server, id, startTime, endTime) => {
  try {
    const response = await getHubMovementAnalysisAPI(
      server,
      id,
      startTime,
      endTime,
      "web"
    );
    if (response.ok) {
      return response.body;
    } else {
      return null;
    }
  } catch (err) {
    console.log(`Error fetching data for movement:`, err);
    return err;
  }
};

export default getHubMovementAnalysisV3;
