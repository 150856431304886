import { getHubSustenanceAnalysisV2 as getHubSustenanceAnalysisV2API } from "@intelligentlilli/api-layer";

const getHubSustenanceAnalysisV2 = async (server, id, days, endTime) => {
  try {
    const response = await getHubSustenanceAnalysisV2API(
      server,
      id,
      days,
      endTime,
      "web"
    );
    if (response.ok) {
      return response.body;
    } else {
      return null;
    }
  } catch (err) {
    console.log(`Error fetching data for sustenance:`, err);
    return err;
  }
};

export default getHubSustenanceAnalysisV2;
