import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// Icons
import { CloseIcon, SuccessTickIcon } from "../../Styles/Icons/DesignSystem";
// Components
import CTAButton from "../../Components/CTAButton";

const CreateStaffSplash = ({
  onSubmit,
  staff,
  serviceUsersAssigned,
  isSuccess,
  typeOfUser,
}) => {
  const navigate = useNavigate();
  const title = isSuccess
    ? `${typeOfUser} created`
    : "Sorry, there's been a problem.";
  const error = `We could not create this ${typeOfUser}`;

  return (
    <div className={styles.splash_container}>
      {isSuccess ? (
        <SuccessTickIcon color={theme.success2} width={30} />
      ) : (
        <CloseIcon color={theme.error2} width={25} />
      )}

      <div className={styles.staff_splash_title}>{title}</div>

      {isSuccess && !serviceUsersAssigned && (
        <div className={styles.staff_splash_message}>
          Staff member{" "}
          <span
            className={styles.clickable_name}
            onClick={() => navigate(`/staff/${staff?.id}`)}
          >
            {staff?.name}
          </span>{" "}
          has been created.
        </div>
      )}

      {isSuccess && serviceUsersAssigned && (
        <div className={styles.staff_splash_message}>
          Staff member{" "}
          {
            <span
              className={styles.clickable_name}
              onClick={() => navigate(`/staff/${staff?.id}`)}
            >
              {staff?.name}
            </span>
          }{" "}
          has been created and has been assigned the following user
          {serviceUsersAssigned.length > 1 ? "s" : ""}:{" "}
          {serviceUsersAssigned.map((su, index) => {
            // this first if statement does not put a comma after their name
            // when there is only 1 service user assigned to them
            // or when the service user is the last one in the list
            if (index === serviceUsersAssigned.length - 1) {
              return (
                <span
                  key={index}
                  onClick={() => navigate(`/lilli-users/${su?.id}`)}
                  className={styles.clickable_name}
                >
                  {su?.label}
                </span>
              );
            } // for all other service users, put a comma after their name
            else {
              return (
                <span
                  key={index}
                  onClick={() => navigate(`/lilli-users/${su?.id}`)}
                  className={styles.clickable_name}
                >
                  {su?.label},{" "}
                </span>
              );
            }
          })}
          .
        </div>
      )}

      {!isSuccess && (
        <div className={styles.error_message}>
          {error}{" "}
          <div>
            Please email:{" "}
            <span>
              <a
                href="mailto: support@intelligentlilli.com"
                target="_blank"
                rel="noreferrer"
              >
                support@intelligentlilli.com
              </a>
            </span>
          </div>
        </div>
      )}
      <CTAButton type="submit" className={styles.submit} onClick={onSubmit}>
        Done
      </CTAButton>
    </div>
  );
};

export default CreateStaffSplash;
