import { getOrganisationStaff as getOrganisationStaffAPI } from "@intelligentlilli/api-layer";

const getOrganisationStaff = async (server, id) => {
  try {
    const response = await getOrganisationStaffAPI(server, id, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getOrganisationStaff;
