import React from "react";
import RiskStatusComponent from "../RiskStatus";
import styles from "./styles.module.scss";

const PdfSectionHeading = ({ title, riskLevel }) => {
  return (
    <div className={styles.heading}>
      <div className={styles.heading_text}>{title}</div>
      <RiskStatusComponent status={riskLevel} pdf />
    </div>
  );
};

export default PdfSectionHeading;
