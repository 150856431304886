import React from "react";
import styles from "./styles.module.scss";
import SearchResultUserCard from "../SearchResultUserCard";
import { useFade } from "../../Services/Hooks/useFade";
import { riskStatusContext } from "../../Services/Constants";
import RiskStatusExplainerModal from "../RiskStatusExplainerModal";
import { useViewport } from "../../Components/ViewportProvider";
import {
  AtRiskIcon,
  ExpectedIcon,
  LightBulbIcon,
  UnexpectedIcon,
} from "../../Styles/Icons/DesignSystem";
import theme from "../../Styles/theme.scss";

const SearchResultBucket = ({ title, tip, searchResultsData, numOfSUs }) => {
  const { width } = useViewport();
  // Custom hooks - Modal to fade in and fade out
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)
  // Mobile tip text
  const mobileTip = `Why?`;

  const RiskIcon = () =>
    title === "At risk" ? (
      <AtRiskIcon colour={theme.neutral7} width={12} />
    ) : title === "Unexpected" ? (
      <UnexpectedIcon colour={theme.neutral7} width={12} />
    ) : title === "Expected" ? (
      <ExpectedIcon colour={theme.neutral7} width={12} />
    ) : null;

  return (
    <div className={styles.search_result_bucket_container}>
      <div className={styles.top_bucket}>
        <div className={styles.bucket_title}>
          {RiskIcon() && (
            <div className={styles.icon}>
              <RiskIcon />
            </div>
          )}
          <div className={styles.title}>
            {title} ({numOfSUs})
          </div>
        </div>
        {tip && (
          <div
            onClick={() => setShowAnimation(true)}
            className={styles.bucket_tip}
          >
            <LightBulbIcon colour={theme.brandGreen9} width={12} />
            <div>{width <= 450 ? mobileTip : tip}</div>
          </div>
        )}
      </div>

      <div className={styles.bottom_bucket}>
        {searchResultsData?.map((user) => (
          <SearchResultUserCard key={user.id} user={user} />
        ))}
        {searchResultsData.length === 0 && <div>No results</div>}
      </div>

      {isVisible && tip && (
        <RiskStatusExplainerModal
          hide={() => setShowAnimation(false)}
          riskStatus={title}
          context={riskStatusContext[title]}
          useFade={true}
          showAnimation={showAnimation}
        />
      )}
    </div>
  );
};

export default SearchResultBucket;
