import CTAButton from "../CTAButton";
import theme from "../../Styles/theme.scss";

const InstallationNextButton = ({
  icon,
  label,
  onClick,
  disabled,
  isDesktop,
  type,
  cypresstestid,
}) => {
  return (
    <CTAButton
      style={{
        width: "100%",
        padding: "12px",
        minWidth: `${isDesktop ? "150px" : "100%"}`,
        maxWidth: `${isDesktop ? "150px" : "100%"}`,
        backgroundColor: disabled && theme.neutral2,
      }}
      onClick={onClick}
      disabled={disabled}
      type={type}
      cypresstestid={cypresstestid}
    >
      {icon}
      {label || "Next"}
    </CTAButton>
  );
};

export default InstallationNextButton;
