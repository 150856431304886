import { getHubRiskScoreV2 as getHubRiskScoreAPI } from "@intelligentlilli/api-layer";

const getHubRiskScoreV2 = async (server, id, days, endTime) => {
  try {
    const response = await getHubRiskScoreAPI(server, id, days, endTime, "web");
    return response;
  } catch (error) {
    return error;
  }
};

export default getHubRiskScoreV2;
