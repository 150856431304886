import CTAButton from "../CTAButton";

const InstallationGoBackButton = ({
  label,
  onClick,
  disabled,
  isDesktop,
  textColor,
  marginRight,
  marginTop,
  icon,
}) => {
  return (
    <CTAButton
      style={{
        width: "100%",
        padding: "12px",
        marginRight: `${marginRight ? marginRight : "0px"}`,
        marginTop: `${marginTop ? marginTop : "0px"}`,
        backgroundColor: "white",
        color: `${textColor ? textColor : "#454545"}`,
        fontWeight: `${isDesktop ? "400" : "500"}`,
        borderRadius: "4px",
        border: "1px solid #e8e8e8",
        minWidth: `${isDesktop ? "150px" : "100%"}`,
        maxWidth: `${isDesktop ? "150px" : "100%"}`,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {label || "Go back"}
    </CTAButton>
  );
};

export default InstallationGoBackButton;
