import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import ReportsTable from "../ReportsTable";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Hooks
import { useReportsHook } from "../../Services/Hooks";
// Utils
import { sortHigh, sortLow } from "@intelligentlilli/lilli-utils";
import { useViewport } from "../ViewportProvider";
// Components
import MobileReportsTable from "../MobileReportsTable";
import ConfirmDeleteReportModal from "../ConfirmDeleteReportModal";

const DashboardReports = ({ searchValue }) => {
  const navigate = useNavigate();

  // redux state
  const server = useSelector((state) => state.session.server);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const user = useSelector((state) => state.user);
  // Local state
  const [sortBy, setSortBy] = useState("Created");
  const [sortDirection, setSortDirection] = useState("ascending");
  const [reportsToDisplay, setReportsToDisplay] = useState([]);
  const [reportToDelete, setReportToDelete] = useState(null);

  const { width } = useViewport();
  const onMobile = width < 1370; // Below 1100 the table view no longer compresses so switch to mobile view

  const handleFilterClick = (clickedFilter) => {
    if (sortBy === clickedFilter) {
      setSortDirection((previousSortDirection) =>
        previousSortDirection === "ascending" ? "descending" : "ascending"
      );
    } else {
      setSortBy(clickedFilter);
      setSortDirection("ascending");
    }
  };

  // custom hooks
  const {
    reports,
    deleteSelectedReport,
    closeConfirmModal,
    setCloseConfirmModal,
  } = useReportsHook(server, navigate);

  // When the user searches or sorts update the reports to display
  useEffect(() => {
    let filteredReports = reports;
    // First extract the userstring and convert all report names to lower case as the sorting functions cannot handle a case mix
    filteredReports = filteredReports.map((report) => {
      const userstring = serviceUsers?.find(
        (hub) => hub.id === report.serviceuserids?.[0]
      )?.userstring;
      return {
        ...report,
        nameLower: report.name.toLowerCase(),
        nameArray: report.name.toLowerCase().split(" "),
        userstring: userstring,
        userstringLower: userstring?.toLowerCase(),
        userstringArray: userstring?.toLowerCase().split(" "),
      };
    });

    // This function searches an array of strings and determines whether any of them match the search value
    const searchArray = (array, inputValue, inputLength) => {
      let matched = false;
      array?.forEach((string) => {
        if (string.slice(0, inputLength) === inputValue) {
          matched = true;
        }
      });
      return matched;
    };

    // Apply the search
    if (searchValue.length > 0) {
      const inputValue = searchValue.trim().toLowerCase();
      const inputLength = inputValue.length;
      filteredReports = filteredReports.filter(
        (report) =>
          report.nameLower?.slice(0, inputLength) === inputValue ||
          report.userstringLower?.slice(0, inputLength) === inputValue ||
          searchArray(report.nameArray, inputValue, inputLength) ||
          searchArray(report.userstringArray, inputValue, inputLength)
      );
    }

    // Hold the correct keys for sorting reports given a table column heading
    const sortKeys = {
      Name: "nameLower",
      User: "userstringLower",
      Created: "ts",
      Covers: "days",
    };

    if (sortDirection === "ascending") {
      filteredReports = sortHigh(filteredReports, sortKeys[sortBy]);
    }

    if (sortDirection === "descending") {
      filteredReports = sortLow(filteredReports, sortKeys[sortBy]);
    }

    setReportsToDisplay(filteredReports);
  }, [
    user.key,
    user.organisation,
    server,
    reports,
    searchValue,
    sortBy,
    sortDirection,
    serviceUsers,
  ]);

  return (
    <div className={styles.dashboard_reports_container}>
      <div>
        {!onMobile && (
          <ReportsTable
            isDashboard={true}
            sortBy={sortBy}
            sortDirection={sortDirection}
            handleFilterClick={handleFilterClick}
            reportsToDisplay={reportsToDisplay}
            server={server}
            setReportToDelete={setReportToDelete}
            setCloseConfirmModal={setCloseConfirmModal}
          />
        )}
        {onMobile && (
          <MobileReportsTable
            reportsToDisplay={reportsToDisplay}
            server={server}
            setReportToDelete={setReportToDelete}
            setCloseConfirmModal={setCloseConfirmModal}
          />
        )}
      </div>
      {!closeConfirmModal && (
        <ConfirmDeleteReportModal
          setCloseConfirmModal={setCloseConfirmModal}
          reportToDelete={reportToDelete}
          deleteSelectedReport={deleteSelectedReport}
        />
      )}
    </div>
  );
};

export default DashboardReports;
