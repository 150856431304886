import { getReportTypes as getReportTypesAPI } from "@intelligentlilli/api-layer";

const getReportTypes = async (server) => {
  try {
    const response = await getReportTypesAPI(server, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getReportTypes;
