import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { CloseIcon, SuccessTickIcon } from "../../Styles/Icons/DesignSystem";
import Backdrop from "../Backdrop";

const mobileSortOptions = [
  { mobileName: "Report Name", name: "Name", direction: "high" },
  { mobileName: "Lilli User", name: "User", direction: "high" },
  { mobileName: "Date created", name: "Created", direction: "low" },
  { mobileName: "Report length", name: "Covers", direction: "low" },
];

function MobileSortReportsOverlay({
  setShowMobileSort,
  sortBy,
  handleFilterClick,
}) {
  return (
    <>
      <Backdrop show={true} onClick={() => setShowMobileSort(false)} />
      <div className={styles.sort}>
        <div className={styles.sort_header}>
          <div>Sort by:</div>
          <div onClick={() => setShowMobileSort(false)}>
            <CloseIcon color={theme.neutral4} width={13} />
          </div>
        </div>
        {mobileSortOptions.map((option) => (
          <div
            key={option.name}
            onClick={() => {
              handleFilterClick(option.name);
              setShowMobileSort(false);
            }}
            className={styles.sort_option}
          >
            <div>{option.mobileName}</div>
            {sortBy === option.name && (
              <SuccessTickIcon color={theme.primary6} />
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default MobileSortReportsOverlay;
