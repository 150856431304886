import React from "react";
import theme from "../../theme.scss";

export const RefreshIcon = ({ width, colour }) => {
  return (
    <svg
      width={width || 18}
      height={width || 18}
      viewBox="0 0 18 18"
      id="RefreshIcon"
    >
      <path
        fill={colour || theme.neutral4}
        d="M16.4716 9.65625C16.3269 11.3661 15.6021 12.9752 14.4172 14.2164C13.2324 15.4577 11.6588 16.2565 9.95748 16.4804C8.25618 16.7044 6.52945 16.3399 5.06377 15.4475C3.5981 14.5551 2.48157 13.1885 1.89943 11.5743C1.31728 9.96006 1.30452 8.19533 1.86326 6.57287C2.422 4.95041 3.51865 3.56774 4.97127 2.65426C6.42389 1.74077 8.14517 1.35138 9.84953 1.55069C11.5539 1.74999 13.1389 2.52601 14.3416 3.75H12.0001C11.8011 3.75 11.6104 3.82902 11.4697 3.96967C11.3291 4.11032 11.2501 4.30109 11.2501 4.5C11.2501 4.69891 11.3291 4.88968 11.4697 5.03033C11.6104 5.17098 11.8011 5.25 12.0001 5.25H15.1073C15.4766 5.2498 15.8308 5.103 16.0919 4.84185C16.3531 4.58071 16.4999 4.22657 16.5001 3.85725V0.75C16.5001 0.551088 16.421 0.360322 16.2804 0.21967C16.1397 0.0790176 15.949 0 15.7501 0V0C15.5511 0 15.3604 0.0790176 15.2197 0.21967C15.0791 0.360322 15.0001 0.551088 15.0001 0.75V2.3085C13.4916 0.960547 11.5725 0.160863 9.55316 0.0387835C7.53384 -0.0832959 5.53239 0.479368 3.87249 1.63577C2.2126 2.79218 0.991322 4.47471 0.406187 6.41124C-0.178948 8.34776 -0.0937266 10.4251 0.648068 12.3071C1.38986 14.1892 2.74485 15.7661 4.4939 16.7826C6.24294 17.7992 8.28376 18.196 10.2863 17.9089C12.2888 17.6219 14.1359 16.6677 15.529 15.2007C16.922 13.7337 17.7794 11.8397 17.9626 9.825C17.9722 9.72056 17.9601 9.61524 17.9268 9.51576C17.8935 9.41628 17.8399 9.32482 17.7694 9.24721C17.6988 9.1696 17.6129 9.10753 17.517 9.06497C17.4211 9.02241 17.3174 9.00028 17.2126 9C17.0296 8.99779 16.8524 9.06377 16.7154 9.18509C16.5784 9.3064 16.4915 9.47436 16.4716 9.65625Z"
      />
    </svg>
  );
};
