import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { addDays, format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
// Components
import DeleteButton from "../DeleteButton";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import {
  DeleteIcon,
  DownloadIcon,
  EyeIcon,
} from "../../Styles/Icons/DesignSystem";
import ButtonSpinner from "../Spinners/ButtonSpinner";
// Utils
import { downloadReport, openInNewTab } from "@intelligentlilli/lilli-utils";
// API
import {
  getReportStatus,
  postPublishReport,
} from "@intelligentlilli/api-layer";

const Report = ({
  report,
  server,
  setCloseConfirmModal,
  setReportToDelete,
  isDashboard,
  onMobile,
}) => {
  const navigate = useNavigate();

  // local state
  const [hovering, setHovering] = useState(false);
  const [hoveringGenerateButton, setHoveringGenerateButton] = useState(false);
  const [processing, setProcessing] = useState();
  const [reportURL, setReportURL] = useState();
  const [downloadError, setDownloadError] = useState();

  /* 
    The following isMounted ref and the useEffect with cleanup function make sure the async call to publish the report does not produce 
    memory leaks should the component be ummounted before the promise resolves
  */
  const isMounted = useRef(true);
  // Showing an alert when an error occurs when attempting to download a report
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Get the SUId from the report
  const serviceUserId = report?.serviceuserids?.[0];

  // Extract required report information to display
  const dateReportWasCreated = report.ts
    ? format(parseISO(report.ts), "dd/LL/yy")
    : "";

  const startDate =
    report.reportstartdate &&
    format(parseISO(report.reportstartdate), "dd/LL/yy");

  // We add one less day than than the number of days as the start and end dates are inclusive in the report
  const endDate =
    report.reportstartdate &&
    format(
      addDays(parseISO(report.reportstartdate), report.days - 1),
      "dd/LL/yy"
    );

  const reportDays = report.days
    ? `${report.days} day${report.days === 1 ? "" : "s"}`
    : "?";

  const handleGenerateReportClick = (event) => {
    // Prevent the click event bubbling up to the parent element
    event.stopPropagation();
    processing === false
      ? openInNewTab(reportURL)
      : downloadReport(
          server,
          setProcessing,
          report.id,
          setDownloadError,
          setReportURL,
          getReportStatus,
          postPublishReport,
          isMounted.current,
          "web"
        );
  };

  // Extract the two buttons as standalone components to reduce repitition
  const GenerateButton = ({ isHovered }) => (
    <PrimaryButton
      className={styles.report_button}
      hoverColour={theme.primary4}
      startIcon={
        processing ? (
          <ButtonSpinner />
        ) : (
          <DownloadIcon width={12} colour={theme.neutral7} />
        )
      }
      onClick={handleGenerateReportClick}
      padding="4px 16px"
      disabled={processing || downloadError}
    >
      {downloadError
        ? "Failed to process"
        : processing === false
        ? "Download"
        : processing
        ? "Processing ..."
        : "Generate Report"}
    </PrimaryButton>
  );

  const onDelete = (e) => {
    // Prevent the click event bubbling up to the parent element
    e.stopPropagation();
    setCloseConfirmModal(false);
    !processing && setReportToDelete(report);
  };

  // If we're on mobile return this view
  if (onMobile) {
    return (
      <div
        className={styles.mobile_report}
        onClick={() => !processing && navigate(`/pdf-preview/${report.id}`)}
        cypresstestid="single_report"
      >
        <div className={styles.mobile_report_details}>
          <div className={`${styles.mobile_report_name} fs-mask`}>
            {report.name}
          </div>
          <div
            className={`${styles.mobile_report_user} fs-mask`}
            onClick={(event) => {
              // Prevent the click event bubbling up to the parent element
              event.stopPropagation();
              !processing && navigate(`/lilli-users/${serviceUserId}`);
            }}
          >
            {report?.userstring}
          </div>
          <div className={styles.mobile_report_dates}>
            {dateReportWasCreated && `Created ${dateReportWasCreated}`}
          </div>
        </div>
        <div className={styles.mobile_report_buttons}>
          <SecondaryButton
            className={styles.mobile_report_buttons_generate}
            startIcon={
              processing ? (
                <ButtonSpinner />
              ) : (
                <DownloadIcon width={12} colour={theme.primary6} />
              )
            }
            onClick={handleGenerateReportClick}
            disabled={processing || downloadError}
          >
            {downloadError
              ? "Failed to process"
              : processing === false
              ? "Download"
              : processing
              ? "Processing ..."
              : "Generate"}
          </SecondaryButton>
          <SecondaryButton
            className={styles.mobile_report_buttons_delete}
            startIcon={<DeleteIcon colour={theme.dangerRed} />}
            onClick={onDelete}
            disabled={processing}
            colour={theme.dangerRed}
          >
            {"Delete"}
          </SecondaryButton>
        </div>
      </div>
    );
  }
  // If we're on desktop, return this view
  return (
    <>
      {/* --------- Desktop size ----------- */}
      <tr
        className={styles.report}
        onMouseOver={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
        cypresstestid="single_report"
      >
        <td className={`${styles.report_name} fs-mask`}>{report.name}</td>
        <td
          className={`${styles.report_user} fs-mask`}
          onClick={() =>
            !processing && navigate(`/lilli-users/${serviceUserId}`)
          }
        >
          {report?.userstring}
        </td>
        <td className={styles.report_element}>{dateReportWasCreated}</td>
        {!isDashboard && (
          <>
            <td className={styles.report_element}>
              {startDate && endDate && `${startDate} - ${endDate}`}
            </td>
            <td className={styles.report_element}>{reportDays}</td>
          </>
        )}
        <td className={styles.buttons}>
          <div className={styles.buttons_generate}>
            {hovering && (
              <PrimaryButton
                className={styles.report_button}
                hoverColour={theme.primary4}
                startIcon={<EyeIcon colour={theme.neutral7} />}
                onClick={() => navigate(`/pdf-preview/${report.id}`)}
                padding="4px 16px"
                disabled={processing}
                id="Preview Report"
              >
                Preview/Edit
              </PrimaryButton>
            )}
          </div>
          <div
            className={styles.buttons_generate}
            onMouseOver={() => {
              setHoveringGenerateButton(true);
            }}
            onMouseLeave={() => {
              setHoveringGenerateButton(false);
            }}
          >
            {hovering && <GenerateButton isHovered={hoveringGenerateButton} />}
          </div>
          <div>
            {hovering && (
              <DeleteButton
                onClick={(e) => onDelete(e)}
                label="Delete"
                disabled={processing}
              />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default Report;
