import { createSlice } from "@reduxjs/toolkit";
import { augmentSensorData } from "../../Services/Utils";

const initialState = null;

const sensorsSlice = createSlice({
  name: "sensors",
  initialState,
  reducers: {
    setSensors(_, action) {
      if (action.payload === null) {
        return null;
      }
      const { devices, hubId, devicestring } = action.payload;
      // Augment the API data with the additional frontend context
      const augmentedData = augmentSensorData(devices, hubId, devicestring);
      // For simplicity in the consumer pages extract the hub data into it's own variable
      const hub = augmentedData?.find(
        (device) => device.defaultName === "SquidZigBee"
      );
      return {
        devices: augmentedData,
        hubId: hubId,
        hub: hub,
      };
    },
    updateSensor(state, action) {
      const { index, key, value } = action.payload;
      state.devices[index][key] = value;
    },
  },
});

export const { setSensors, updateSensor } = sensorsSlice.actions;

export default sensorsSlice.reducer;
