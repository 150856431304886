import { getHubTemperatureAnalysisV2 as getHubTemperatureAnalysisAPI_v2 } from "@intelligentlilli/api-layer";

const getHubTemperatureAnalysisV2 = async (server, id, startTime, endTime) => {
  try {
    const response = await getHubTemperatureAnalysisAPI_v2(
      server,
      id,
      startTime,
      endTime,
      "web"
    );
    if (response.ok) {
      return response.body;
    } else {
      return null;
    }
  } catch (err) {
    console.log(`Error fetching data for temperature v2:`, err);
    return err;
  }
};

export default getHubTemperatureAnalysisV2;
