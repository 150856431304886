import { postLogout as postLogoutAPI } from "@intelligentlilli/api-layer";

const postLogout = async (server) => {
  try {
    const response = await postLogoutAPI(server, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default postLogout;
