import React, { useState } from "react";
import theme from "../../../Styles/theme.scss";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
// API
import postHubProperty from "../../../Services/Cache/Hubs/postHubProperty";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setRefreshOrganisationHubs,
  setRefreshUser,
} from "../../../State/slices/session-slice";
// Components
import Page from "../../../Components/Page";
import CTAButton from "../../../Components/CTAButton";
import InstallationArt from "../../../Components/InstallationArt";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
// services
import { mobileBreakpoint } from "../../../Services/config";
// hooks
import { useGetUserRole } from "../../../Services/Hooks";
// icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";

const InstallFinish = () => {
  const { id, devicestring } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();

  // sizing
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;

  const { userIsJustAnInstaller, userIsManagerOrAdmin } = useGetUserRole();
  console.log("userIsManagerOrAdmin?", userIsManagerOrAdmin);
  console.log("userIsJustAnInstaller?", userIsJustAnInstaller);

  // redux state
  const dispatch = useDispatch();

  const server = useSelector((state) => state.session.server);
  const sensorLocations = useSelector((state) => state.sensorLocations);
  // Local state
  const [summary, setSummary] = useState();
  console.log("InstallFinish > sensorLocations:", sensorLocations);
  console.log("InstallFinish > summary:", summary);
  // back-up check that all postHubProperty requests were sent
  const [sentSensorLocations, setSentSensorLocations] = useState(false);
  const [sentInstallationNotes, setSentInstallationNotes] = useState(false);
  const [sentInstallationComplete, setSentInstallationComplete] =
    useState(false);
  const [stage, setStage] = useState("start");

  // installer success message
  const installerSuccessMessage = `This user is successfully installed but you don't have access to view their data. For more information please contact `;

  const allPostRequestsSuccessful =
    sentSensorLocations &&
    (summary ? sentInstallationNotes : true) &&
    sentInstallationComplete;

  const goToSensorsScreen = () => {
    navigate(`/install/sensors/${id}/${devicestring}`);
  };

  const completeInstallation = () => {
    dispatch(setLoading(true));
    // if the user refreshses on this screen, it will clear the sensor locations
    // when that happens, this will result in the 'Uh oh' Screen where the 'Try Again' button will
    // navigate the user back to the sensors screen
    if (!sensorLocations) {
      console.log("no sensor locations!");
      setSentSensorLocations(false);
      setSentInstallationNotes(false);
      setSentInstallationComplete(false);
      setStage("end");
      dispatch(setLoading(false));
      return;
    }

    postHubProperty(
      server,
      id,
      "SensorLocations",
      JSON.stringify(sensorLocations)
    )
      .then((res) => {
        console.log("1- Sensor locations ressponse:", res);
        console.log("1- Res.status:", res.status);
        console.log("1- Res.ok?:", res.ok);
        setSentSensorLocations(true);
        if (res.ok) {
          console.log("1- Res.Ok");
        } else {
          console.log("1- Res NOT ok");
        }
      })
      .catch((err) => {
        console.log("1- Sensor locations: there has been an error:", err);
      });

    if (summary) {
      postHubProperty(server, id, "InstallationNotes", summary)
        .then((res) => {
          console.log("2- Installation Notes response:", res);
          console.log("2- Res.status:", res.status);
          console.log("2- Res.ok?:", res.ok);
          setSentInstallationNotes(true);

          if (res.ok) {
            console.log("2- Res.Ok");
          } else {
            console.log("2- Res NOT ok");
          }
        })
        .catch((err) => {
          console.log("2- Installation Notes: there has been an error:", err);
        });
    }

    postHubProperty(server, id, "InstallationStatus", "complete")
      .then((res) => {
        console.log("3- InstallationStatus response:", res);
        console.log("3- Res.status:", res.status);
        console.log("3- Res.ok?:", res.ok);
        setSentInstallationComplete(true);
        setStage("end");
        if (userIsManagerOrAdmin) {
          dispatch(setRefreshOrganisationHubs(true));
        } else {
          dispatch(setRefreshUser(true));
        }
        if (res.ok) {
          console.log("3- Res.Ok");
        } else {
          console.log("3- Res NOT ok");
        }
      })
      .catch((err) => {
        console.log("3- Installation Status: there has been an error:", err);
      });

    dispatch(setLoading(false));
  };

  return (
    <Page className={styles.page} cypresstestid="Installation-Notes-9">
      {!isDesktop && <InstallationArt half />}
      <InstallationCard style={styles.card}>
        <div className={styles.card_left}>
          <img
            src="/logo/lilli-128-teal.png"
            alt="Lilli"
            className={styles.logo}
          />
        </div>

        <div className={styles.card_right}>
          {isDesktop && <InstallationArt />}
          {stage === "start" && (
            <>
              <div className={`${styles.top} fs-mask`}>
                <div className={styles.instructions}>
                  <h2 className={styles.main_title}>Almost there!</h2>
                  <h2 className={styles.secondary_title}>Install notes</h2>
                  <p>
                    Please use this space to leave any notes about this Lilli
                    user and their install:
                  </p>
                </div>
                <textarea
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                  placeholder="e.g. Service user stays at family home on Thursdays..."
                  style={{
                    height: "180px",
                    width: "100%",
                    resize: "none",
                    borderRadius: 5,
                    borderColor: theme.neutral2,
                    backgroundColor: "#FAFAFA",
                    padding: 12,
                    boxSizing: "border-box",
                    fontFamily: "Inter",
                    fontSize: "16px",
                  }}
                />
              </div>
              <div className={styles.buttons}>
                <div style={{ marginRight: 12 }}>
                  <InstallationGoBackButton
                    textColor={theme.primary6}
                    onClick={() => navigate(-1)}
                    label={"Go back"}
                  />
                </div>
                <div>
                  <CTAButton
                    style={{
                      width: "100%",
                      padding: "12px",
                    }}
                    onClick={completeInstallation}
                    cypresstestid="Installation-Complete-10"
                  >
                    Complete
                  </CTAButton>
                </div>
              </div>
            </>
          )}
          {stage === "end" && allPostRequestsSuccessful && (
            <InstallationSplash
              icon={<ThumbsUpIcon />}
              headers={["Great!", "Your installation is complete!"]}
              message={
                userIsJustAnInstaller
                  ? installerSuccessMessage
                  : "Head back to your dashboard"
              }
              email={userIsJustAnInstaller && "support@intelligentlilli.com"}
              nextButtonLabel={
                userIsJustAnInstaller
                  ? "Start new installation"
                  : "Go to dashboard"
              }
              nextButtonOnClick={() => {
                userIsJustAnInstaller ? navigate("/install") : navigate("/");
              }}
              isDesktop={isDesktop}
              cypresstestid="Installation-Go-To-Dashboard-11"
            />
          )}
          {stage === "end" && !allPostRequestsSuccessful && (
            <InstallationSplash
              icon={<ThumbsDownIcon />}
              headers={["Uh oh!", "We ran into an issue"]}
              message={
                // if sensors data are available in the browser cache
                sensorLocations
                  ? "Click the Try Again button. If the issue persists please email support at "
                  : "Please re-enter your sensor locations. If the issue persists please email support at "
              }
              email={"support@intelligentlilli.com"}
              nextButtonLabel={
                // if sensors data are available in the browser cache
                sensorLocations ? "Try again" : "Re-enter sensor locations"
              }
              nextButtonOnClick={
                // if sensors data are available in the browser cache, try the post requests again
                sensorLocations
                  ? completeInstallation
                  : // if there are no sensor location data send the user back to the sensors screen
                    goToSensorsScreen
              }
              isDesktop={isDesktop}
              goBackOnClick={() => navigate("/install")}
              goBackButtonLabel={"Exit install"}
            />
          )}
        </div>
      </InstallationCard>
    </Page>
  );
};

export default InstallFinish;
