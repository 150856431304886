import React from "react";
import theme from "../../theme.scss";

export const SortIcon = ({ width, height, colour }) => {
  return (
    <svg width={width || 11} height={height || 12} viewBox="0 0 11 12">
      <path
        d="M3.16013 0.917358H1.80579C1.32686 0.917358 0.867555 1.10761 0.528903 1.44626C0.190252 1.78491 0 2.24422 0 2.72315L0 4.07749C0 4.55641 0.190252 5.01572 0.528903 5.35438C0.867555 5.69303 1.32686 5.88328 1.80579 5.88328H3.16013C3.63906 5.88328 4.09837 5.69303 4.43702 5.35438C4.77567 5.01572 4.96592 4.55641 4.96592 4.07749V2.72315C4.96592 2.24422 4.77567 1.78491 4.43702 1.44626C4.09837 1.10761 3.63906 0.917358 3.16013 0.917358V0.917358ZM4.06303 4.07749C4.06303 4.31695 3.9679 4.54661 3.79857 4.71593C3.62925 4.88526 3.39959 4.98038 3.16013 4.98038H1.80579C1.56633 4.98038 1.33667 4.88526 1.16735 4.71593C0.998021 4.54661 0.902895 4.31695 0.902895 4.07749V2.72315C0.902895 2.48369 0.998021 2.25403 1.16735 2.0847C1.33667 1.91538 1.56633 1.82025 1.80579 1.82025H3.16013C3.39959 1.82025 3.62925 1.91538 3.79857 2.0847C3.9679 2.25403 4.06303 2.48369 4.06303 2.72315V4.07749Z"
        fill={colour || theme.neutral5}
      />
      <path
        d="M3.16013 6.78619H1.80579C1.32686 6.78619 0.867555 6.97645 0.528903 7.3151C0.190252 7.65375 0 8.11306 0 8.59198L0 9.94632C0 10.4252 0.190252 10.8846 0.528903 11.2232C0.867555 11.5619 1.32686 11.7521 1.80579 11.7521H3.16013C3.63906 11.7521 4.09837 11.5619 4.43702 11.2232C4.77567 10.8846 4.96592 10.4252 4.96592 9.94632V8.59198C4.96592 8.11306 4.77567 7.65375 4.43702 7.3151C4.09837 6.97645 3.63906 6.78619 3.16013 6.78619ZM4.06303 9.94632C4.06303 10.1858 3.9679 10.4154 3.79857 10.5848C3.62925 10.7541 3.39959 10.8492 3.16013 10.8492H1.80579C1.56633 10.8492 1.33667 10.7541 1.16735 10.5848C0.998021 10.4154 0.902895 10.1858 0.902895 9.94632V8.59198C0.902895 8.35252 0.998021 8.12287 1.16735 7.95354C1.33667 7.78421 1.56633 7.68909 1.80579 7.68909H3.16013C3.39959 7.68909 3.62925 7.78421 3.79857 7.95354C3.9679 8.12287 4.06303 8.35252 4.06303 8.59198V9.94632Z"
        fill={colour || theme.neutral5}
      />
      <path
        d="M10.0641 9.51696L9.02897 10.5508V2.106L10.0641 3.13981C10.1061 3.18173 10.1559 3.21497 10.2107 3.23764C10.2654 3.26031 10.3242 3.27197 10.3835 3.27195C10.4428 3.27193 10.5015 3.26023 10.5563 3.23752C10.611 3.21481 10.6608 3.18153 10.7027 3.13959C10.7446 3.09764 10.7779 3.04785 10.8005 2.99306C10.8232 2.93827 10.8349 2.87955 10.8348 2.82025C10.8348 2.76096 10.8231 2.70224 10.8004 2.64747C10.7777 2.59269 10.7444 2.54293 10.7025 2.50101L9.53459 1.33492C9.28065 1.0813 8.93642 0.938843 8.57752 0.938843C8.21862 0.938843 7.87439 1.0813 7.62045 1.33492L6.45256 2.50101C6.41061 2.54293 6.37733 2.59269 6.35462 2.64747C6.33191 2.70224 6.32021 2.76096 6.32019 2.82025C6.32017 2.87955 6.33183 2.93827 6.3545 2.99306C6.37717 3.04785 6.41042 3.09764 6.45233 3.13959C6.49424 3.18153 6.54401 3.21481 6.59879 3.23752C6.65356 3.26023 6.71227 3.27193 6.77157 3.27195C6.83087 3.27197 6.88959 3.26031 6.94438 3.23764C6.99917 3.21497 7.04896 3.18173 7.0909 3.13981L8.12607 2.106V10.5508L7.0909 9.51696C7.04896 9.47504 6.99917 9.4418 6.94438 9.41913C6.88959 9.39645 6.83087 9.38479 6.77157 9.38482C6.71227 9.38484 6.65356 9.39654 6.59879 9.41925C6.54401 9.44196 6.49424 9.47524 6.45233 9.51718C6.41042 9.55912 6.37717 9.60891 6.3545 9.6637C6.33183 9.7185 6.32017 9.77722 6.32019 9.83651C6.32021 9.89581 6.33191 9.95452 6.35462 10.0093C6.37733 10.0641 6.41061 10.1138 6.45256 10.1558L7.62045 11.3218C7.87439 11.5755 8.21862 11.7179 8.57752 11.7179C8.93642 11.7179 9.28065 11.5755 9.53459 11.3218L10.7025 10.1558C10.7444 10.1138 10.7777 10.0641 10.8004 10.0093C10.8231 9.95452 10.8348 9.89581 10.8348 9.83651C10.8349 9.77722 10.8232 9.7185 10.8005 9.6637C10.7779 9.60891 10.7446 9.55912 10.7027 9.51718C10.6608 9.47524 10.611 9.44196 10.5563 9.41925C10.5015 9.39654 10.4428 9.38484 10.3835 9.38482C10.3242 9.38479 10.2654 9.39645 10.2107 9.41913C10.1559 9.4418 10.1061 9.47504 10.0641 9.51696Z"
        fill={colour || theme.neutral5}
      />
    </svg>
  );
};
