import React from "react";
import styles from "./styles.module.scss";

import NewStyleModal from "../NewStyleModal";
import ConfirmDelete from "../ConfirmDelete";

function ConfirmDeleteReportModal({
  setCloseConfirmModal,
  reportToDelete,
  deleteSelectedReport,
}) {
  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={() => setCloseConfirmModal(true)}
      title="Are you sure you want to delete?"
      context={`You will be deleting the report titled ${reportToDelete?.name} for ${reportToDelete?.userstring}. This action cannot be undone.`}
      contextClass={styles.contextClass}
      cypresstestid="Delete-Report-Btn-Submit"
    >
      <ConfirmDelete
        confirmLabel="Delete Report"
        confirm={() => deleteSelectedReport(reportToDelete?.id)}
        goBack={() => setCloseConfirmModal(true)}
      />
    </NewStyleModal>
  );
}

export default ConfirmDeleteReportModal;
