import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// Hooks
import { useTimer, useGetSensorStatus } from "../../../Services/Hooks";
// Components
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Page from "../../../Components/Page";
import AltSpinner from "../../../Components/Spinners/AltSpinner";
import InstallationArt from "../../../Components/InstallationArt";
import InstallationBanner from "../../../Components/InstallationBanner";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationSplash from "../../../Components/InstallationSplash";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import { useViewport } from "../../../Components/ViewportProvider";
// Icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";
// Config
import { mobileBreakpoint } from "../../../Services/config";

const InstallHubProvisioning = () => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;

  const [showCarousel, setShowCarousel] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowCarousel(true), 1000);
  }, []);

  // We wait for the hub to become provisioned
  const { minutes, seconds, setMinutes } = useTimer({
    initialMinutes: 6,
    initialSeconds: 0,
  });
  const timesUp = minutes === 0 && seconds === 0;

  // Trigger the sensor status fetching
  const { hubIsOnline } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: !timesUp,
  });

  // Once the time is up we stop polling the hub. To attempt to connect again simply restart the timer
  const tryAgain = () => {
    setMinutes(6);
  };

  return (
    <Page className={styles.page}>
      {isDesktop && <InstallationBanner />}
      <InstallationCard>
        <div className={styles.card_left}>
          <img
            src="/images/installation/hub/hub.png"
            alt="Lilli hub"
            className={styles.logo}
          />
        </div>
        <div
          className={`${styles.card_right} ${
            !hubIsOnline && !timesUp && styles.flex_dir_column
          }`}
        >
          <InstallationArt puzzle={showCarousel} />
          {!hubIsOnline && !timesUp && (
            <div className={styles.content}>
              <h2>Connecting to the Lilli hub...</h2>
              {!showCarousel && (
                <div className={styles.spinner}>
                  <AltSpinner style={{ marginRight: 6 }} />
                  <div>Waiting for connection...</div>
                </div>
              )}
              {showCarousel && (
                <div cypresstestid="Installation-Hub-Provisioning-7">
                  <h3>Did you know?</h3>
                  <div style={{ marginTop: 12 }}>
                    <Carousel
                      showIndicators={false}
                      showArrows={false}
                      showStatus={false}
                      showThumbs={false}
                      infiniteLoop
                      autoPlay
                    >
                      <div style={{ textAlign: "start" }}>
                        Lilli detects showers and baths
                      </div>
                      <div style={{ textAlign: "start" }}>
                        Lilli works without the need for wifi
                      </div>
                      <div style={{ textAlign: "start" }}>
                        You can generate reports through Lilli
                      </div>
                    </Carousel>
                  </div>
                </div>
              )}
              <div>
                {showCarousel && (
                  <div className={styles.waiting}>
                    <br />
                    <div style={{ textAlign: "left" }}>
                      It may take a few minutes for the hub to connect.
                      {/* The hub is connected when there are 3 green lights. This
                      may take a few minutes. */}
                    </div>
                    <br />
                    <div>Waiting for connection...</div>
                  </div>
                )}
                <InstallationGoBackButton
                  isDesktop={isDesktop}
                  marginTop={"12px"}
                  onClick={() => navigate(`/install`)}
                  label={"Exit install"}
                  textColor={theme.error2}
                  cypresstestid="Installation-Hub-Provisioning-Go-Back-Btn-7a"
                />
              </div>
            </div>
          )}

          {hubIsOnline && (
            <InstallationSplash
              icon={<ThumbsUpIcon />}
              headers={["Great!", "Your Lilli hub is connected"]}
              message={"Let's get your sensors installed"}
              goBackButtonLabel={"Exit install"}
              goBackOnClick={() => navigate("/install")}
              nextButtonLabel={"Continue"}
              nextButtonOnClick={() =>
                navigate(`/install/sensors/${id}/${devicestring}`)
              }
              isDesktop={isDesktop}
            />
          )}

          {timesUp && !hubIsOnline && (
            <InstallationSplash
              icon={<ThumbsDownIcon />}
              headers={["Uh-oh!", "Something went wrong"]}
              message={
                "Let's try again, but if the problem continues, contact "
              }
              email={"support@intelligentlilli.com"}
              goBackButtonLabel={"Exit install"}
              goBackOnClick={() => navigate("/install")}
              nextButtonLabel={"Try again"}
              nextButtonOnClick={tryAgain}
              isDesktop={isDesktop}
            />
          )}
        </div>
      </InstallationCard>
    </Page>
  );
};

export default InstallHubProvisioning;
