import PageTitle from "../PageTitle";
import styles from "./styles.module.scss";

const InstallationBanner = () => {
  return (
    <div className={styles.page_banner}>
      <PageTitle title="Installation" />
    </div>
  );
};

export default InstallationBanner;
