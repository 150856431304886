import { getUserHubs as getUserHubsAPI } from "@intelligentlilli/api-layer";

const getUserHubs = async (server, id) => {
  try {
    const response = await getUserHubsAPI(server, id, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getUserHubs;
