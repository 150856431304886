import React from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
// Utils
import { getServiceUserById } from "@intelligentlilli/lilli-utils";
// Hooks
import {
  useFade,
  useOpenHubPairing,
  useGetSensorStatus,
} from "../../../Services/Hooks";
// State
import { useSelector } from "react-redux";
// Routing
import { useParams, useNavigate } from "react-router-dom";
// Components
import Page from "../../../Components/Page";
import CTAButton from "../../../Components/CTAButton";
import Sensor from "../../../Components/Sensor";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationSuDetails from "../../../Components/InstallationSuDetails";
import PrimaryButton from "../../../Components/PrimaryButton";
import NewStyleModal from "../../../Components/NewStyleModal";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallationArt from "../../../Components/InstallationArt";
// Icons
// import { VideoIcon } from "../../../Styles/Icons/DesignSystem";

const InstallSensors = () => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  // sizing
  const { width } = useViewport();
  const isDesktop = width > 600;

  // Trigger the sensor status fetching
  const { loading, fetchSensorStatus } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: false,
  });

  // redux state
  const sensors = useSelector((state) => state.sensors);
  const sensorLocations = useSelector((state) => state.sensorLocations);
  const serviceUsers = useSelector((state) => state.serviceUsers);

  const serviceUser = getServiceUserById(serviceUsers, id);

  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  // Installation is complete when each sensor in the devices array has installed: true
  const installationComplete =
    sensors?.devices?.filter(
      (device) =>
        !device.name.includes("REMOVED") &&
        (!device.online ||
          (device.locationRequired && !sensorLocations?.[device.name]))
    ).length === 0;

  // Trigger the hub to activate pairing mode if the installation is not yet complete
  useOpenHubPairing({ hubId: id, installationComplete: installationComplete });

  // We want to show the smart plug first in the list of sensors to install sorting them here
  const devicesCopy = sensors?.devices && [...sensors?.devices];
  // Filter out any removed sensors
  const devicesWithoutRemoved = devicesCopy?.filter(
    (device) => !device.name.includes("REMOVED")
  );
  const sortedSensors = sensors && {
    ...sensors,
    devices: devicesWithoutRemoved?.sort((a, b) =>
      a.defaultName === "Smart Plug Mini UK" ? -1 : 0
    ),
  };

  return (
    <Page className={styles.page} cypresstestid="Installation-Sensors-8">
      <InstallationCard style={styles.card}>
        <div className={styles.card_top}>
          {!isDesktop && <InstallationArt half />}
          <InstallationSuDetails
            userstring={serviceUser?.userstring || ""}
            devicestring={devicestring}
            onClick={fetchSensorStatus}
            loading={loading}
            isDesktop={isDesktop}
            allowRefresh
          />
        </div>

        <div className={styles.card_bottom}>
          <div className={styles.card_left}>
            <img
              src="/logo/lilli-128-teal.png"
              alt="Lilli"
              className={styles.logo}
            />
          </div>
          <div className={styles.card_right}>
            {/* <PrimaryButton
              startIcon={<VideoIcon colour={theme.primary6} />}
              colour={theme.primary6}
              backgroundColour={theme.white}
              border={`1px solid ${theme.neutral2}`}
              style={{ fontSize: "16px" }}
              iconMargin="15px"
              padding="11px 18px"
              hoverColour={theme.neutral1}
              onClick={() => setShowAnimation(true)}
            >
              Watch how to identify sensors
            </PrimaryButton> */}
            {!sensors?.criticalError && (
              <>
                <h2>Let's start connecting sensors</h2>
                <div className={styles.sensors}>
                  <h4>
                    Each sensor in your kit has a code on it. Please select a
                    sensor to install below:
                  </h4>
                  {sortedSensors?.devices
                    ?.filter((device) => device.defaultName !== "SquidZigBee")
                    ?.map((device, index) => (
                      <Sensor
                        device={device}
                        key={index}
                        onClick={() => navigate(device.route)}
                        sensorLocation={sensorLocations?.[device.name]}
                        loading={loading}
                        ignoreLocation={!device.locationRequired}
                      />
                    ))}
                </div>
              </>
            )}
            {sensors?.criticalError && (
              <div className={styles.error}>
                <div>Cannot detect lilli hub</div>
                <a
                  href="mailto: support@intelligentlilli.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact support
                </a>
              </div>
            )}
            <div className={styles.buttons}>
              {/* If someone returns to an installation after closing the app allow them to restart the installation */}
              {!sensors && (
                <PrimaryButton
                  onClick={fetchSensorStatus}
                  padding="14px 18px"
                  cypresstestid="Installation-Test-Sensors-8a"
                  style={{
                    fontSize: "16px",
                    width: isDesktop ? "50%" : "100%",
                    marginBottom: isDesktop ? "0px" : "14px",
                    lineHeight: 1.5,
                  }}
                >
                  {isDesktop ? "Continue" : "Continue Installation"}
                </PrimaryButton>
              )}
              {installationComplete && (
                <CTAButton
                  style={{
                    fontSize: "16px",
                    width: isDesktop ? "50%" : "100%",
                    marginBottom: isDesktop ? "0px" : "14px",
                    padding: "14px 18px",
                  }}
                  cypresstestid="Installation-Complete-Install-8c"
                  onClick={() =>
                    navigate(`/install/finish/${id}/${devicestring}`)
                  }
                >
                  Complete
                </CTAButton>
              )}
              {!installationComplete && (
                <PrimaryButton
                  colour={theme.error2}
                  backgroundColour={theme.white}
                  border={`1px solid ${theme.neutral2}`}
                  cypresstestid="Installation-Exit-Install-8b"
                  style={{
                    fontSize: "16px",
                    marginBottom: isDesktop ? "0px" : "0px",
                    marginRight: isDesktop ? "10px" : "0px",
                    width: isDesktop ? "50%" : "100%",
                  }}
                  padding="14px 18px"
                  hoverColour={theme.neutral1}
                  onClick={() => navigate("/install")}
                >
                  {isDesktop ? "Exit" : "Exit Installation"}
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
      </InstallationCard>
      {isVisible && (
        <NewStyleModal
          hide={() => {
            setShowAnimation(false);
          }}
          title="How to identify sensors"
          useFade={true}
          showAnimation={showAnimation}
        >
          <div className={styles.video_placeholder}>Video to come</div>
        </NewStyleModal>
      )}
    </Page>
  );
};

export default InstallSensors;
