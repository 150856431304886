import React from "react";
import theme from "../../theme.scss";

export const LightBulbIcon = ({ width, height, colour }) => {
  return (
    <svg
      width={width || 17}
      height={height || 22}
      viewBox="0 0 17 22"
      id="LightBulbIcon"
    >
      <path
        d="M5.125 20.7513C5.125 21.3471 5.63125 21.8346 6.25 21.8346H10.75C11.3688 21.8346 11.875 21.3471 11.875 20.7513V19.668H5.125V20.7513ZM8.5 0.167969C4.1575 0.167969 0.625 3.56964 0.625 7.7513C0.625 10.3296 1.96375 12.5938 4 13.9696V16.418C4 17.0138 4.50625 17.5013 5.125 17.5013H11.875C12.4938 17.5013 13 17.0138 13 16.418V13.9696C15.0363 12.5938 16.375 10.3296 16.375 7.7513C16.375 3.56964 12.8425 0.167969 8.5 0.167969ZM10.75 12.843V15.3346H6.25V12.843C4.54 11.6838 2.875 10.4921 2.875 7.7513C2.875 4.7613 5.395 2.33464 8.5 2.33464C11.605 2.33464 14.125 4.7613 14.125 7.7513C14.125 10.4488 12.4263 11.7055 10.75 12.843Z"
        fill={colour || theme.brandBlack}
      />
    </svg>
  );
};
