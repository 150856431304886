import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
// State
import { useSelector, useDispatch } from "react-redux";
import { updateSensor } from "../../../State/slices/sensors-slice";
// Hooks
import { useOpenHubPairing, useGetSensorStatus } from "../../../Services/Hooks";
// Components
import InstallSensor from "../../../Components/InstallSensor";
import InstallationHint from "../../../Components/InstallationHint";
import InstallationInstructions from "../../../Components/InstallationInstructions";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallationButtons from "../../../Components/InstallationButtons";
import InstallSensorLocation from "../../../Components/InstallSensorLocation";
import InstallTroubleshooting from "../../../Components/InstallTroubleshooting";

const InstallPlug = () => {
  // Get the service user id from the url parameter
  const { devicestring, id } = useParams();
  const navigate = useNavigate();

  // sizing
  const { width } = useViewport();
  const isDesktop = width > 600;

  // Trigger the enter hub pairing mode
  const secondsUntilNextRepair = 120000; // 2 minutes
  useOpenHubPairing({
    hubId: id,
    installationComplete: false,
    frequencyOfRepairing: secondsUntilNextRepair,
  });

  // Trigger the sensor status fetching
  const { loading, fetchSensorStatus } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: false,
  });

  // redux state
  const dispatch = useDispatch();
  const sensors = useSelector((state) => state.sensors);
  const sensorLocations = useSelector((state) => state.sensorLocations);
  const serviceUsers = useSelector((state) => state.serviceUsers);

  // su details
  const selectedSuObject = serviceUsers.filter((i) => i.id === parseInt(id));
  const selectedSUName = selectedSuObject?.[0]?.userstring || "";

  // Find the index of the smart plug from the sensor data
  const sensorIndex = sensors?.devices?.findIndex(
    (device) => device.displayName === "Smart plug"
  );
  // Bool to check we have data
  const foundSensor = sensorIndex >= 0;
  // Get the correct sensor
  const sensor = foundSensor && sensors?.devices?.[sensorIndex];

  // If the installer is returning to this page and has already chosen a location get it here
  const sensorLocation = sensorLocations?.[sensor?.name];

  // Keep track of when the user is entering a custom appliance for the plug sensor
  const otherSelected =
    sensorLocation &&
    (sensorLocation === "Other" ||
      !sensor?.locationOptions.find(
        (location) => location.value === sensorLocation
      ));

  // Keep track of which pages the user has visited
  useEffect(() => {
    foundSensor &&
      dispatch(
        updateSensor({
          index: sensorIndex,
          key: "checkedConnection",
          value: true,
        })
      );
  }, [dispatch, sensorIndex, foundSensor]);

  // images of the sensor
  const imageLocations = {
    mainImage: "/images/installation/sensors/plug/plug.png",
    pluggedIn: "/images/installation/sensors/plug/plug-in.png",
    active: "/images/installation/sensors/plug/plug-on.png",
  };
  const images = [
    {
      image: imageLocations["mainImage"],
      altText: "The smart plug",
      className: undefined,
    },
    {
      image: imageLocations["pluggedIn"],
      altText: "The smart plug when it is plugged into the socket.",
      className: undefined,
    },
    {
      image: imageLocations["active"],
      altText: "The smart plug when it is active.",
      className: undefined,
    },
  ];

  // installation instructions
  const instructions = [
    { text: `Unplug the ${otherSelected ? "device" : sensorLocation}.` },
    { text: "Plug the smart plug into the wall." },
    {
      text: `Plug the ${
        otherSelected ? "device" : sensorLocation
      } into the smart plug.`,
      images: [
        {
          src: imageLocations["pluggedIn"],
          alt: "Plugged in power sensor",
          width: "40%",
        },
      ],
    },
    {
      text: "The LED on the side of the smart plug should turn green, if not push the LED button.",
    },
    {
      text: "Once you can see the green light test the connection below.",
      images: [
        {
          src: imageLocations["active"],
          alt: "Connected power sensor",
          width: "40%",
        },
      ],
    },
  ];

  // hints
  const smartPlugInstallationHint = [
    "To maximise sensor readings, find out the most used kitchen appliance for eating or drinking.",
  ];

  return (
    <InstallSensor
      images={width > 1000 && images}
      image={width < 1000 && imageLocations["mainImage"]}
      altText={width < 1000 && "Lilli smart plug"}
      sensor={sensor}
      imageStyle={styles.image}
      userstring={selectedSUName}
      devicestring={devicestring}
      testConnection={fetchSensorStatus}
      hints={smartPlugInstallationHint}
      loading={loading}
      locationSelected={!!sensorLocation}
      cypresstestid={"Installation-Smart-Plug-Sensor"}
    >
      <h2>Install smart plug</h2>
      {width <= 900 && <InstallationHint hints={smartPlugInstallationHint} />}
      <div className={styles.top_container}>
        <p>
          This smart plug is intended for use within a kitchen to monitor eating
          and drinking.
        </p>
        <p>
          The smart plug should be used with the most frequently used kitchen
          appliance, such as a Microwave, Kettle, or Toaster.
        </p>

        <InstallSensorLocation sensor={sensor} />
        {sensorLocation && (
          <InstallationInstructions instructions={instructions} />
        )}
      </div>
      <InstallTroubleshooting
        imageLocation={imageLocations.active}
        imageWidth="50%"
      />
      <InstallationButtons
        loading={loading}
        goBack={() => navigate(-1)}
        isDesktop={isDesktop}
        sensor={sensor}
        sensorLocation={sensorLocation}
        fetchSensorStatus={fetchSensorStatus}
        id={id}
      />
    </InstallSensor>
  );
};

export default InstallPlug;
