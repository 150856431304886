import React from "react";
import styles from "./styles.module.scss";
// Components
import { CloseIcon, SearchIcon } from "../../Styles/Icons";

const DashboardSearchInput = (props) => {
  const placeholderText = `Search ${props.selectedTab}...`;

  const focus = () => {
    props.setFocus();
  };
  const isFocused = () => {
    props.setFocused(true);
  };
  const requestEnd = () => {
    props.setRequestEnd(true);
  };

  return (
    <div
      className={`
        ${styles.dashboard_search_container} 
        ${props.focused && styles.focused_dashboard_search_container}
        ${props.className}
        `}
    >
      <div
        onClick={props.setFocus && focus}
        className={styles.clickable_dashboard_search}
      >
        <SearchIcon className={styles.dashboard_search_icon} />
        <input
          value={props.value}
          onFocus={props.setFocused && isFocused}
          onBlur={props.setRequestEnd && requestEnd}
          placeholder={placeholderText}
          onChange={(event) => {
            props.setValue(event.target.value);
          }}
          ref={props.inputRef && props.inputRef}
          aria-label="Search"
        />
      </div>
      <CloseIcon
        className={styles.dashboard_close_icon}
        onClick={() => {
          props.setValue("");
        }}
      />
      {props.children}
    </div>
  );
};

export default DashboardSearchInput;
