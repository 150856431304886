import { useEffect } from "react";
import {
  setServiceUsersData,
  updateServiceUserData,
} from "../../State/slices/serviceUsersData-slice";
import { setServiceUsers } from "../../State/slices/serviceUsers-slice";
import { setUser } from "../../State/slices/user-slice";
import {
  setFetchInitialState,
  setLoadingBaseData,
  setRefreshStaff,
} from "../../State/slices/session-slice";
import getCheckAuthState from "../Cache/CurrentUser/getCheckAuthState";
import getHubRiskScoreV2 from "../Cache/Hubs/getHubRiskScoreV2";
import postHubsRiskScores from "../Cache/Hubs/postHubsRiskScores";
import getOrganisationHubs from "../Cache/Organisations/getOrganisationHubs";
// Utils
import {
  calcBehaviourRisk,
  formatUserHubs,
} from "@intelligentlilli/lilli-utils";
import { isSameDay } from "date-fns";

const fetchManagerSUs = (server, organisationId, dispatch) => {
  // If the user is a manager get all organisation service users and persist to redux
  getOrganisationHubs(server, organisationId)
    .then((res) => {
      if (res.ok) {
        // Persisting a hubs object to state ready to receive hub data.
        dispatch(setServiceUsersData(formatUserHubs(res.body)));
        dispatch(setServiceUsers(res.body));
        dispatch(setLoadingBaseData(3));
        return res.body;
      }
    })
    .then((result) => {
      fetchRiskData(result, server, dispatch);
    })
    .catch((err) => {
      console.log(err);
    });
};

const saveSURiskData = (dispatch, riskScore) => {
  const allBehavioursExceptIndependence = riskScore.dayRatings.map((day) => {
    const { independence, ...rest } = day.details;
    return {
      ...day,
      details: { ...rest },
    };
  });
  const amendedRiskScore = {
    ...riskScore,
    dayRatings: allBehavioursExceptIndependence,
  };

  // Function for getting the number of occurances of each risk level within the daily risk values
  const countOccurrences = (arr, val) =>
    arr.reduce((a, v) => (v.dayRating === val ? a + 1 : a), 0);

  // Occurances of eack risk level in the date range
  const countAtRisk = countOccurrences(amendedRiskScore.dayRatings, 2);
  const countUnexpected = countOccurrences(amendedRiskScore.dayRatings, 1);
  const countExpected = countOccurrences(amendedRiskScore.dayRatings, 0);
  // Saving this week's number of days at each risk level
  dispatch(
    updateServiceUserData({
      hubId: riskScore.hubId,
      update: {
        riskScores: riskScore.dayRatings,
        weekRisks: {
          atRisk: countAtRisk,
          unexpected: countUnexpected,
          expected: countExpected,
        },
        riskStatus: amendedRiskScore.dayRatings.filter((dayRating) =>
          isSameDay(new Date(), new Date(dayRating.date))
        )?.[0].dayRating,
        riskStatusSustenance: calcBehaviourRisk(
          riskScore.dayRatings,
          "sustenance"
        ),
        riskStatusMovement: calcBehaviourRisk(riskScore.dayRatings, "movement"),
        riskStatusIndependence: calcBehaviourRisk(
          riskScore.dayRatings,
          "independence"
        ),
        riskStatusSleep: calcBehaviourRisk(riskScore.dayRatings, "sleep"),
      },
    })
  );
};

// There are two possible ways to get risk data. The slow way; which is straight to the API and making a call for each SU.
// The fast way is a single call to our CloudFlare workers to serve up cached data. This uses a new endpoint that doesn't exist in the actual API.
async function fetchRiskData(serviceUsers, server, dispatch) {
  // When we finish fetching the risk data we can exit the loading animation with the following:
  const completeLoadingAnimation = () => {
    dispatch(setLoadingBaseData(4));
    setTimeout(() => {
      dispatch(setLoadingBaseData(0));
    }, 250);
  };

  // First filter out service users pre installation as they won't have any risk data so will be unnecessary calls
  const sUsPostInstallation =
    serviceUsers?.filter(
      (serviceUser) =>
        serviceUser?.serviceUserProperties?.installationStatus === "complete"
    ) || [];

  // Create an array of the service users' id's
  const serviceUserIds =
    sUsPostInstallation?.map((serviceUser) => serviceUser.id) || [];

  const toFetch = [];
  // Split the behaviours into groups of three per page
  while (serviceUserIds?.length > 0) {
    toFetch.push(serviceUserIds.splice(0, 48));
  }

  // If the organisation does not yet have any installed hubs simply complete the loading spinner.
  if (toFetch.length === 0) {
    completeLoadingAnimation();
    dispatch(setRefreshStaff(true));
  }

  // Where there are more than 48 service users to fetch we want to keep track of whether we have fetched all their risk data
  const numOfGroupsToFetch = toFetch.length;
  let fetchedGroups = 0;

  // When a group finished fetching their risk data this function is called.
  const incrementGroupsFetched = () => {
    fetchedGroups++;
    // Finally finish off the loading animation by setting loadingBaseData when all the data has been received
    if (fetchedGroups === numOfGroupsToFetch) {
      completeLoadingAnimation();
      dispatch(setRefreshStaff(true));
    }
  };

  toFetch.map(async (serviceUsersSubset) => {
    // Call the CloudFlare endpoint that gets all the service user risk scores in one call
    const hubsRiskScores = await postHubsRiskScores(server, serviceUsersSubset);
    // If that's successful, save all the risk data in redux & local storage
    if (hubsRiskScores.ok) {
      hubsRiskScores?.body?.forEach((riskScore) => {
        if (riskScore?.dayRatings) {
          saveSURiskData(dispatch, riskScore);
        }
      });
      incrementGroupsFetched();
    }
    // Where the user is either working in dev (i.e. cloudflare isn't intercepting requests)
    // or the worker intercepting requests has failed for whatever reason a 404 response will be recieved.
    // We still want to fetch the data even if it takes a bit longer
    if (!hubsRiskScores.ok || hubsRiskScores.message) {
      const promises = serviceUsersSubset?.map((lilliUser) =>
        getHubRiskScoreV2(server, lilliUser, 7, new Date().toISOString()).then(
          (res) => {
            if (res?.ok) {
              saveSURiskData(dispatch, res?.body);
            }
          }
        )
      );
      await Promise.all(promises);
      incrementGroupsFetched();
    }
  });
}

export const useFetchInitialData = (
  server,
  setCheckedAuth,
  dispatch,
  fetchInitialState
) => {
  // Check the auth state of the user and persisting the user to state
  useEffect(() => {
    if (fetchInitialState) {
      dispatch(setFetchInitialState(false));
      dispatch(setLoadingBaseData(1));
      getCheckAuthState(server)
        .then((res) => {
          if (res.ok) {
            // Persisting the user to state
            dispatch(setUser(res?.body));
            // Set the fullstory identifier so that we can track individual pain points in fullstory
            window.FS?.identify(res?.body?.key, {
              displayName: res?.body?.identity,
              email: res?.body?.email,
            });
            // Return response
            return res?.body;
          } else {
            dispatch(setUser(null));
            return null;
          }
        })
        .then((result) => {
          // If the user is not authorised skip the next step
          if (!result) {
            dispatch(setLoadingBaseData(0));
            return null;
          }
          dispatch(setLoadingBaseData(2));
          // If the user is not a manager setting the serviceUsers part of state as the users' service user associations
          if (!result.roles?.includes("manager")) {
            dispatch(setLoadingBaseData(3));
            dispatch(setServiceUsers(result.hubs));
            fetchRiskData(result.hubs, server, dispatch, result.key);
            // Seperately create an object of SUs where SU id is the key. This is for persisting the risk and behaviours data later on
            dispatch(setServiceUsersData(formatUserHubs(result.hubs)));
          } else {
            fetchManagerSUs(server, result.organisationId, dispatch);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((_) => {
          setCheckedAuth(true);
        });
    }
  }, [fetchInitialState, dispatch, server, setCheckedAuth]);
};
