import { postLogin as postLoginAPI } from "@intelligentlilli/api-layer";

const postLogin = async ({ server, username, password, token }) => {
  try {
    const response = await postLoginAPI({
      server,
      username,
      password,
      token,
      deviceID: null, // (for mobile only),
      origin: "web",
    });
    return response;
  } catch (err) {
    return err;
  }
};

export default postLogin;
