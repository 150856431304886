import { getReportPreview as getReportPreviewAPI } from "@intelligentlilli/api-layer";

const getReportPreview = async (server, reportId) => {
  try {
    const response = await getReportPreviewAPI(server, reportId, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getReportPreview;
