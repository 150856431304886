import { getUser as getUserAPI } from "@intelligentlilli/api-layer";

const getUser = async (server, id) => {
  try {
    const response = await getUserAPI(server, id, "web");
    return response;
  } catch (err) {
    return err;
  }
};

export default getUser;
