import styles from "./styles.module.scss";

const InstallationCard = (props) => {
  return (
    <div className={`${styles.card} ${props.className} ${props.style}`}>
      {props.children}
    </div>
  );
};

export default InstallationCard;
